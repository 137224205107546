export const COUNCILS_WITH_COORDINATES = [
  {
    id: 7,
    name: 'Taupo District Council',
    coordinates: [-176.089705, -38.684774],
  },
  {
    id: 8,
    name: 'Matamata-Piako District Council',
    coordinates: [175.747184, -37.811854],
  },
  {
    id: 9,
    name: 'Hauraki District Council',
    coordinates: [175.596924, -37.273649],
  },
  {
    id: 10,
    name: 'South Waikato District Council',
    coordinates: [175.762709, -38.107590],
  },
  {
    id: 11,
    name: 'Hamilton City Council',
    coordinates: [175.279253, -37.786323],
  },
  {
    id: 12,
    name: 'Opotiki District Council',
    coordinates: [177.284805, -38.006142],
  },
  {
    id: 13,
    name: 'Western Bay of Plenty District Council',
    coordinates: [176.074655, -37.614495],
  },
  {
    id: 14,
    name: 'Waitomo District Council',
    coordinates: [175.132515, -38.400710],
  },
  {
    id: 15,
    name: 'Tauranga City Council',
    coordinates: [176.167122, -37.687797],
  },
  {
    id: 16,
    name: 'Whakatane District Council',
    coordinates: [177.001335, -37.983200],
  },
  {
    id: 17,
    name: 'Otorohanga District Council',
    coordinates: [175.197563, -38.183682],
  },
  {
    id: 18,
    name: 'Thames-Coromandel District Council',
    coordinates: [175.521519, -37.064764],
  },
  {
    id: 19,
    name: 'Waipa District Council',
    coordinates: [175.340699, -37.889029],
  },
  {
    id: 20,
    name: 'Rotorua District Council',
    coordinates: [176.224006, -38.136847],
  },
  {
    id: 21,
    name: 'Kawerau District Council',
    coordinates: [176.711925, -38.084003],
  },
  {
    id: 22,
    name: 'Waikato District Council',
    coordinates: [175.076922, -37.634312],
  },
  {
    id: 23,
    name: 'Carterton District Council',
    coordinates: [175.529872, -41.029260],
  },
  {
    id: 24,
    name: 'Hutt City Council',
    coordinates: [174.912338, -41.208484],
  },
  {
    id: 25,
    name: 'Kapiti Coast District Council',
    coordinates: [174.989011, -40.924246],
  },
  {
    id: 26,
    name: 'Marlborough District Council',
    coordinates: [173.785750, -41.495465],
  },
  {
    id: 30,
    name: 'Nelson City Council',
    coordinates: [173.283945, -41.270632],
  },
  {
    id: 31,
    name: 'Porirua City Council',
    coordinates: [174.837855, -41.130840],
  },
  {
    id: 32,
    name: 'South Wairarapa District Council',
    coordinates: [175.450138, -41.402173],
  },
  {
    id: 33,
    name: 'Tasman District Council',
    coordinates: [173.158341, -41.431985],
  },
  {
    id: 34,
    name: 'Upper Hutt City Council',
    coordinates: [175.067571, -41.127046],
  },
  {
    id: 37,
    name: 'Wellington City Council',
    coordinates: [174.777969, -41.292524],
  },
  {
    id: 38,
    name: 'Central Hawkes Bay District Council',
    coordinates: [176.530498, -39.999606],
  },
  {
    id: 39,
    name: 'Gisborne District Council',
    coordinates: [178.007687, -38.664257],
  },
  {
    id: 40,
    name: 'Hastings District Council',
    coordinates: [176.826403, -39.630546],
  },
  {
    id: 41,
    name: 'Horowhenua District Council',
    coordinates: [175.286148, -40.438527],
  },
  {
    id: 42,
    name: 'Manawatu District Council',
    coordinates: [175.518067, -40.127682],
  },
  {
    id: 43,
    name: 'Napier City Council',
    coordinates: [176.917030, -39.492844],
  },
  {
    id: 44,
    name: 'New Plymouth District Council',
    coordinates: [174.078003, -39.058579],
  },
  {
    id: 45,
    name: 'Palmerston North City Council',
    coordinates: [175.607895, -40.352309],
  },
  {
    id: 46,
    name: 'Rangitikei District Council',
    coordinates: [175.592213, -40.068439],
  },
  {
    id: 47,
    name: 'Ruapehu District Council',
    coordinates: [175.346793, -39.435976],
  },
  {
    id: 48,
    name: 'South Taranaki District Council',
    coordinates: [174.529590, -39.578254],
  },
  {
    id: 49,
    name: 'Stratford District Council',
    coordinates: [174.434481, -39.337399],
  },
  {
    id: 54,
    name: 'Tararua District Council',
    coordinates: [176.096832, -40.541753],
  },
  {
    id: 55,
    name: 'Wairoa District Council',
    coordinates: [177.423616, -39.037572],
  },
  {
    id: 56,
    name: 'Whanganui District Council',
    coordinates: [175.050649, -39.930089],
  },
  {
    id: 57,
    name: 'Ashburton District Council',
    coordinates: [171.734136, -43.903913],
  },
  {
    id: 58,
    name: 'Buller District Council',
    coordinates: [171.604147, -41.768488],
  },
  {
    id: 59,
    name: 'Central Otago District Council',
    coordinates: [169.649864, -45.046445],
  },
  {
    id: 60,
    name: 'Clutha District Council',
    coordinates: [169.835638, -45.843100],
  },
  {
    id: 61,
    name: 'Dunedin City Council',
    coordinates: [170.503604, -45.878760],
  },
  {
    id: 62,
    name: 'Gore District Council',
    coordinates: [168.932251, -46.094610],
  },
  {
    id: 63,
    name: 'Grey District Council',
    coordinates: [171.571518, -42.480861],
  },
  {
    id: 64,
    name: 'Hurunui District Council',
    coordinates: [172.459910, -42.933805],
  },
  {
    id: 65,
    name: 'Invercargill City Council',
    coordinates: [168.353752, -46.413187],
  },
  {
    id: 66,
    name: 'Kaikoura District Council',
    coordinates: [173.681135, -42.400309],
  },
  {
    id: 67,
    name: 'Mackenzie District Council',
    coordinates: [170.103488, -43.949131],
  },
  {
    id: 68,
    name: 'Queenstown-Lakes District Council',
    coordinates: [168.662643, -44.848055],
  },
  {
    id: 69,
    name: 'Southland District Council',
    coordinates: [167.766090, -45.751616],
  },
  {
    id: 70,
    name: 'Timaru District Council',
    coordinates: [170.506244, -44.059914],
  },
  {
    id: 71,
    name: 'Waimate District Council',
    coordinates: [171.061218, -44.724677],
  },
  {
    id: 72,
    name: 'Waitaki District Council',
    coordinates: [170.958107, -44.929780],
  },
  {
    id: 73,
    name: 'Westland District Council',
    coordinates: [170.979249, -43.476299],
  },
  {
    id: 74,
    name: 'Waimakariri District Council',
    coordinates: [172.640663, -43.383564],
  },
  {
    id: 75,
    name: 'Selwyn District Council',
    coordinates: [172.366789, -43.766901],
  },
  {
    id: 76,
    name: 'Christchurch City Council',
    coordinates: [172.619587, -43.532054],
  },
  {
    id: 80,
    name: 'Auckland Transport',
    coordinates: [174.763332, -36.848461],
  },
  {
    id: 81,
    name: 'SH New Zealand',
    coordinates: [172.621951, -40.968140],
  },
  {
    id: 86,
    name: 'Chatham Islands Council',
    coordinates: [-176.440974, -43.942766],
  },
  {
    id: 87,
    name: 'Masterton District Council',
    coordinates: [175.660070, -40.953079],
  },
  {
    id: 88,
    name: 'DOC Roads',
    coordinates: [175.553139, -41.074195],
  },
];
