import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useDeliveryKPI } from '../../context/reports-api-hooks';
import { CircleIndicator } from '../Indicators';
import { roundKpiValue } from '../../shared/helpers';

export default function DeliveryWidget({ overrideDisplayLogic = false }) {
  const { data: deliveryData, isLoading, isError } = useDeliveryKPI();

  const title = 'Transport Programme Delivery';
  const description = 'Proportion of 3 year budget (%)';

  // If there is an error or no data we want to hide the widget
  if (!overrideDisplayLogic && (isError || !deliveryData || deliveryData.nodata)) return null;

  return (
    <Widget
      link="/performance/reports/delivery"
      title={title}
      description={description}
      lockYear={deliveryData?.lockYear}
      quarter={null}
      isLoading={isLoading && !deliveryData}
      moreInfoContent={(
        <Text fontSize="sm">
          Approved budget vs expenditure variance for all transport activities excluding emergency works, from the start of the NLTP period.
        </Text>
      )}
    >
      <CircleIndicator
        size={100}
        colour={deliveryData?.currentPercentage !== undefined ? 'gray.700' : null}
        value={deliveryData && deliveryData?.currentPercentage !== null ? `${roundKpiValue(deliveryData?.currentPercentage, 5, 1)}%` : null}
        isLoading={isLoading && !deliveryData}
      />
    </Widget>
  );
}
