import {
  Box, Container, Flex, Text, Wrap,
} from '@chakra-ui/react';
import { TrafficLightColours as COLOURS } from '@src/modules/performance/shared/constants';
import MainLayout from '@src/components/layouts/main';
import ReportCard from '@src/modules/performance/components/ReportCard';
import {
  useBoardings,
  useInfrastructureExpenditure,
  useNoBuses,
  usePassengerKms, usePopulationBusStop, usePopulationServicedCommunity,
  usePublicTransportKPI,
  useServiceExpenditure, useServiceKms,
  useServicedCommunities,
  useTotalMobilityServicedCommunities,
  useTotalMobilityUse,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import SLAreaLineChart from './components/selfloading/SLAreaLineChart';
import SLDualAxisBarLineChart from './components/selfloading/SLDualAxisBarLineChart';
import SLLineChart from './components/selfloading/SLLineChart';
import SLListTextWidget from './components/selfloading/SLListTextWidget';
import SLStackedLineChart from './components/selfloading/SLStackedLineChart';

import InfoScroller from './components/information/InfoScroller';
import html from './help/PublicTransport.html';
import { CircleIndicator } from '../../components/Indicators';
import { roundKpiValue } from '../../shared/helpers';

const subheader = 'Fleet Size, Network Use and Community Serviced';

const lineColours = [
  'blue',
  'orangered',
  'seagreen',
];

function PublicTransport() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: publicTransportData, isLoadingKpi } = usePublicTransportKPI();
  const noBuses = useNoBuses();
  const passengerKms = usePassengerKms();
  const serviceKms = useServiceKms();
  const populationServicedCommunity = usePopulationServicedCommunity();
  const populationBusStop = usePopulationBusStop();
  const servicedCommunities = useServicedCommunities();
  const totalMobilityServicedCommunities = useTotalMobilityServicedCommunities();
  const totalMobilityUse = useTotalMobilityUse();
  const boardings = useBoardings();
  const serviceExpenditure = useServiceExpenditure();
  const infrastructureExpenditure = useInfrastructureExpenditure();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);
  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  const stackedReportChartColours = [
    'transblue', 'transorange', 'transgrey',
  ];

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header
            title="Public Transport and Total Mobility"
            subtitle={subheader}
            info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
          >
            <Flex align="center">
              <Box mr={3}>
                <CircleIndicator
                  size={60}
                  colour={COLOURS[publicTransportData?.colour]}
                  value={(publicTransportData?.value !== undefined && publicTransportData?.value) ? `${roundKpiValue(publicTransportData?.value, 5, 1)}%` : null}
                  isLoading={isLoadingKpi && !publicTransportData}
                />
              </Box>
              <Text fontWeight="bold" color="gray">
                Passenger kms
                <br />
                <Text as="span" fontSize="xs" color="gray">
                  (
                  {publicTransportData?.averageYearRange}
                  {' '}
                  Annual Avg & Trend)
                </Text>
              </Text>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Public Transport Use</Text>
                <Flex>
                  <SLLineChart
                    queryInfo={passengerKms}
                    title1="Passenger kms"
                    title2="&nbsp;"
                    labels={['Passenger kms']}
                    colours={lineColours}
                    unitprefix=""
                    unitpostfix=""
                    precision={0}
                    chartMin={0}
                    hideLegend
                    formatK
                  />
                  <SLLineChart
                    queryInfo={serviceKms}
                    title1="Service kms"
                    title2="&nbsp;"
                    labels={['Service kms']}
                    colours={lineColours}
                    unitprefix=""
                    unitpostfix=""
                    precision={0}
                    chartMin={0}
                    hideLegend
                    formatK
                  />
                  <SLStackedLineChart
                    queryInfo={boardings}
                    title1="Public transport boardings"
                    title2="&nbsp;"
                    labels={['Bus', 'Rail', 'Ferry']}
                    colours={stackedReportChartColours}
                    unitprefix=""
                    unitpostfix="M"
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Public Transport Fleet Size</Text>
                <SLLineChart
                  queryInfo={noBuses}
                  title1="No. buses"
                  title2="&nbsp;"
                  labels={['No. Buses']}
                  colours={lineColours}
                  unitprefix=""
                  unitpostfix=""
                  precision={0}
                  chartMin={0}
                  hideLegend
                />
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Public Transport Community Serviced</Text>
                <Flex>
                  <SLLineChart
                    queryInfo={populationServicedCommunity}
                    title1="Population of serviced community"
                    title2="&nbsp;"
                    labels={['Population']}
                    colours={lineColours}
                    unitprefix=""
                    unitpostfix=""
                    precision={0}
                    chartMin={0}
                    hideLegend
                    formatK
                  />
                  <SLLineChart
                    queryInfo={populationBusStop}
                    title1="% population within 500m of a bus stop"
                    title2="&nbsp;"
                    labels={['Population']}
                    colours={lineColours}
                    unitprefix=""
                    unitpostfix="%"
                    precision={0}
                    hideLegend
                    chartMin={0.0}
                    chartMax={100.0}
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Public Transport Communities</Text>
                <SLListTextWidget
                  queryInfo={servicedCommunities}
                  title="Serviced communities included:"
                  field="ServicedCommunity"
                />
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Public Transport Expenditure</Text>
                <Flex>
                  <SLAreaLineChart
                    queryInfo={serviceExpenditure}
                    title1="(Region)"
                    title2="Public transport service expenditure"
                    labels={[
                      'Region',
                      'top',
                      '25-75th National %ile',
                    ]}
                    areaIndex={1}
                    colours={['blue', 'lightgrey']}
                    unitprefix=""
                    unitpostfix="M"
                  />
                  <SLAreaLineChart
                    queryInfo={infrastructureExpenditure}
                    title1="(Territorial Authority)"
                    title2="Public transport infrastructure expenditure"
                    labels={[
                      'TA',
                      'top',
                      '25-75th National %ile',
                    ]}
                    areaIndex={1}
                    colours={['blue', 'lightgrey']}
                    unitprefix=""
                    unitpostfix="M"
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Total Mobility Use</Text>
                <SLDualAxisBarLineChart
                  queryInfo={totalMobilityUse}
                  title1="Total Mobility Use"
                  title2="&nbsp;"
                  labels={['Persons Registered', 'Boardings']}
                  vertlabels={['Persons Registered', 'Boardings']}
                  colours={lineColours}
                  unitprefix={['', '']}
                  unitpostfix={['', '']}
                  precision={1}
                  width={250}
                  chartType={['line', 'line']}
                />
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Total Mobility Communities</Text>
                <SLListTextWidget
                  queryInfo={totalMobilityServicedCommunities}
                  title="Serviced communities included:"
                  field="ServicedCommunity"
                />
              </Box>
            </Wrap>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default PublicTransport;
