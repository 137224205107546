import { currentDatabaseSelector } from '@transport-insights/uikit';
import { selectedJobIdState } from '@tce/state/tce';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import useTceApi from '../api/useTceApi';

const getQueryKey = (rca, lockyear) => ['tce-jobs', rca, lockyear];

export function useJobs() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = useTceApi();

  const { isLoading, data: jobs, error } = useQuery(
    ['tce-jobs', rca?.id],
    async () => api.getJobs(rca?.id),
    { enabled: rca?.id !== null },
  );

  return {
    isLoading,
    jobs,
    error,
  };
}

export function useJob() {
  const jobId = useRecoilValue(selectedJobIdState);
  const api = useTceApi();

  const query = useQuery(
    ['tce-job', jobId],
    async () => api.getJob(jobId),
    { enabled: jobId !== null },
  );

  return {
    job: query.data,
    ...query,
  };
}

export function useCreateJobMutation(mutationOptions = undefined) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = useTceApi();

  const queryClient = useQueryClient();
  return useMutation({
    ...mutationOptions,
    mutationFn: ({
      importId, email, lockYear, userId, username,
    }) => api.createJob({
      rcaId: rca.id,
      lockYear,
      importId,
      userId,
      email,
      username,
    }),
    onSuccess: (result) => {
      queryClient.setQueryData(
        getQueryKey(rca, result.lockYear),
        (oldData) => {
          if (!oldData) return [];
          const filteredData = oldData?.filter((t) => t.id !== result.id);
          filteredData.push(result);
          return filteredData;
        },
      );
      queryClient.invalidateQueries('tce-rcaimports');
      queryClient.invalidateQueries('tce-job');
      return mutationOptions?.onSuccess?.(result);
    },
  });
}

export function PollJobs() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = useTceApi();

  const {
    isLoading, data: jobs, error, isFetching,
  } = useQuery(
    ['tce-job-statuses', rca?.id],
    async () => api.getJobStatuses(rca?.id),
    {
      // Refetch the data every 5 seconds
      refetchInterval: 5000,
      enabled: rca?.id !== null,
    },
  );

  return {
    isLoading,
    jobs,
    error,
    isFetching,
  };
}

export function PollSingleJob() {
  const jobId = useRecoilValue(selectedJobIdState);
  const api = useTceApi();

  const {
    isLoading, data: job, error, isFetching,
  } = useQuery(
    ['tce-job-status', jobId],
    async () => api.getJobStatus(jobId),
    {
      // Refetch the data every 10 seconds
      refetchInterval: 10000,
      enabled: jobId !== null,
    },
  );

  return {
    isLoading,
    job,
    error,
    isFetching,
  };
}

export function CalculateRates(mutationOptions) {
  const api = useTceApi();
  const queryClient = useQueryClient();
  return useMutation({
    ...mutationOptions,
    mutationFn: (jobId) => api.calculateRates(jobId),
    onSuccess: () => {
      queryClient.invalidateQueries(['tce-job']);
      queryClient.invalidateQueries(['tce-job-status']);
    },
  });
}
