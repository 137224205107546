import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
  Text,
  GridItem,
  Grid,
  SimpleGrid,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import {
  convertBubbleRange,
  roundToDecimal,
  currencyFormatter,
  getTrendDirection,
  getRangeOfPoints,
} from '@src/utils/math';
import {
  getContrastColor, COMPARISON_COLORS, getTrendlineColor,
} from '@src/utils/color';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import ReportSubheading from '../shared/ReportSubheading';
import CollapsedContent from '../shared/CollapsedContent';
import ChartContainer from '../shared/ChartContainer';
import { useTrendSvg } from '../../hooks/useTrendSvg';

export default function Ep3({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const { upRedSvg, downGreenSvg, flatSvg } = useTrendSvg();
  const filteredCategories = useFilteredCategories(filters);
  const years = [...new Set(reportData.data.map((x) => x.financialYear))].sort();
  const rca = reportData.rcas[0];
  const filteredReportData = reportData.data
    .filter((x) => x.costGroup !== null)
    .filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = reportData.data.filter((x) => x.id === rca.id).filter((x) => x.costGroup !== null);
  const selectedRcaFilteredData = filteredReportData.filter((x) => x.id === rca.id);
  const pieChartData = Object.entries(groupBy(selectedRcaData.filter((x) => x.financialYear === years.at(-1)), 'costGroupDescription'))
    .map(([k, v]) => ({
      name: k,
      totalCostAmount: roundToDecimal(v.map((item) => item.costAmount).reduce((a, b) => a + b, 0), 2),
    }));
  const currentYearOverview = selectedRcaFilteredData.filter((x) => x.financialYear === years.at(-1))
    .map((item) => ({
      category: item.category,
      value: item.costPerLaneKm,
      trendM: item.cplTrendM,
      length: roundToDecimal(item.length_m / 1000, 1),
      laneKms: roundToDecimal(item.laneKms, 1),
      trendDirection: getTrendDirection(roundToDecimal(item.cplTrendM, 3), roundToDecimal(item.cplTrendM, 3), item.cplTrendCount),
    }));
  const bubbleChartData = Object.entries(groupBy(selectedRcaFilteredData.filter((x) => x.financialYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      category: k,
      costPerLaneKm: v.map((item) => item.costPerLaneKm).reduce((a, b) => a + b, 0),
      length: v[0].length_m / 1000,
      laneKms: roundToDecimal(v[0].laneKms, 1),
      vkt: roundToDecimal(v[0].vkt / 1000000, 1),
    }));
  const transformedDataByCategory = Object.entries(groupBy(selectedRcaFilteredData, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        year: item.financialYear,
        value: item.costPerLaneKm,
        trendY1: item.cplTrendB,
        trendY2: item.cplTrendB + (item.cplTrendM * item.cplTrendCount),
      })),
    }));
  const transformedDataByRca = Object.entries(groupBy(filteredReportData.filter((x) => x.financialYear === years.at(-1)), 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.category,
        y: item.costPerLaneKm,
      })),
    }));
  const dataTable = Object.entries(groupBy(reportData.data, 'category'))
    .map(([k, v]) => ({
      category: k,
      data: v.map((item) => ({
        year: item.financialYear,
        rcaName: item.rcaName,
        costPerLaneKm: item.costPerLaneKm,
        costAmount: item.costAmount,
        costPerVKT: item.costPerVKT,
      })),
    }))
    .map((x) => ({
      name: x.category,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          data: Object.entries(groupBy(v, 'year')).map(([year, val]) => ({
            year,
            totalCostPerLane: roundToDecimal(val.map((y) => y.costPerLaneKm).reduce((a, b) => a + b, 0), 2),
            totalCostAmount: roundToDecimal(val.map((y) => y.costAmount).reduce((a, b) => a + b, 0), 2),
            totalCostPerVKT: roundToDecimal(val.map((y) => y.costPerVKT).reduce((a, b) => a + b, 0), 5),
          })),
        })),
    }));
  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '1fr 2fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Maintenance Costs
          </ReportSubheading>
          <Text mb={8}>This chart shows the breakdown of Maintenance Costs for the year in the network.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: true,
                    marker: {
                      show: false,
                    },
                    y: {
                      title: {
                        formatter: () => 'Total Cost: ',
                      },
                      formatter(value) {
                        return currencyFormatter.format(value);
                      },
                    },
                  },
                  labels: pieChartData.map((x) => x.name),
                }
              }
              series={pieChartData.map((x) => x.totalCostAmount)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Maintenance Costs by ONF Category
          </ReportSubheading>
          <Text mb={4}>
            The bars show the Maintenance Costs per lane km in each ONF Street Category for the selected cost group/s. The arrow above the bar shows the cost trend.
          </Text>

          <ChartContainer>
            <Chart
              options={
                {
                  xaxis: {
                    type: 'category',
                    categories: [...new Set(currentYearOverview.map((x) => x.category))],
                    labels: {
                      style: {
                        colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      forceNiceScale: true,
                      title: {
                        text: 'Cost Per Lane km',
                      },
                      labels: {
                        formatter(value) {
                          return currencyFormatter.format(value);
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: false,
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                    custom({ seriesIndex, dataPointIndex, w }) {
                      const {
                        x, y, length, laneKms,
                      } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                      return `<div style="padding: 10px">
                      <p style="color: ${theme.colors.onfCategory[x].base}; font-weight: bold; margin-bottom: 10px;">${x}</p> 
                      <ul>
                      <li><b>Cost Per Lane km</b>: ${currencyFormatter.format(y)}</li>
                      <li><b>Lane km</b>: ${laneKms} km</li>
                      <li><b>Length</b>: ${length} km</li>
                      </ul></div>`;
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                  annotations: {
                    points: currentYearOverview.map((item) => (
                      {
                        x: item.category,
                        y: item.value,
                        marker: {
                          size: 0,
                        },
                        customSVG: {
                          // eslint-disable-next-line no-nested-ternary
                          SVG: item.trendDirection === 'flat' ? flatSvg : item.trendDirection === 'up' ? upRedSvg : downGreenSvg,
                          cssClass: undefined,
                          offsetX: -30,
                          offsetY: -50,
                        },
                      }
                    )),
                  },
                }
              }
              series={
                [
                  {
                    name: years.at(-1),
                    data: currentYearOverview.map((x) => ({
                      y: x.value, x: x.category, trend: x.trendM, length: x.length, laneKms: x.laneKms,
                    })),
                  },
                ]
              }
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>
          Maintenance Costs by ONF Category
        </ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the Maintenance costs per lane kilometre for each ONF Street Category. The bottom axis is the network length for the ONF Street Categories. The side axis is $ per lane kilometre. The bubble size is the VKT in each ONF category.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
                  {
                    colors: bubbleChartData.map((x) => theme.colors.onfCategory[x.category].base),
                    grid: {
                      padding: {
                        left: 20,
                        bottom: 20,
                      },
                    },
                    xaxis: {
                      type: 'numeric',
                      title: {
                        text: 'Street Category Network Length (km)',
                        offsetY: 70,
                      },
                      min: 0,
                      max: Math.max(...bubbleChartData.map((x) => x.length)) * 1.1,
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)} km`;
                        },
                      },
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: (Math.max(...bubbleChartData.map((x) => x.costPerLaneKm)) + 0.5) * 1.1,
                        title: {
                          text: 'Cost Per Lane km',
                        },
                        labels: {
                          formatter(value) {
                            return currencyFormatter.format(value);
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    fill: {
                      opacity: 0.8,
                    },
                    tooltip: {
                      marker: {
                        show: false,
                      },
                      z: {
                        title: 'Vehicle Count',
                      },
                      y: {
                        title: {
                          formatter: () => 'Crash Count:',
                        },
                      },
                      x: {
                        show: true,
                        formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                      },
                      custom({ seriesIndex, dataPointIndex, w }) {
                        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        const { name } = w.globals.initialSeries[seriesIndex];

                        return `<div style="padding: 10px">
                        <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                        <ul>
                        <li><b>Cost per lane km</b>: ${currencyFormatter.format(data.y)}</li>
                        <li><b>Lane km</b>: ${data.laneKms} km</li>
                        <li><b>VKT</b>: ${data.vkt} mil</li>
                        <li><b>Length</b>: ${data.x}km</li>
                        </ul></div>`;
                      },
                    },
                    plotOptions: {
                      bubble: {
                        zScaling: true,
                      },
                    },
                  }
                }
                series={bubbleChartData.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.costPerLaneKm,
                      z: convertBubbleRange(item.vkt, [Math.min(...bubbleChartData.map((x) => x.vkt)), Math.max(...bubbleChartData.map((x) => x.vkt))], [1, 8]),
                      vkt: item.vkt,
                      laneKms: item.laneKms,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      <Box my={8}>
        <ReportSubheading>Maintenance Costs by ONF Street Category</ReportSubheading>
        <Text mb={8}>
          These charts shows the Maintenance costs per lane kilometre for each ONF Street Category
          and the linear trend (green is improving, red is declining).
        </Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {transformedDataByCategory.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    xaxis: {
                      categories: years,
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: Math.max(...transformedDataByCategory.flatMap((x) => x.data.map((y) => y.value))),
                        forceNiceScale: true,
                        title: {
                          text: 'Cost Per Lane km',
                        },
                        labels: {
                          formatter(value) {
                            return currencyFormatter.format(value);
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: [0, 4],
                    },
                    colors: [getTrendlineColor(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, false)],
                    fill: {
                      colors: [theme.colors.onfCategory[cat.name].base],
                    },
                    markers: {
                      size: 0,
                      hover: {
                        size: 0,
                      },
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: {
                      enabled: true,
                      enabledOnSeries: [0],
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  [
                    {
                      name: cat.name,
                      type: 'column',
                      data: cat.data.map((x) => x.value),
                    },
                    {
                      name: 'Trend',
                      type: 'line',
                      data: getRangeOfPoints(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, years.length),
                    },
                  ]
                }
                type="line"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>
          Compared to other networks
        </ReportSubheading>
        <Text mb={8}>
          This graph shows the Maintenance Costs per lane km for each ONF Street Category, for your network and the other networks you are comparing with (defaults to the Peer Group and Region of the RCA, and National).
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    forceNiceScale: true,
                    tickAmount: 8,
                    labels: {
                      formatter(value) {
                        return currencyFormatter.format(value);
                      },
                    },
                    title: {
                      text: 'Cost Per Lane km',
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter: (value) => (value === 0 ? null : currencyFormatter.format(value)),
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">
                    Category
                  </Th>
                  <Th background="gray.50">Network</Th>
                  <Th background="gray.50">Year</Th>
                  <Th background="gray.50">Cost Amount</Th>
                  <Th background="gray.50">Cost Per Lane km</Th>
                  <Th background="gray.50">Cost Per VKT</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataTable.map((category, idx1) => category.data.map(({ rcaName, data }, idx2) => data.map(({
                  year, totalCostAmount, totalCostPerLane, totalCostPerVKT,
                }, idx3) => (
                  <Tr key={`row_${idx1}_${idx2}_${idx3}`}>
                    {idx2 === 0 && idx3 === 0
                      && (
                      <Td
                        position="sticky"
                        left="0"
                        color={getContrastColor(theme.colors.onfCategory[category.name].base)}
                        background={theme.colors.onfCategory[category.name].base}
                        rowSpan={category.data.length * years.length}
                        borderBottom="2px solid"
                        borderColor={idx2 === 0 ? theme.colors.onfCategory[category.name].base : 'gray.100'}
                        fontWeight="bold"
                      >
                        {category.name}
                      </Td>
                      )}
                    {idx3 === 0
                      && (
                      <Td
                        rowSpan={years.length}
                        borderBottom="2px solid"
                        borderColor="gray.100"
                      >
                        {rcaName}
                      </Td>
                      )}
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {year}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {currencyFormatter.format(totalCostAmount)}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {currencyFormatter.format(totalCostPerLane)}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {currencyFormatter.format(totalCostPerVKT)}
                    </Td>
                  </Tr>
                ))))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
