import ReactGA from 'react-ga4';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useUserInfo, currentDatabaseSelector, selectedLockYearState } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default function GoogleAnalytics({ children }) {
  const { email, userId } = useUserInfo();
  const config = useConfig();
  const location = useLocation();
  const db = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const [lastSendTime, setLastSendTime] = useState(null);

  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_MEASUREMENT_ID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!db) {
      return;
    }

    // This debounce is pretty gross but I couldn't think of a better solution
    // sometimes in the code we change the lock year and rca when selecting a new rca
    // this needs to only record as one page view
    if (lastSendTime) {
      const diff = new Date().getTime() - lastSendTime;
      if (diff < 1000) {
        return;
      }
    }

    const title = location.pathname
      .split('/')
      .filter((c) => c)
      .map((c) => capitalize(c))
      .join(' ');

    ReactGA.send(
      {
        hitType: 'pageview',
        rca: db?.name,
        lockYear,
        userId: userId ?? 'anonymous',
        userEmailPostfix: email ? email.split('@')[1] : '',
        page_title: title,
      },
    );
    setLastSendTime(new Date().getTime());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, email, userId, lockYear, location.pathname]);

  return children;
}
