import { HStack, Wrap } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { tceWizardFilterRoadState } from '@tce/state/tce';

export default function Selection({ name }) {
  const tceFilter = useRecoilValue(tceWizardFilterRoadState(name));
  return (
    <HStack alignContent="left" w="full">
      <Wrap mt={2} spacing={6}>
        <div>{tceFilter ?? ''}</div>
      </Wrap>
    </HStack>
  );
}
