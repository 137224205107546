import {
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';

export default function SignedOut() {
  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Heading as="h1">Signed Out</Heading>
        <Text>We have signed you out of Transport Insights, but it&rsquo;s a good idea to close your browser.</Text>
      </Container>
    </MainLayout>
  );
}
