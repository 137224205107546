import {
  Box,
  Flex,
  Text,
  useTheme,
} from '@chakra-ui/react';

import { reportsList } from '../../api/reports-list';

function TransportIndicator({ reportId }) {
  const theme = useTheme();
  const report = reportsList.find((x) => x.id === reportId);
  return (
    <Flex align="center" mb={1}>
      <Box backgroundColor={theme.colors.transportIndicator[report.group]} width={3} height={3} mr={2} borderRadius="full" />
      <Text color={theme.colors.transportIndicator[report.group]} fontWeight="bold" fontSize="xs">{report.group}</Text>
    </Flex>
  );
}

export default function ReportHeading({ reportId, children }) {
  return (
    <Box>
      <TransportIndicator reportId={reportId} />
      {children}
    </Box>
  );
}
