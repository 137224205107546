import {
  Button,
  Collapse,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';

import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

export default function CollapsedContent({
  collapsedLabel, openLabel, children, ...rest
}) {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Button onClick={onToggle} variant="outline" size="sm" colorScheme="gray" color="gray.700" align="center" display="flex" {...rest}>
        <Icon as={isOpen ? FiChevronDown : FiChevronRight} boxSize={4} mr={2} />
        { isOpen ? openLabel : collapsedLabel}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </>
  );
}
