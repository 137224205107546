import { useEffect, useState } from 'react';
import {
  Container,
  Heading,
  Button,
  Text,
  Flex,
  Icon,
  Box,
} from '@chakra-ui/react';
import { FiDownloadCloud } from 'react-icons/fi';
import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import MainLayout from '@src/components/layouts/main/MainLayout';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import {
  useAnnualReports,
} from '@src/modules/performance/context/reports-api-hooks';
import usePerformanceReportsApi from '../../api/usePerformanceReportsApi';

function AnnualReports() {
  const { data, isLoading } = useAnnualReports();
  const api = usePerformanceReportsApi();
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setFiles(data.filter((x) => x.RcaName === currentDatabase.name).sort((a, b) => b.Year - a.Year));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const downloadFile = (fileURL, filename) => {
    api.downloadReport(fileURL)
      .then((res) => {
        const file = new Blob(
          [res],
          { type: 'application/pdf' },
        );
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}.pdf`;
        link.click();
      });
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Heading as="h1" mb={6}>Annual Reports</Heading>
        {isLoading && <LoadingSpinner />}
        {!isLoading
          && (
          <Box mt={8} variant="soft-rounded" size="md">
            {files.map((file) => (
              <Button
                key={file.FileName}
                display="flex"
                w="100%"
                justifyContent="space-between"
                size="lg"
                variant="outline"
                colorScheme="blue"
                mb={4}
                onClick={() => downloadFile(file.DownloadLink, `${file.Year}-${file.Year + 1 - 2000} ${file.RcaName} RCA Report v${file.Version}`)}
              >
                <Flex as="span" align="center">
                  <Icon as={FiDownloadCloud} boxSize={8} mr={4} />
                  <Text fontSize="lg" fontWeight="normal">
                    {file.Year}
                    /
                    {file.Year + 1 - 2000}
                    {' '}
                    — RCA Report for
                    {' '}
                    {file.RcaName}
                  </Text>
                </Flex>
                <Text fontSize="sm" fontWeight="normal">
                  PDF
                  {Math.round((file.FileSize / 1024))}
                  {' '}
                  KB
                </Text>
              </Button>
            ))}
            {files.length === 0 && <Text>No reports available.</Text>}
          </Box>
          )}
      </Container>
    </MainLayout>
  );
}

export default AnnualReports;
