import Card from '@src/modules/performance/components/Card';
import {
  Text, Center, Box, useTheme,
} from '@chakra-ui/react';
import {
  FiChevronDown, FiChevronUp, FiMinus, FiPercent,
} from 'react-icons/fi';

import PlaceHolder from '@src/modules/performance/components/Placeholder';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import { COLOURS } from '../shared/MapColours';

function Indicator({ marker, colour }) {
  if (marker === 'up') {
    return (<FiChevronUp fontSize={48} color={COLOURS[colour]} />);
  }
  if (marker === 'down') {
    return (<FiChevronDown fontSize={48} color={COLOURS[colour]} />);
  }
  if (marker === 'flat') {
    return (
      <FiMinus fontSize={48} color={COLOURS[colour]} pl={2} />
    );
  }
  if (marker === 'percentage') {
    return (
      <FiPercent fontSize={48} color={COLOURS[colour]} pl={2} />
    );
  }
  return null;
}

function TextWidget({
  title, value, additionalValue, colour, subtext1, subtext2, loading, marker, handleClick, selected, lockYear, ...other
}) {
  const theme = useTheme();
  return (
    <Card
      width="100%"
      padding={2}
      onClick={handleClick}
      borderWidth="1px"
      borderColor={selected ? 'brand.orange.400' : 'white'}
      cursor="pointer"
      _hover={{ borderColor: selected ? 'brand.orange.400' : 'brand.orange.300' }}
      _before={{
        content: '""',
        width: 0,
        height: 0,
        borderTop: '9px solid transparent',
        borderBottom: '9px solid transparent',
        borderLeft: '9px solid white',
        borderLeftColor: selected ? 'white' : 'transparent',
        right: '-9px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        bgColor: 'transparent',
        zIndex: 2,
      }}
      _after={{
        content: '""',
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid',
        borderLeftColor: selected ? theme.colors.brand.orange[500] : 'transparent',
        right: '-10px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        bgColor: 'transparent',
        zIndex: 1,
      }}
      {...other}
    >
      <Box>
        <Center>
          <PlaceHolder isLoaded={!loading}>
            <Text textStyle="cardHeader">
              {title}
            </Text>
            <LockYearBadge lockYear={lockYear} />
          </PlaceHolder>
        </Center>
      </Box>
      {selected
      && (
      <>
        {value !== 'No data'
          && (
          <Box>
            <PlaceHolder isLoaded={!loading}>
              <Center>
                <Text textStyle="cardText" color={COLOURS[colour]} fontSize={Number.isNaN(value) ? 32 : 48}>
                  {value}
                </Text>
                <Indicator marker={marker} colour={colour} />
              </Center>
              {additionalValue
              && <Center mb={2}><Text fontSize="sm">{additionalValue}</Text></Center>}
            </PlaceHolder>
          </Box>
          )}
        <Box>
          <Center>
            <PlaceHolder isLoaded={!loading}>
              <Text textStyle="cardSubtext" fontWeight="bold">
                {subtext1}
              </Text>
            </PlaceHolder>
          </Center>
          <Center>
            <PlaceHolder isLoaded={!loading}>
              <Text textStyle="cardSubtext">
                {subtext2}
              </Text>
            </PlaceHolder>
          </Center>
        </Box>
      </>
      )}
    </Card>
  );
}

export default TextWidget;
