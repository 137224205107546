import {
  Container,
} from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import MainLayout from '@src/components/layouts/main';

const events = [
  {
    title: 'Check ONF network and apply updates',
    start: '2023-04-01',
    end: '2023-06-01',
    url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework/',
    allDay: true,
  },
  {
    title: 'Load end of financial year crash data in RAMM',
    start: '2023-06-10',
    end: '2023-06-15',
    allDay: true,
  },
  {
    title: 'Run import from RAMM in Transport Insights',
    start: '2023-07-16',
    end: '2023-08-01',
    allDay: true,
  },
  {
    title: 'End of year importing completed',
    start: '2023-07-31',
    allDay: true,
    backgroundColor: 'brand.orange.500',
  },
];

export default function Calendar() {
  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full" height="100%">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          navLinks={false}
          height="100%"
          headerToolbar={
            {
              start: 'today prev,next',
              center: 'title',
              end: 'dayGridMonth,dayGridWeek',
            }
          }
        />
      </Container>
    </MainLayout>
  );
}
