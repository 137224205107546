import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useRoadNetworkUseKPI } from '../../context/reports-api-hooks';
import { CircleIndicator } from '../Indicators';
import { roundKpiValue } from '../../shared/helpers';

export default function RoadNetworkUseWidget({ overrideDisplayLogic = false }) {
  const { data: roadNetworkUseData, isLoading, isError } = useRoadNetworkUseKPI();

  const title = 'Road Network Use';
  const description = 'Change in vehicle kilometres\ntravelled over 5 years';

  // If there is an error or no data we want to hide the widget
  if (!overrideDisplayLogic && (isError || !roadNetworkUseData || roadNetworkUseData.nodata)) return null;

  return (
    <Widget
      link="/performance/reports/road-network-use"
      title={title}
      description={description}
      lockYear={roadNetworkUseData?.lockYear}
      quarter={null}
      isLoading={isLoading && !roadNetworkUseData}
      moreInfoContent={
        <Text fontSize="sm">The average change in road usage per year for the last five years.</Text>
      }
    >
      <CircleIndicator
        size={100}
        colour={roadNetworkUseData?.percentage !== undefined ? 'gray.700' : null}
        value={roadNetworkUseData?.percentage !== undefined ? `${roundKpiValue(roadNetworkUseData?.percentage, 5, 1, 'max')}%` : undefined}
        isLoading={isLoading || !roadNetworkUseData}
      />
    </Widget>
  );
}
