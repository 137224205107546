/* eslint-disable max-len */
import { RAG_COLORS } from '@src/utils/color';

export const useTrendSvg = () => {
  const {
    red, green, gray, darkGray,
  } = RAG_COLORS;
  const upRedSvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${red} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>`;
  const upGreenSvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${green} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>`;
  const upGraySvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${darkGray} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>`;
  const downGreenSvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${green} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>`;
  const downRedSvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${red} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>`;
  const downGraySvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${darkGray} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>`;
  const flatSvg = `<svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke=${gray} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line></svg>`;
  return {
    upRedSvg, upGreenSvg, upGraySvg, downGreenSvg, downRedSvg, downGraySvg, flatSvg,
  };
};
