import {
  Container,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import Chart from 'react-apexcharts';
import { usePortalNavContext } from '@transport-insights/uikit';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

export default function ActiveTravel() {
  const theme = useTheme();
  const { isLoading } = usePortalNavContext();
  const { data: report, isLoading: isLoadingReport } = useReports('active-travel');
  const regionName = report?.rcaName?.replace('Region', '');
  const { description, notification } = reportsList.find((x) => x.url === 'active-travel');
  const censusYear = report?.data?.[0]?.censusYear || '';
  // Sort alphabetically with 'Total - NZ' last
  const sortedData = report?.data?.slice().sort((a, b) => ((!a.reportName.includes('Total') && !a.reportName.includes('average')) ? a.reportName.localeCompare(b.reportName) : a));

  const series = [
    {
      name: 'Work',
      data: sortedData?.map((item) => ({
        x: item.reportName,
        y: item.activeTravelWork * 100,
      })),
    },
    {
      name: 'Education',
      data: sortedData?.map((item) => ({
        x: item.reportName,
        y: item.activeTravelEducation * 100,
      })),
    },
  ];

  if (isLoading || isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="active-travel" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="active-travel" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="active-travel" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text whiteSpace="pre">{notification}</Text>
                </InfoPane>
              )}
          <Heading as="h2" mb={2} fontWeight="normal" fontSize="2xl">
            Active travel (walk and cycle) mode share in
            {' '}
            {regionName}
          </Heading>
          <Text mb={4}>
            {censusYear}
            {' '}
            Census Data
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: false,
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: [theme.colors.brand.blue[700], theme.colors.brand.blue[300]],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'Share of trips, walk and cycle (%)',
                      },
                      min: 0,
                      tickAmount: 6,
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(1)}%`;
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: true,
                    },
                  },
                }
              }
              series={series}
              width="100%"
              height="500"
              type="bar"
            />
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
