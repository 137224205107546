import { useState, useEffect } from 'react';
import {
  generateTableContents,
  generatePiechart,
  generateTornadoChart,
} from '@src/modules/performance/shared/networkcharacteristics';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Center,
  Container,
  Heading,
} from '@chakra-ui/react';
import PlaceHolder from '@src/modules/performance/components/Placeholder';

import MainLayout from '@src/components/layouts/main';
import { useNetworkCharacteristics } from '@src/modules/performance/context/map-api-hooks';
import StackedTornadoChart from './components/charts/custom/StackedTornadoChart';
import PopoutPieChart from './components/charts/custom/PopoutPieChart';

import { SSRSColours } from './components/charts/ChartColours';

const title = 'Network Characteristics';

const TABLE_HEADERS = [
  'ONRC',
  'Total Length(Km)',
  'Urban (Km)',
  'Rural (Km)',
  'Sealed (Km)',
  'Unsealed (Km)',
  'Lane (Km)',
  'Urban Journeys (M VKT)',
  'Rural Journeys (M VKT)',
  'Annual Total Journeys Travelled (M VKT)',
  'Percentage of length',
];

// replica of the old net1 SSRS report
function NetworkCharacteristics() {
  const { data: networkCharacteristics, loading } = useNetworkCharacteristics();

  const [rows, setRows] = useState([]);

  const [tornadoChart, setTornadoChart] = useState({
    data: [50, 50],
    colours: [SSRSColours.blue, SSRSColours.orange],
  });
  const [pieChart, setPieChart] = useState({
    data: [0, 0],
    colours: [SSRSColours.blue, SSRSColours.orange],
    labels: ['Sealed', 'Unsealed'],
    postfix: '%',
  });

  useEffect(() => {
    if (networkCharacteristics) {
      setRows(generateTableContents(networkCharacteristics));
      setPieChart(generatePiechart(networkCharacteristics));
      setTornadoChart(generateTornadoChart(networkCharacteristics));
    }
  }, [networkCharacteristics]);

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Box mb={50}>
          <Heading as="h1">{title}</Heading>

          { // placeholder blocks to stop page from jumping around
            loading && (
              <Center pt={10}>
                <Flex>
                  <PlaceHolder width={600} height={270} />
                  <PlaceHolder width={200} height={270} />
                </Flex>
              </Center>
            )
          }
          {!loading && (
            <Center pt={10}>
              <Flex>
                <StackedTornadoChart
                  title="Network % Length (km) & Journeys Travelled (veh km)"
                  settings={tornadoChart}
                />
                <PopoutPieChart
                  title="Sealed vs Unsealed Proportion"
                  settings={pieChart}
                />
              </Flex>
            </Center>
          )}

          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                {TABLE_HEADERS.map((text, index) => (
                  <Th key={`header${index}`} backgroundColor="#ececec" isNumeric={index > 0}>
                    <Text fontSize={12}>{text}</Text>
                  </Th>
                ))}
              </Tr>
            </Thead>
            { // arbritrary size placeholder grid
              loading && (
                <Tbody>
                  {Array.from(Array(9), (e, index1) => (
                    <Tr key={`row${index1}`}>
                      {Array.from(Array(TABLE_HEADERS.length), (evt, index2) => (
                        <Td key={`cell${index2}`}>
                          <PlaceHolder isLoaded={!loading} height={15} width={40} padding={5} />
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              )
}
            {!loading && (
              <Tbody>
                {rows.map((row, rowindex) => (
                  <Tr key={row[0].value}>
                    {row && row.map((cell, index) => (
                      <Td textAlign={cell.style} key={`cell${index}`}>
                        {(rowindex >= rows.length - 1) && (
                          <Text fontWeight="bold" fontSize={12}>
                            {cell.value}
                            {cell.postfix}
                          </Text>
                        )}
                        {(rowindex < rows.length - 1) && (
                          <Text fontSize={12}>
                            {cell.value}
                            {cell.postfix}
                          </Text>
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </Box>
      </Container>
    </MainLayout>
  );
}

export default NetworkCharacteristics;
