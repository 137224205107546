import { Box } from '@chakra-ui/react';

function Card(props) {
  const { children, ...rest } = props;
  // Pass the computed styles into the `__css` prop
  return (
    <Box
      display="flex"
      flexDirection="column"
      background="white"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      padding="6"
      borderRadius="4"
      boxShadow="base"
      {...rest}
    >
      { children }
    </Box>
  );
}

export default Card;
