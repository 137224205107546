const overallActivityAssessments = [
  'Not Assessed', // grey   - 0
  'Good/Effective', // green  - 1
  'Fit for purpose/developing', // yellow - 2
  'Room for Improvement', // orange
  'Unacceptable/basic', // red    - 4
];

const activityAssessments = [
  'Not Assessed', // grey   - 0
  'Good', // green  - 1
  'Fit for purpose', // yellow - 2
  'Room for Improvement', // orange
];

const smartBuyerAssessments = [
  'Not Assessed', // grey   - 0
  'A Smart Buyer', // green  - 1
  'Developing', // yellow - 2
  'Limited', // orange
  'Basic', // red    - 4
];

const mapActivityToText = (score, filter) => {
  switch (filter) {
    case 'Overall':
      return overallActivityAssessments[score];
    case 'Smart_buyer_selfassessment':
      if (score >= 65) {
        return smartBuyerAssessments[1];
      }
      if (score >= 55) {
        return smartBuyerAssessments[2];
      }
      if (score >= 30) {
        return smartBuyerAssessments[3];
      }
      if (score != null) {
        return smartBuyerAssessments[4];
      }
      return smartBuyerAssessments[0];
    default:
      if (score != null) {
        return activityAssessments[score];
      }
      return activityAssessments[0];
  }
};

const coassuranceAssessment = [
  'Not Available', // blank/grey 0.0
  'Unacceptable', // red        0.25
  'Significant improvement needed', // orange     0.5
  'Some improvement needed', // yellow     0.75
  'Effective', // green      1.00
];

const mapCoassuranceToText = (score) => coassuranceAssessment[score * 4]; // map from 0.0 - 1.0 to 0 - 4

const mapCoInvestorLabel = (isTechnical) => {
  if (isTechnical) {
    return 'Technical Audit';
  }

  return 'Overall Rating';
};

const servicePerformance = {
  pass: 'Target Achieved',
  partial: 'Partially Achieved',
  fail: 'Target Not Achieved',
  na: 'Not Reported',
  'n/a': 'Not Reported',
  noData: 'No data',
};

const mapServicePerformanceToText = (value) => servicePerformance[value];

export {
  mapActivityToText,
  mapCoassuranceToText,
  mapCoInvestorLabel,
  mapServicePerformanceToText,
};
