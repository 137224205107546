import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

// import { categories } from '../api/categories';

const { persistAtom } = recoilPersist();

const selectedFiltersState = atom({
  key: 'selectedCategories',
  default: { categories: [] },
  effects_UNSTABLE: [persistAtom],
});

const selectedCategoryTypeState = atom({
  key: 'selectedCategoryType',
  default: 'all',
  effects_UNSTABLE: [persistAtom],
});

export {
  selectedFiltersState,
  selectedCategoryTypeState,
};
