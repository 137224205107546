import { useEffect, useState } from 'react';
import { Tooltip } from '@chakra-ui/react';
import TrafficLight from '../indicators/TrafficLight';
import councildata from '../../../map/CouncilData';

function HeaderTrafficLight({
  endpointData, size, fieldname, mapText,
}) {
  const defaultColour = 'GREY';
  const [colour, setColour] = useState(defaultColour);
  const [text, setText] = useState('No data');

  useEffect(() => {
    if (endpointData !== undefined) {
      const indicatorColour = councildata.mapValueToColourRange(endpointData, councildata.colours[fieldname]);
      setColour(indicatorColour === 'none' ? defaultColour : indicatorColour);
      setText(mapText(endpointData));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointData]);

  return (
    <Tooltip hasArrow label={text} fontSize="12px">
      <TrafficLight size={size} colour={colour} strokeWidth="12px" stroke="white" />
    </Tooltip>
  );
}

export default HeaderTrafficLight;
