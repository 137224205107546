import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Calendar from '@src/modules/calendar/pages/Calendar';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={Calendar} />
    </Switch>
  );
}
