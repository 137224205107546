import { SSRSColours } from '../views/reports/components/charts/ChartColours';

// these are the totals as shown on summary widget
// and the last row in the net1 table
const calculateTotals = (rows) => {
  // these are the values we want to report on
  // in this specific order
  // but, not all councils will have data for these

  // exclude 'Not Required' && 'Unclassified'
  const HEADERS = {
    'Total (km)': 0,
    'Urban (km)': 0,

    'Rural (km)': 0, // do we need these two
    'Sealed (km)': 0, // ??

    'High Volume (km)': 0,
    'National (km)': 0,
    'Regional (km)': 0,
    'Arterial (km)': 0,
    'Primary Collector (km)': 0,
    'Secondary Collector (km)': 0,
    'Access (km)': 0,
    'Low Volume (km)': 0,

    'Annual Journeys (M VKT)': 0,
  };

  const result = [];

  const urban_total = rows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.length, 0);

  const rural_total = rows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.length, 0);

  const unclassified_total = rows.filter((r) => r.urban_rural !== 'R' && r.urban_rural !== 'U').reduce((acc, row) => acc + row.length, 0);

  const sealed_total = rows.filter((r) => r.Sealed === 1).reduce((acc, row) => acc + row.length, 0);

  /* const unsealed_total = rows.filter(r => r.Sealed === 0).reduce((acc, row) => {
    return acc + row.length
  }, 0) */

  HEADERS['Total (km)'] = urban_total + rural_total + unclassified_total;
  HEADERS['Urban (km)'] = urban_total;
  HEADERS['Rural (km)'] = rural_total;
  HEADERS['Sealed (km)'] = sealed_total;

  // collect the distinct categories and calculate totals for each
  const unique = [...new Set(rows.map((item) => item.category))].filter((r) => r !== 'Unclassified' && r !== 'Not Required');

  unique.forEach((category) => {
    const length = rows.filter((r) => r.category === category).reduce((acc, row) => acc + row.length, 0);

    if (length > 0.0) {
      HEADERS[`${category} (km)`] = length;
    }
  });

  // annual journeys (m vkt)
  const urban_total_vkt = rows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.vkt, 0);

  const rural_total_vkt = rows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.vkt, 0);

  const annual_total = urban_total_vkt + rural_total_vkt;

  HEADERS['Annual Journeys (M VKT)'] = annual_total;

  // filter the final result
  Object.keys(HEADERS).forEach((key) => {
    if (Math.round(HEADERS[key]) > 0.0) {
      result.push({
        title: key,
        value: HEADERS[key].toLocaleString('en-NZ', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      });
    }
  });

  return result;
};

const format = (v, precision, postfix, style) => {
  if (v > 0) {
    return {
      value: `${v.toLocaleString('en-NZ', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })}`,
      style,
      postfix,
    };
  }
  return {
    value: '',
    style,
    postfix: '',
  };
};

// arbitrary order for results
const ORDER = [
  'High Volume',
  'National',
  'Regional',
  'Arterial',
  'Primary Collector',
  'Secondary Collector',
  'Access',
  'Low Volume',
  'Not Required',
  'Unclassified',
  'TOTAL NETWORK',
];

const generateTableContents = (rows) => {
  const results = [];

  const unique = [...new Set(rows.map((item) => item.category))];

  let total_network_length = 0;
  let total_urban = 0;
  let total_rural = 0;
  let total_sealed = 0;
  let total_unsealed = 0;
  let total_lane = 0;
  let total_urbanjourneys = 0;
  let total_ruraljourneys = 0;
  let total_annualtotaljourneys = 0;

  // we need an unformatted copy of the total_length for each category
  // for the final percentage calculation
  const column1_totals = [];

  unique.forEach((category) => {
    const catrows = rows.filter((r) => r.category === category);

    const urban = catrows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.length, 0);
    const rural = catrows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.length, 0);
    const sealed = catrows.filter((r) => r.Sealed === 1).reduce((acc, row) => acc + row.length, 0);
    let unsealed = catrows.filter((r) => r.Sealed !== 1).reduce((acc, row) => acc + row.length, 0);

    const lane = catrows.reduce((acc, row) => acc + row.lane_km, 0);

    const urbanjourneys = catrows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.vkt, 0);

    const ruraljourneys = catrows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.vkt, 0);

    const annualtotaljourneys = urbanjourneys + ruraljourneys;

    let total_length = urban + rural;

    // unclassified shows up as unsealed, but is not counted there,
    // gets added to total instead
    if (category === 'Unclassified') {
      total_length += unsealed;
      unsealed = 0.0;
    }

    const percentageoflength = 0;

    const row = [
      {
        value: category,
        style: 'left',
      },
      format(total_length, 1, '', 'right'),
      format(urban, 1, '', 'right'),
      format(rural, 1, '', 'right'),
      format(sealed, 1, '', 'right'),
      format(unsealed, 1, '', 'right'),
      format(lane, 1, '', 'right'),
      format(urbanjourneys, 1, '', 'right'),
      format(ruraljourneys, 1, '', 'right'),
      format(annualtotaljourneys, 1, '', 'right'),
      format(percentageoflength, 0, '%', 'right'),
    ];

    column1_totals.push(total_length); // set this raw value aside so we can use it for percentage calculation

    total_network_length += total_length;
    total_urban += urban;
    total_rural += rural;
    total_sealed += sealed;
    total_unsealed += unsealed;
    total_lane += lane;
    total_urbanjourneys += urbanjourneys;
    total_ruraljourneys += ruraljourneys;
    total_annualtotaljourneys += annualtotaljourneys;

    results.push(row);
  });

  // calculate the percentages for each row
  // we can't use the formatted values, so we use the values we set aside in
  // column1_totals
  results.forEach((row, index) => {
    let percentage = 0;
    if (column1_totals[index] !== '') {
      percentage = (parseFloat(column1_totals[index]) / total_network_length) * 100.0;
    }
    row[row.length - 1].value = `${percentage.toFixed(0)}`;
    row[row.length - 1].postfix = '%';
    row[row.length - 1].style = 'right';
  });

  // totals row
  const row = [
    {
      value: 'TOTAL NETWORK',
      style: 'left',
    },
    format(total_network_length, 1, '', 'right'),
    format(total_urban, 1, '', 'right'),
    format(total_rural, 1, '', 'right'),
    format(total_sealed, 1, '', 'right'),
    format(total_unsealed, 1, '', 'right'),
    format(total_lane, 1, '', 'right'),
    format(total_urbanjourneys, 1, '', 'right'),
    format(total_ruraljourneys, 1, '', 'right'),
    format(total_annualtotaljourneys, 1, '', 'right'),
    // format(0, 0, '%', 'right', false) // already formatted the last row
  ];

  results.push(row);

  // sort the results to ensure they are in correct order
  results.sort((a, b) => ORDER.indexOf(a[0].value) - ORDER.indexOf(b[0].value));

  return results;
};

const generatePiechart = (rows) => {
  let total_sealed = 0;
  let total_unsealed = 0;

  const unique = [...new Set(rows.map((item) => item.category))].filter((r) => r !== 'Unclassified');

  unique.forEach((category) => {
    const catrows = rows.filter((r) => r.category === category);

    const sealed = catrows.filter((r) => r.Sealed === 1).reduce((acc, row) => acc + row.length, 0);
    const unsealed = catrows.filter((r) => r.Sealed !== 1).reduce((acc, row) => acc + row.length, 0);

    total_sealed += sealed;
    total_unsealed += unsealed;
  });

  const one_percent = (total_sealed + total_unsealed) / 100.0;

  const perc_sealed = Math.round(total_sealed / one_percent);
  const perc_unsealed = Math.round(total_unsealed / one_percent);

  const decimalFormat = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  return {
    data: [perc_sealed, perc_unsealed],
    colours: [SSRSColours.blue, SSRSColours.orange],
    labels: [`Sealed (${total_sealed.toLocaleString('en-NZ', decimalFormat)} km)`, `Unsealed (${total_unsealed.toLocaleString('en-NZ', decimalFormat)} km)`],
    postfix: '%',
  };
};

const generateTornadoChart = (rows) => {
  const unique = [...new Set(rows.map((item) => item.category))].filter((c) => c !== 'Unclassified');

  unique.sort((a, b) => ORDER.indexOf(a) - ORDER.indexOf(b));

  const urban_km = {
    label: '% Urban km',
    colour: SSRSColours.blue,
    data: [],
  };
  const rural_km = {
    label: '% Rural km',
    colour: SSRSColours.orange,
    data: [],
  };
  const urban_vkt = {
    label: '% Urban Vkt',
    colour: SSRSColours.blue,
    data: [],
  };
  const rural_vkt = {
    label: '% Rural Vkt',
    colour: SSRSColours.orange,
    data: [],
  };

  // calculate total network length
  let total_network = 0;
  let total_vkt = 0;
  unique.forEach((category) => {
    const catrows = rows.filter((r) => r.category === category);
    const urban = catrows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.length, 0);
    const rural = catrows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.length, 0);
    total_network += (urban + rural);

    const urbanjourneys = catrows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.vkt, 0);

    const ruraljourneys = catrows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.vkt, 0);

    total_vkt += (urbanjourneys + ruraljourneys);
  });

  unique.forEach((category) => {
    const catrows = rows.filter((r) => r.category === category);

    const urban = catrows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.length, 0);
    const rural = catrows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.length, 0);

    const perca = total_network / 100.0;

    if (perca > 0) {
      const urban_perc = urban / perca;
      const rural_perc = rural / perca;

      urban_km.data.push(urban_perc * -1);
      rural_km.data.push(rural_perc * -1);
    } else {
      urban_km.data.push(0);
      rural_km.data.push(0);
    }

    const urbanjourneys = catrows.filter((r) => r.urban_rural === 'U').reduce((acc, row) => acc + row.vkt, 0);

    const ruraljourneys = catrows.filter((r) => r.urban_rural === 'R').reduce((acc, row) => acc + row.vkt, 0);

    const percb = total_vkt / 100.0;

    if (percb > 0) {
      const urban_vkt_perc = urbanjourneys / percb;
      const rural_vkt_perc = ruraljourneys / percb;

      urban_vkt.data.push(urban_vkt_perc);
      rural_vkt.data.push(rural_vkt_perc);
    } else {
      urban_vkt.data.push(0);
      rural_vkt.data.push(0);
    }
  });

  return {
    labels: unique,
    colours: {
      left: ['red', 'blue'],
      right: ['black', 'orange'],
    },
    data: [
      urban_km,
      rural_km,
      urban_vkt,
      rural_vkt,
    ],
  };
};

export {
  calculateTotals,
  generateTableContents,
  generatePiechart,
  generateTornadoChart,
};
