import { createContext, useContext } from 'react';

// NOTE TO DEVELOPERS:
// If you add more config keys below, be sure to update
// src/configure/ConfigBehaviour.jsx to read the value from
// the environment!

/**
 * @typedef {object} ConfigDefinition
 * @prop {string} PORTAL_NAV_API_URL Shared Portal Navigation API base URL (portal-nav-api)
 * @prop {string} TCE_API_URL TCE API base URL (tce-api)
 * @prop {string} ONF_API_URL ONF API base URL (onf-api)
 */

/** @type {import('react').Context<Readonly<ConfigDefinition>>} */
const ConfigContext = createContext(undefined);

export function useConfig() {
  return useContext(ConfigContext);
}

/**
 * @typedef {object} ConfigProviderProps
 * @prop {ConfigDefinition} config
 */

/**
 * @param {import('react').PropsWithChildren<ConfigProviderProps>} props
 */
export function ConfigProvider(props) {
  const { config, children } = props;

  // freeze config object for extra safety
  const frozenConfig = Object.freeze(config);

  return (
    <ConfigContext.Provider value={frozenConfig}>{ children }</ConfigContext.Provider>
  );
}
