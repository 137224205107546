import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TrafficGroupPage from '@src/modules/tce/src/components/pages/trafficgroup/TrafficGroup';
import TrafficCountImport from '@src/modules/tce/src/components/pages/import/TrafficCountImport';
import TrafficCountEstimates from '@src/modules/tce/src/components/pages/traffic-count-estimates/TrafficCountEstimate';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/traffic-group`} component={TrafficGroupPage} />
      <Route path={`${path}/estimates/:jobId`} component={TrafficCountEstimates} />
      <Route path={`${path}/estimates/`} component={TrafficCountImport} />
      <Route component={TrafficCountImport} />
    </Switch>
  );
}
