import { Checkbox, HStack, VStack } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import {
  tceWizardFilterOnlyInconsistentTrafficMix,
  tceWizardFilterOnlySignificantChanges,
} from '@tce/state/tce';

function Step1Filter({ name }) {
  const [onlyInconsistentTrafficMix, setOnlyInconsistentTrafficMix] = useRecoilState(tceWizardFilterOnlyInconsistentTrafficMix(name));
  const onChangeOnlyInconsistentTrafficMix = () => setOnlyInconsistentTrafficMix(!onlyInconsistentTrafficMix);

  const [onlySignificantChanges, setOnlySignificantChanges] = useRecoilState(tceWizardFilterOnlySignificantChanges(name));

  const onChangeOnlySignificantChanges = () => setOnlySignificantChanges(!onlySignificantChanges);

  return (
    <HStack w="full" alignItems="start">
      <VStack alignItems="start">
        <Checkbox
          colorScheme="blue"
          isChecked={onlySignificantChanges}
          onChange={(e) => onChangeOnlySignificantChanges(e)}
        >
          Show significant changes in Count (+/- 15% or 100vpd)
        </Checkbox>
        <Checkbox
          colorScheme="blue"
          isChecked={onlyInconsistentTrafficMix}
          onChange={(e) => onChangeOnlyInconsistentTrafficMix(e)}
        >
          Show Counts with an inconsistent Traffic Mix
        </Checkbox>
      </VStack>
    </HStack>
  );
}

export {
  Step1Filter,
};
