import {
  Box,
  Container,
  Flex,
  Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import { useServicePerformance, useServicePerformanceKPI } from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import HeaderServicePerformance from './components/selfloading/HeaderServicePerformance';
import TrafficLightTable from './components/selfloading/TrafficLightTable';
import html from './help/ServicePerformance.html';

const subheader = 'LGA Non-Financial Performance Measures';
const subtitle = 'Annual Targets Achieved';

function ServicePerformance() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const servicePerformanceKpi = useServicePerformanceKPI();
  const servicePerformance = useServicePerformance();
  const headerKpiDescription = servicePerformanceKpi.data?.[0]?.description;
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header
            title="Service Performance"
            subtitle={subheader}
            info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
          >
            <Flex>
              <Box mr={5}>
                <HeaderServicePerformance queryInfo={servicePerformanceKpi} />
              </Box>
              <Box pr={15}>
                <Text mt={3} fontWeight="bold" color="gray" whiteSpace="pre-line">
                  {headerKpiDescription}
                </Text>
              </Box>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Text mb={5} textStyle="reportSectionHeader">{subtitle}</Text>
            <Box>
              <TrafficLightTable queryInfo={servicePerformance} />
            </Box>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default ServicePerformance;
