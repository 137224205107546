import { useEffect, useState } from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Flex,
} from '@chakra-ui/react';
import TrafficLight from '../indicators/TrafficLight';

function SmallTrafficLightTable({ queryInfo, sourceText }) {
  const [data, setData] = useState({
    title: '',
    labels: [],
    values: [],
  });

  useEffect(() => {
    if (queryInfo?.data) {
      setData(() => queryInfo.data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th colSpan={2} textAlign="left" textTransform="none">
            <Flex width="100%" align="center" my={2}>
              <Text fontSize={12} textStyle="reportSectionHeader" mr={2}>{data.title}</Text>
            </Flex>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.labels.map((row, index) => (
          <Tr key={row}>
            <Td>
              <Text align="left">{row}</Text>
            </Td>
            <Td key={`${row}`}>
              <Center>
                <TrafficLight size={12} colour={data.values[index]} />
              </Center>
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td border="none">
            <Text fontSize="sm" mt={4}>
              {sourceText}
              {' '}
              {data?.date}
            </Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}

export default SmallTrafficLightTable;
