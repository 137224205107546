import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useToast } from '@chakra-ui/react';
import useTemporaryTrafficManagementApi from '../api/useTemporaryTrafficManagementApi';

export function useCurrentTtmEntryPeriod() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();

  return useQuery(
    ['temporary-traffic-management-entry-period', rcaId],
    async () => api.getCurrentTtmEntryPeriod(rcaId),
  );
}

export function useTtmList() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();

  return useQuery(
    ['temporary-traffic-management', rcaId],
    async () => api.getTtmList(rcaId),
  );
}

export function useTtmReportOptions() {
  const api = useTemporaryTrafficManagementApi();

  return useQuery(
    ['temporary-traffic-management-report-options'],
    async () => api.getTtmReportOptions(),
    {
      cacheTime: 3600,
    },
  );
}

export function useTtmReport(lockYear, quarter) {
  const api = useTemporaryTrafficManagementApi();
  const convertedLockYear = lockYear?.replace('/', '-');

  return useQuery(
    ['temporary-traffic-management-report', lockYear, quarter],
    async () => api.getTtmReport(convertedLockYear, quarter),
    {
      enabled: Boolean(convertedLockYear && quarter),
      cacheTime: 3600,
    },
  );
}

export function useTemporaryTrafficManagementKpi() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();
  // Refactor data to convert value to a percentage
  return useQuery(
    ['temporary-traffic-management-kpi', rcaId],
    async () => api.getTemporaryTrafficManagementKpi(rcaId),
    {
      select: (data) => ({ ...data, value: data.value }),
    },
  );
}

export const useCreateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (ttmResult) => api.createTtmResult(ttmResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        toast({
          title: 'Success',
          description: 'Result was successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be created. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useUpdateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (ttmResult) => api.updateTtmResult(ttmResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        toast({
          title: 'Success',
          description: 'Result was successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be updated. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useActivateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ quarterNumber, lockYear }) => api.activateTtmResults({ quarterNumber, lockYear }).then((result) => result),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        queryClient.invalidateQueries('temporary-traffic-management-report');
        toast({
          title: 'Success',
          description: 'Results were successfully activated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Results could not be activated. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useDeactivateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ quarterNumber, lockYear }) => api.deactivateTtmResults({ quarterNumber, lockYear }).then((result) => result),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        queryClient.invalidateQueries('temporary-traffic-management-report');
        toast({
          title: 'Success',
          description: 'Results were successfully deactivated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Results could not be deactivated. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useCreateTtmSiteInspection = () => {
  const api = useTemporaryTrafficManagementApi();
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (ttmSiteInspection) => api.createTtmSiteInspection({ ...ttmSiteInspection, quadDatabaseId: rcaId }).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management-site-inspections');
        toast({
          title: 'Success',
          description: 'Site inspection was successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Site inspection could not be created. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export function useGetTtmSiteInspectionList({ startDate, endDate }) {
  const api = useTemporaryTrafficManagementApi();
  const rcaId = useRecoilValue(selectedDatabaseIdState);

  return useQuery(
    ['temporary-traffic-management-site-inspections', rcaId, startDate, endDate],
    async () => api.getTtmSiteInspectionList({ rcaId, startDate, endDate }),
    {
      cacheTime: 3600,
      enabled: rcaId !== undefined && startDate !== undefined && endDate !== undefined,
    },
  );
}
