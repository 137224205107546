import {
  AlertDialog, AlertDialogOverlay, AlertDialogContent,
  AlertDialogBody, AlertDialogFooter, Button,
} from '@chakra-ui/react';

function AccessWarningDialog({
  isOpen,
  onCancel,
}) {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody>
            Access Denied - You do not have access to this page.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onCancel}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default AccessWarningDialog;
