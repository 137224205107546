import { TrafficLightColours as COLOURS, deliveryPerformanceLegend } from '@src/modules/performance/shared/constants';
import { useDeliveryPerformanceKPI } from '@src/modules/performance/context/map-api-hooks';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import { Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';
import IndicatorLegend from '../IndicatorLegend';

export default function DeliveryPerformanceWidget() {
  const { data: deliveryPerformanceData, isLoading, isError } = useDeliveryPerformanceKPI();
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const rcaId = currentDatabase?.id;

  const title = 'Road Maintenance Delivery';
  const description = 'Budget Variance (%)';
  const colour = mapValueToColourRange(deliveryPerformanceData?.varianceExclEmergency, 'deliveryPerformance');

  // If there is an error and no data we want to hide the widget
  if (isError && (deliveryPerformanceData?.variance === undefined || !deliveryPerformanceData?.variance)) return null;

  return (
    <Widget
      link={rcaId !== 81 ? '/performance/reports/delivery-performance' : null}
      title={title}
      description={description}
      lockYear={deliveryPerformanceData?.quadLockYear}
      quarter={deliveryPerformanceData?.quarter}
      isLoading={isLoading && !deliveryPerformanceData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            Approved budget vs expenditure variance for all maintenance activities excluding emergency works, from the start of the NLTP period.
          </Text>
          <IndicatorLegend items={deliveryPerformanceLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[colour]}
        value={deliveryPerformanceData?.varianceExclEmergency !== null && deliveryPerformanceData?.varianceExclEmergency !== undefined ? `${roundKpiValue(deliveryPerformanceData?.varianceExclEmergency, 5, 1)}%` : null}
        isLoading={isLoading}
        padding={0}
      />
    </Widget>
  );
}
