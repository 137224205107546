/* eslint-disable react/display-name */
import React from 'react';
import { Icon } from '@chakra-ui/react';
import { TrafficLightColours as COLOURS } from '../charts/ChartColours';

// uses a forward ref so that we can wrap this in a tooltip
const TrafficLight = React.forwardRef(({ colour, size, ...rest }, ref) => (
  <Icon viewBox="0 0 200 200" ref={ref} boxSize={size} color={COLOURS[colour]} {...rest}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
));

export default TrafficLight;
