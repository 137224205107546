import { useSettingsApi } from '@src/modules/settings/api/settings';
import { useUserInfo } from '@transport-insights/uikit';
import { useQuery } from 'react-query';

export function useNews() {
  const api = useSettingsApi();
  const { isLoggedIn } = useUserInfo();

  return useQuery(
    ['news', isLoggedIn],
    async () => api.getNews(),
    {
      enabled: isLoggedIn,
    },
  );
}

export function useSettingsConfig() {
  const api = useSettingsApi();

  return useQuery(
    ['settings-config'],
    async () => api.getConfig(),
  );
}
