import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { selectedJobIdState } from '@tce/state/tce';
import useTceApi from '../api/useTceApi';

export function useRoadSectionCategories() {
  const api = useTceApi();
  const selectedJobId = useRecoilValue(selectedJobIdState);

  const { isLoading, data: categories, error } = useQuery(
    ['tce-roadsectioncategories', selectedJobId],
    async () => api.getRoadSectionCategories(selectedJobId),
    {
      enabled: selectedJobId !== null,
    },
  );

  return {
    isLoading,
    categories,
    error,
  };
}

export function useRoadSectionHierarchies() {
  const api = useTceApi();
  const selectedJobId = useRecoilValue(selectedJobIdState);

  const { isLoading, data: hierarchies, error } = useQuery(
    ['tce-roadsectionhierarchies', selectedJobId],
    async () => api.getRoadSectionHierarchies(selectedJobId),
    {
      enabled: selectedJobId !== null,
    },
  );

  return {
    isLoading,
    hierarchies,
    error,
  };
}

// get a single road section
export function useRoadSection(sectionId) {
  const api = useTceApi();
  const selectedJobId = useRecoilValue(selectedJobIdState);

  const { isLoading, data: roadSection, error } = useQuery(
    ['tce-roadsection', selectedJobId, sectionId],
    async () => api.getRoadSection(selectedJobId, sectionId),
    {
      enabled: selectedJobId !== null,
    },
  );

  return {
    isLoading,
    roadSection,
    error,
  };
}

// get a list of road sections, using a search filter
// Remove mockResult when real data is available
export function useRoadSections(search, startRow, numberOfRows) {
  const api = useTceApi();
  const selectedJobId = useRecoilValue(selectedJobIdState);

  const params = {
    page: startRow,
    rows: numberOfRows,
    ...search,
  };

  const { isLoading, data: roadSections, error } = useQuery(
    ['tce-roadsections', selectedJobId, params],
    async () => api.getRoadSections(selectedJobId, startRow, numberOfRows, search),
    {
      enabled: (selectedJobId !== null && startRow >= 0 && !!numberOfRows),
    },
  );
  return {
    isLoading,
    roadSections,
    error,
  };
}

export function useUpdateRoadSectionMutation(mutationOptions = undefined) {
  const selectedJobId = useRecoilValue(selectedJobIdState);
  const api = useTceApi();

  return useMutation({
    ...mutationOptions,
    mutationFn: (submission) => api.updateRoadSection(selectedJobId, submission),
    onSuccess: (result) => mutationOptions?.onSuccess?.(result),
  });
}

export function useAssignTrafficGroupMutation(mutationOptions = undefined) {
  const selectedJobId = useRecoilValue(selectedJobIdState);
  const api = useTceApi();

  return useMutation({
    ...mutationOptions,
    mutationFn: (submission) => api.assignTrafficGroup(selectedJobId, submission),
    onSuccess: (result) => mutationOptions?.onSuccess?.(result),
  });
}
