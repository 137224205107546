import { Pie } from 'react-chartjs-2';
import { TrafficLightColours as COLOURS } from './ChartColours';

function PieChart({
  height, width, values, colours,
}) {
  const data = {
    labels: [],
    datasets: [
      {
        data: values,
        backgroundColor: [],
        borderWidth: 2, // white border line
      },
    ],
  };

  colours.forEach((col) => {
    data.datasets[0].backgroundColor.push(COLOURS[col]);
  });

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <Pie data={data} options={options} height={height} width={width} />
  );
}

export default PieChart;
