import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { ONF_API_URL } = useConfig();
  const api = useAuthenticatedApi(ONF_API_URL);

  return {
    /* -------------------------------------------
                  ONF Reports endpoints
       -------------------------------------------- */
    // get the data for the specified report
    async getReportData(reportName, rcaId, lockYear, params) {
      if (!lockYear) return [];
      // NOTE:
      // Azure does NOT like slashes in url parameters, even when you encode them!
      // so just replace it with a dash here and change it back to a slash in the backend :)
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/report/${reportName}/${rcaId}/${encodedLockYear}`;

      const res = await api.post(url, params);
      return res?.data || [];
    },

    async getNetworkSelectorData(rcaId, lockYear) {
      if (!lockYear) return [];
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/network-selector-params/${rcaId}/${encodedLockYear}`;
      const res = await api.get(url);
      return res?.data || [];
    },

    async getGlossaryData() {
      const url = '/glossary';
      const res = await api.get(url);
      return res?.data || [];
    },

    async getDataQuality(reportCode, rcaId, lockYear) {
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `/data-quality/${reportCode}/${rcaId}/${encodedLockYear}`;
      const res = await api.post(url);
      return res?.data || [];
    },
    async getRegionalReportData(reportName, rcaId, lockYear) {
      if (!lockYear) return [];
      // NOTE:
      // Azure does NOT like slashes in url parameters, even when you encode them!
      // so just replace it with a dash here and change it back to a slash in the backend :)
      const encodedLockYear = lockYear.replace('/', '-');
      const url = `regional-reporting/${reportName}/${rcaId}/${encodedLockYear}`;

      const res = await api.post(url);
      return res?.data || [];
    },
  };
};
