// These are the colours used by bar, tornado and line charts
export const ChartColours = {
  red: 'rgb(255, 69, 0)',
  blue: 'rgb(30, 144, 255)', // dodger blue
  lightblue: 'rgb(17, 141, 255)',
  black: 'rgb(30, 30, 30)', // gray 30
  white: 'rgb(255, 255, 255)',
  grey: 'rgba(127, 127, 127, 1.0)',
  transred: 'rgba(250, 128, 114, 0.6)', // salmon 0.6
  transblue: 'rgba(70, 130, 180, 0.6)', // steelblue3 0.6
  transgrey: 'rgba(127, 127, 127, 0.6)', // grey50 0.6 - used by the stacked area graphs so that the x-axis grid shines through
  transgreen: 'rgba(102, 221, 170, 0.6)', // mediumaquamarine 0.6 #66ddaa
  transorange: 'rgba(237,137,54, 0.6)',
  lightgrey: 'rgba(80, 80, 80, 0.3)',
  darkblue: 'rgb(18, 35,158)',
  orange: 'rgb(237,137,54)',
  // used by economic chart
  orangered: 'rgb(255,69,0)',
  seagreen: 'rgb(67,205,128)',
  magenta: 'rgb(139,0,139)',
};

export const TrafficLightColours = {
  GREEN: '#5FAB12',
  YELLOW: '#F7C622',
  ORANGE: '#F66F22',
  RED: '#C01F24',
  GREY: '#CCCCCC',
};

// these are the lovely colours used by the SSRS report =^x.x^=
export const SSRSColours = {
  orange: '#e38628', // drab orange
  blue: '#7a9ca1', // drab blue-ish
};
