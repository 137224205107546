import Hsp1 from '../reports/Hsp1';
import Hsp2 from '../reports/Hsp2';
import Hsp3 from '../reports/Hsp3';
import Hsp4 from '../reports/Hsp4';
import Hsp5 from '../reports/Hsp5';
import Ia1 from '../reports/Ia1';
import Ia2 from '../reports/Ia2';
import Ep1 from '../reports/Ep1';
import Ep2 from '../reports/Ep2';
import Ep3 from '../reports/Ep3';
import Ep4 from '../reports/Ep4';

export default function ReportSelector({
  reportId, filters, selectedToggleOption = null, reportData, networkSelector,
}) {
  switch (reportId) {
    case 'hsp1':
      return <Hsp1 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'hsp2':
      return <Hsp2 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'hsp3':
      return <Hsp3 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'hsp4':
      return <Hsp4 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'hsp5':
      return <Hsp5 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'ia1':
      return <Ia1 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'ia2':
      return <Ia2 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'ep1':
      return <Ep1 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'ep2':
      return <Ep2 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'ep3':
      return <Ep3 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    case 'ep4':
      return <Ep4 filters={filters} selectedToggleOption={selectedToggleOption} reportData={reportData} networkSelector={networkSelector} />;
    default:
      break;
  }
}
