import {
  Container,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { isEmpty, groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import { usePortalNavContext } from '@transport-insights/uikit';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

export default function SafetyReport() {
  const theme = useTheme();
  const { isLoading } = usePortalNavContext();
  const { data: report, isLoading: isLoadingReport } = useReports('safety');
  const years = [...new Set(report?.data?.map((x) => x.latestYear))].sort();
  const regionName = report?.rcaName?.replace('Region', '');
  const { description, notification } = reportsList.find((x) => x.url === 'safety');

  const transformedDataByCategory = Object.entries(groupBy(report?.data, 'countType'))
    .map(([k, v]) => ({
      name: k !== 'DSI' ? k : 'Per million kilometers travelled',
      type: k !== 'DSI' ? 'column' : 'line',
      data: v.map((item) => ({
        year: item.latestYear,
        value: k !== 'DSI' ? item.countTotal : item.perMVKT,
      })),
    }))
    .sort((a) => (a.name === 'Per million kilometers travelled' ? 1 : -1));

  if (isLoading || isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="safety" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="safety" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="safety" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text>{notification}</Text>
                </InfoPane>
              )}
          <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
            Injuries on
            {' '}
            {regionName}
            {' '}
            roads
          </Heading>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    type: 'bar',
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: [theme.colors.brand.blue[700]],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: false,
                  },
                  xaxis: {
                    categories: years,
                  },
                  yaxis: [
                    {
                      seriesName: 'Per million kilometers travelled',
                      axisTicks: {
                        show: true,
                      },
                      axisBorder: {
                        show: true,
                      },
                      title: {
                        text: 'Per million kilometers travelled',
                      },
                      min: 0,
                      tickAmount: 6,
                      labels: {
                        formatter(val) {
                          return val.toFixed(2);
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                  },
                  stroke: {
                    width: [0],
                  },
                }
              }
              series={transformedDataByCategory
                .filter((item) => item.name === 'Per million kilometers travelled')
                .map((item) => ({
                  name: item.name,
                  type: 'column',
                  data: item.data.map((x) => x.value),
                }))}
              width="100%"
              height="500"
            />
          </ChartContainer>
          <Heading as="h2" mt={6} mb={4} fontWeight="normal" fontSize="2xl">
            Deaths and serious injuries on
            {' '}
            {regionName}
            {' '}
            roads
          </Heading>
          <ChartContainer>
            <Chart
              options={
              {
                chart: {
                  stacked: true,
                  animations: {
                    enabled: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: [theme.colors.brand.blue[700], theme.colors.brand.blue[200], theme.colors.brand.orange[500]],
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  enabled: true,
                  shared: false,
                },
                xaxis: {
                  categories: years,
                },
                yaxis: [
                  {
                    seriesName: 'Serious Injuries',
                    forceNiceScale: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                    },
                    title: {
                      text: 'Deaths and serious injuries',
                    },
                    tickAmount: 6,
                    show: true,
                    labels: {
                      formatter(val) {
                        return val.toFixed(0);
                      },
                    },
                  },
                  {
                    seriesName: 'Serious Injuries',
                    show: false,
                    labels: {
                      formatter(val) {
                        return val.toFixed(0);
                      },
                    },
                  },
                  {
                    opposite: true,
                    seriesName: 'Per million kilometers travelled',
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                    },
                    title: {
                      text: 'Per million kilometers travelled',
                    },
                    forceNiceScale: true,
                    tickAmount: 6,
                    labels: {
                      formatter(val) {
                        return val.toFixed(2);
                      },
                    },
                  },
                ],
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                stroke: {
                  width: [0, 0, 4],
                  curve: 'smooth',
                },
                markers: {
                  size: 8,
                },
              }
            }
              series={transformedDataByCategory.map((item) => ({
                name: item.name,
                type: item.type,
                data: item.data.map((x) => x.value),
              }))}
              width="100%"
              height="500"
            />
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
