import {
  Box, HStack, Text,
  Stack,
} from '@chakra-ui/react';
import { TrafficLightColours } from '../shared/constants';

const colorOrder = [TrafficLightColours.GREEN, TrafficLightColours.YELLOW, TrafficLightColours.ORANGE, TrafficLightColours.RED, TrafficLightColours.GREY];

function sortItemsByColor(items) {
  return items.sort(
    (a, b) => colorOrder.indexOf(a.color) - colorOrder.indexOf(b.color),
  );
}

function IndicatorLegend({ items, direction = 'column', rest }) {
  const sortedItems = sortItemsByColor(items);

  return (
    <Stack direction={direction} align="flex-start" {...rest}>
      {sortedItems.map((item, index) => (
        <HStack key={index} align="center">
          <Box backgroundColor={item.color} boxSize={3} borderRadius="full" flexShrink={0} />
          <Text fontSize="small">{item.label}</Text>
        </HStack>
      ))}
    </Stack>
  );
}

export default IndicatorLegend;
