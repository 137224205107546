import { SkeletonCircle } from '@chakra-ui/react';

function PlaceHolderCircle(props) {
  const { children, ...rest } = props;
  return (
    <SkeletonCircle fadeDuration={0} speed={0} startColor="#efefef" endColor="#efefef" {...rest}>
      {children}
    </SkeletonCircle>
  );
}

export default PlaceHolderCircle;
