import {
  AspectRatio, Text, VStack,
} from '@chakra-ui/react';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import { isFunction } from 'lodash';
import Chart from 'react-apexcharts';

function PieChart({ chartData, isInteractive }) {
  return (
    <Chart
      options={{
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        chart: {
          selection: {
            enabled: isInteractive,
          },
        },
        tooltip: {
          enabled: isInteractive,
          y: {
            formatter: (val) => `${val.toFixed(2)}%`,
          },
        },
        colors: chartData.map((d) => d.color),
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
              value: null,
            },
          },
        },
        stroke: {
          width: 1,
        },
        labels: chartData.map((d) => d.label),
      }}
      series={chartData.map((d) => d.value)}
      type="pie"
      height="75%"
      width="200%"
    />
  );
}

export function AssetCard({
  title, titleColor, chartData, isSelected, onClick, isInteractive,
}) {
  const hasOnClick = isFunction(onClick);
  return (
    <ChartContainer
      cursor={hasOnClick ? 'pointer' : 'default'}
      borderColor={isSelected ? 'brand.orange.400' : 'gray.100'}
      borderWidth="2px"
      _hover={hasOnClick ? { borderColor: 'brand.orange.400' } : {}}
      onClick={onClick}
    >
      <AspectRatio ratio={1}>
        <VStack>
          <Text textStyle="cardHeader" whiteSpace="nowrap" color={titleColor}>
            {title}
          </Text>
          <PieChart chartData={chartData} isInteractive={isInteractive} />
        </VStack>
      </AspectRatio>
    </ChartContainer>
  );
}
