const councils = [
  { id: 7, name: 'Taupo District Council' },
  { id: 8, name: 'Matamata-Piako District Council' },
  { id: 9, name: 'Hauraki District Council' },
  { id: 10, name: 'South Waikato District Council' },
  { id: 11, name: 'Hamilton City Council' },
  { id: 12, name: 'Opotiki District Council' },
  { id: 13, name: 'Western Bay of Plenty District Council' },
  { id: 14, name: 'Waitomo District Council' },
  { id: 15, name: 'Tauranga City Council' },
  { id: 16, name: 'Whakatane District Council' },
  { id: 17, name: 'Otorohanga District Council' },
  { id: 18, name: 'Thames-Coromandel District Council' },
  { id: 19, name: 'Waipa District Council' },
  { id: 20, name: 'Rotorua Lakes Council' },
  { id: 21, name: 'Kawerau District Council' },
  { id: 22, name: 'Waikato District Council' },
  { id: 23, name: 'Carterton District Council' },
  { id: 24, name: 'Hutt City Council' },
  { id: 25, name: 'Kapiti Coast District Council' },
  { id: 26, name: 'Marlborough District Council' },
  { id: 30, name: 'Nelson City Council' },
  { id: 31, name: 'Porirua City Council' },
  { id: 32, name: 'South Wairarapa District Council' },
  { id: 33, name: 'Tasman District Council' },
  { id: 34, name: 'Upper Hutt City Council' },
  { id: 37, name: 'Wellington City Council' },
  { id: 38, name: "Central Hawke's Bay District Council" },
  { id: 39, name: 'Gisborne District Council' },
  { id: 40, name: 'Hastings District Council' },
  { id: 41, name: 'Horowhenua District Council' },
  { id: 42, name: 'Manawatu District Council' },
  { id: 43, name: 'Napier City Council' },
  { id: 44, name: 'New Plymouth District Council' },
  { id: 45, name: 'Palmerston North City Council' },
  { id: 46, name: 'Rangitikei District Council' },
  { id: 47, name: 'Ruapehu District Council' },
  { id: 48, name: 'South Taranaki District Council' },
  { id: 49, name: 'Stratford District Council' },
  { id: 54, name: 'Tararua District Council' },
  { id: 55, name: 'Wairoa District Council' },
  { id: 56, name: 'Whanganui District Council' },
  { id: 57, name: 'Ashburton District Council' },
  { id: 58, name: 'Buller District Council' },
  { id: 59, name: 'Central Otago District Council' },
  { id: 60, name: 'Clutha District Council' },
  { id: 61, name: 'Dunedin City Council' },
  { id: 62, name: 'Gore District Council' },
  { id: 63, name: 'Grey District Council' },
  { id: 64, name: 'Hurunui District Council' },
  { id: 65, name: 'Invercargill City Council' },
  { id: 66, name: 'Kaikoura District Council' },
  { id: 67, name: 'Mackenzie District Council' },
  { id: 68, name: 'Queenstown-Lakes District Council' },
  { id: 69, name: 'Southland District Council' },
  { id: 70, name: 'Timaru District Council' },
  { id: 71, name: 'Waimate District Council' },
  { id: 72, name: 'Waitaki District Council' },
  { id: 73, name: 'Westland District Council' },
  { id: 74, name: 'Waimakariri District Council' },
  { id: 75, name: 'Selwyn District Council' },
  { id: 76, name: 'Christchurch City Council' },
  { id: 77, name: 'Whangarei District Council' },
  { id: 78, name: 'Kaipara District Council' },
  { id: 79, name: 'Far North District Council' },
  { id: 80, name: 'Auckland Transport' },
  { id: 86, name: 'Chatham Islands Council' },
  { id: 87, name: 'Masterton District Council' },
];

const getCouncilNameFromId = (idString) => {
  const id = parseInt(idString, 10);
  return councils.find((c) => c.id === id)?.name ?? '';
};

const councilIds = councils.map((c) => c.id);

export { councils, councilIds, getCouncilNameFromId };
