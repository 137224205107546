import {
  Flex,
  Link,
  Tooltip,
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  LinkBox,
  LinkOverlay,
  Text,
  Box,
  Heading,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import {
  FiArrowRight, FiArrowLeft, FiList, FiDownload,
} from 'react-icons/fi';
import { reportsList } from '@src/modules/onf/api/reports-list';
import { useEffect } from 'react';

export default function Pagination({ currentReportId, reportData }) {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const enabledReportsList = reportsList.filter((x) => x.enabled);

  const prevIndex = enabledReportsList.findIndex((report) => report.id === currentReportId) - 1;
  const nextIndex = enabledReportsList.findIndex((report) => report.id === currentReportId) + 1;

  const nextReport = nextIndex !== enabledReportsList.length ? enabledReportsList[nextIndex] : enabledReportsList[0];
  const prevReport = prevIndex !== -1 ? enabledReportsList[prevIndex] : enabledReportsList[enabledReportsList.length - 1];

  // Remove some properties we dont need for the csv
  const csvData = reportData.data.map(({
    id, rank, minDate, maxDate, streetFamily, ...keep
  }) => keep);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentReportId]);

  return (
    <>
      <Flex align="center" justify="space-between">
        {prevReport
          && (
          <Tooltip label={prevReport.name}>
            <Link
              as={RouterLink}
              to={`/onf/transport-outcomes/${prevReport.id}`}
              variant="reportLink"
              colorScheme={theme.colors.transportIndicator[prevReport.group]}
            >
              <Icon as={FiArrowLeft} boxSize={8} />
            </Link>
          </Tooltip>
          )}
        {nextReport
          && (
          <Tooltip label={nextReport.name}>
            <Link
              as={RouterLink}
              to={`/onf/transport-outcomes/${nextReport.id}`}
              variant="reportLink"
              colorScheme={theme.colors.transportIndicator[nextReport.group]}
            >
              <Icon as={FiArrowRight} boxSize={8} />
            </Link>
          </Tooltip>
          )}
        <Tooltip label="Transport Outcomes List">
          <IconButton onClick={onOpen} icon={<FiList />} borderRadius="full" colorScheme="gray" ml={3} />
        </Tooltip>
        <CsvDownloader
          filename={`${reportData.rcas[0]?.rcaName} - ${reportData.reportName} (${reportData?.lockYear.replace('/', '-')})`}
          extension=".csv"
          separator=","
          datas={csvData}
        >
          <Tooltip label="Download Report CSV">
            <IconButton icon={<FiDownload />} borderRadius="full" colorScheme="gray" ml={3} isDisabled={reportData.data.length === 0} />
          </Tooltip>
        </CsvDownloader>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(4px)"
        />
        <ModalContent>
          <ModalHeader>Transport Outcomes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {enabledReportsList.map((report) => (
              <LinkBox
                as="article"
                backgroundColor="white"
                p={2}
                borderRadius="md"
                key={report.id}
                border="2px solid"
                borderColor="gray.50"
                mb={4}
                transition="all 200ms"
                _hover={{ borderColor: theme.colors.transportIndicator[report.group] }}
              >
                <Flex align="center" mb={2}>
                  <Box backgroundColor={theme.colors.transportIndicator[report.group]} width={3} height={3} mr={2} borderRadius="full" />
                  <Text color={theme.colors.transportIndicator[report.group]} fontWeight="bold" fontSize="xs">{report.group}</Text>
                </Flex>
                <Heading size="sm">
                  <LinkOverlay
                    as={RouterLink}
                    to={`/onf/transport-outcomes/${report.id}`}
                    onClick={onClose}
                    color="gray.800"
                    fontWeight="normal"
                    textDecoration="none"
                  >
                    {report.name}
                  </LinkOverlay>
                </Heading>
              </LinkBox>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
