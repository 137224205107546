export const clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'crashes',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#d9534f', // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'crashes',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
};

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'crashes',
  filter: ['!', ['has', 'point_count']],
  paint: {
    // determine colour from properties.death_count if > 1 red otherwise yellow
    'circle-color': [
      'match',
      ['get', 'death_count'],
      0, '#ffe084',
      1, '#da3711',
      '#FFFFFF',
    ],
    'circle-radius': 5,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000',

  },
};

export const roadLayer = {
  id: 'road',
  type: 'line',
  source: 'roads',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#000',
    'line-width': 3,
  },
};

export const onrcLayer = {
  id: 'onrc',
  type: 'line',
  source: 'onrc',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': [
      'match',
      ['get', 'onrc_category'],
      'High Volume', '#FF0000',
      'National', '#FF6600',
      'Regional', '#FFCC00',
      'Arterial', '#FFFF00',
      'Primary Collector', '#00FF00',
      'Secondary Collector', '#00CCFF',
      'Access', '#00FFFF',
      'Low Volume', '#FFA500',
      'Not Required', '#FFFFFF',
      'Unclassified', '#808080',
      'TOTAL NETWORK', '#8B00FF',
      '#8A2BE2',
    ],
    'line-width': 2,
    'line-opacity': 0.7,
  },
};
