import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  Button,
} from '@chakra-ui/react';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '@src/assets/constants';
import { MainHeaderLayout } from '@transport-insights/uikit';

export default function ErrorPage({ error }) {
  useEffect(() => {
    console.error('Unexpected error', error);
  }, [error]);

  return (
    <>
      <MainHeaderLayout />
      <Container maxW="container.lg">
        <Box pb={4}>
          <strong>The system has encountered an unexpected error.</strong>
        </Box>
        <Box pb={4}>
          Please contact ONRC support by emailing
          {' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${SUPPORT_EMAIL}?subject=Competency Assessment`}
          >
            {SUPPORT_EMAIL}
          </a>
          <br />
          or by calling
          {' '}
          <strong>{SUPPORT_PHONE}</strong>
        </Box>
        <Box pb={4}>
          <Button as={RouterLink} to="/">
            Back
          </Button>
        </Box>
        {error && (
        <Accordion allowToggle pt={4}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" color="darkgray">
                  Details
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={12}>
              <code>{ `${error}` }</code>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        )}
      </Container>
    </>
  );
}
