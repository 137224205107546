import {
  Flex,
  Icon,
  Box,
} from '@chakra-ui/react';
import {
  FiInfo,
} from 'react-icons/fi';

export default function InfoPane({ children }) {
  return (
    <Flex align="flex-start" justify="flex-start" my={6} p={4} border="1px solid" borderColor="brand.blue.600" borderRadius="lg">
      <Icon as={FiInfo} boxSize={6} color="brand.blue.700" />
      <Box pl={4}>
        {children}
      </Box>
    </Flex>
  );
}
