import {
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Text,
  Box,
  IconButton,
  Icon,
  Tag,
  VStack,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverAnchor,
  useTheme,
  Link,
} from '@chakra-ui/react';
import {
  FiArrowDown, FiArrowUp, FiMinus, FiInfo,
} from 'react-icons/fi';
import Chart from 'react-apexcharts';
import { useDataQualityReport } from '@src/modules/onf/context/reports';
import { useConfig } from '@src/context/config';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import EmptyState from '@src/components/shared/EmptyState';

function ResultsChart({ data, isPartYear, colors }) {
  const theme = useTheme();
  const boxData = isPartYear ? [] : [data.perc10, data.perc25, data.perc50, data.perc75, data.perc90];
  const barData = () => {
    if (data.Grade2Max !== null && data.Grade1Max !== null) {
      // 5 block barchart
      return [{ data: [data.Grade2Min] }, { data: [data.Grade1Min - data.Grade2Min] }, { data: [data.Grade1Max - data.Grade1Min] }, { data: [data.Grade2Max - data.Grade1Max] }];
    }
    // standard 3 block barchart
    const precalc = Math.max(data.Grade1Min, data.Grade2Min) - Math.min(data.Grade2Min, data.Grade1Min);

    if (data.Reversed) {
      // If reversed, we use Grade1Min
      return [{ data: [data.Grade1Min] }, { data: [precalc] }, { data: [100.0 - precalc - data.Grade1Min] }];
    }
    // If not, we use Grade2Min
    return [{ data: [data.Grade2Min] }, { data: [precalc] }, { data: [100.0 - precalc - data.Grade2Min] }];
  };
  const downToNearestTen = (v) => {
    let value = v;
    if (value % 5 === 0) {
      value = value--;
    }
    return Math.floor(value * 0.2) * 5;
  };
  const upToNearestTen = (v) => {
    let value = v;
    if (value % 5 === 0) {
      value--;
    }
    return Math.ceil(value * 0.2) * 5;
  };
  const getMinimum = (limit, result) => Math.max(limit, Math.min(downToNearestTen(result), downToNearestTen(data.perc10)));
  const getMaximum = (limit, result) => Math.max(limit, Math.max(upToNearestTen(result), upToNearestTen(data.perc90)));
  const getLimits = () => {
    let minresult = null;
    let maxresult = null;
    let haveValue = false;

    if (data.ResultF && !data.ResultFLast) {
      minresult = data.ResultF;
      maxresult = data.ResultF;
      haveValue = true;
    }
    if (!data.ResultF && data.ResultFLast) {
      minresult = data.ResultFLast;
      maxresult = data.ResultFLast;
      haveValue = true;
    }
    if (data.ResultF && data.ResultFLast) {
      minresult = Math.min(data.ResultF, data.ResultFLast);
      maxresult = Math.max(data.ResultF, data.ResultFLast);
      haveValue = true;
    }
    if (!data.ResultF && !data.ResultFLast) {
      minresult = data.ResultMin;
      maxresult = data.perc90 || 5;
      haveValue = true;
    }

    if (data.Grade2Max !== null && data.Grade1Max !== null) {
      if (haveValue) {
        // 5 block barchart
        return [getMinimum(0, minresult), getMaximum(data.Grade2Max + 10, maxresult)];
      }
      // no result, so don't zoom
      return [0, data.Grade2Max + 10];
    } if (haveValue) {
      // standard 3 block 0 - 100 barchart
      return [getMinimum(0, minresult), getMaximum(0, maxresult)];
    }
    // no result, so don't zoom
    return [0, 100];
  };

  return (
    <Box position="relative" maxW="300" height="120">
      <Chart
        options={
            {
              chart: {
                stacked: true,
                stackType: '100%',
                toolbar: {
                  show: false,
                },
                parentHeightOffset: 0,
              },
              colors: data.Reversed ? colors.slice().reverse() : colors,
              legend: {
                show: false,
              },
              yaxis: {
                show: false,
                axisTicks: {
                  show: false,
                },
              },
              xaxis: {
                min: getLimits()[0],
                max: getLimits()[1],
                tickAmount: 5,
                axisBorder: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              tooltip: {
                enabled: false,
              },
              dataLabels: {
                enabled: false,
              },
              annotations: {
                xaxis: [
                  {
                    x: data.ResultFLast,
                    strokeDashArray: 0,
                    opacity: 1,
                    borderWidth: (!isPartYear || data.ResultFLast === null) ? 0 : 3,
                    borderColor: theme.colors.brand.blue[200],
                    label: {
                      text: undefined,
                    },
                  },
                  {
                    x: data.ResultF,
                    strokeDashArray: 0,
                    opacity: 1,
                    borderWidth: 3,
                    borderColor: theme.colors.brand.blue[600],
                    label: {
                      text: undefined,
                    },
                  },
                ],
              },
            }
          }
        series={barData()}
        type="bar"
        width="100%"
        height="100%"
      />
      <Box position="absolute" top="34%" zIndex="9999">
        <Chart
          options={
            {
              chart: {
                stacked: true,
                toolbar: {
                  show: false,
                },
                sparkline: {
                  enabled: true,
                },
              },
              colors: [],
              legend: {
                show: false,
              },
              yaxis: {
                show: false,
                axisBorder: {
                  show: false,
                },
              },
              xaxis: {
                min: getLimits()[0],
                max: getLimits()[1],
                tickAmount: 5,
                axisBorder: {
                  show: false,
                },
                labels: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
                boxPlot: {
                  colors: {
                    upper: 'rgba(255,255,255,0.4)',
                    lower: 'rgba(255,255,255,0.4)',
                  },
                },
              },
              stroke: {
                colors: ['#fff'],
              },
              tooltip: {
                enabled: false,
              },
              dataLabels: {
                enabled: false,
              },
            }
          }
          series={[
            {
              data: [{
                x: 'category 1',
                y: boxData,
              }],
            }]}
          type="boxPlot"
          height="30"
        />
      </Box>
    </Box>
  );
}

export default function DataQualityTable({ reportId }) {
  const config = useConfig();
  const { data, isLoading } = useDataQualityReport(reportId);
  const colors = ['#e3001c', '#ffc419', '#40ab0f'];

  const renderTrend = (trend, range, result, showNullAs) => {
    if (data.isPartYear && range !== 1) {
      if (result !== null || (result === null && showNullAs === 1)) {
        return <Tag>Part year result</Tag>;
      }
    }

    if (trend > 0) {
      return <Icon as={FiArrowUp} color="green.600" boxSize={10} />;
    } if (trend < 0) {
      return <Icon as={FiArrowDown} color="red.600" boxSize={10} />;
    } if (trend === 0) {
      return <Icon as={FiMinus} color="gray.600" boxSize={10} />;
    }
    return <Tag>New</Tag>;
  };

  const renderCustomerOutcomes = (customerOutcomes) => {
    const outcomes = customerOutcomes.map((x) => ({ key: x.replace('Affected', ''), label: x.replace('Affected', '').replace(/([a-z])([A-Z])/g, '$1 $2') }));
    const colorMap = { Amenity: '#bf4d27', Safety: '#c02026', CostEfficiency: '#d69c17' };
    return (
      <VStack alignItems="flex-start">
        {outcomes.map((x) => <Tag size="sm" borderRadius="full" key={x.key} backgroundColor={colorMap[x.key]}>{x.label}</Tag>)}
      </VStack>
    );
  };

  // <!-- show null result as one of three variations -->
  //       <!-- don't use !data.ResultF -->
  //       <span v-if="data.ResultF === null && data.ShowNullAs === 0">NA</span>
  //       <span v-else-if="data.ResultF === null && data.ShowNullAs === 1">0.0</span>
  //       <span v-else-if="data.ResultF === null && data.ShowNullAs === 2">No Data</span>
  //       <!-- or show as fixed decimal or na as per filter -->
  //       <span v-else>{{ data.ResultF | nicenumber }}</span>

  const renderResult = (result, showNullAs, color) => {
    let label = 'NA';
    let textColor = color;

    if (result === null && showNullAs === 0) {
      label = 'NA';
      textColor = 'gray.500';
    } else if (result === null && showNullAs === 1) {
      label = '0.0';
      textColor = 'gray.500';
    } else if (result === null && showNullAs === 2) {
      label = 'No Data';
      textColor = 'gray.500';
    } else if (result >= 0) {
      label = result.toFixed(1);
    }

    return <Text color={textColor} fontWeight="bold" fontSize="lg">{label}</Text>;
  };

  if (isLoading) {
    return (
      <LoadingSpinner />
    );
  }

  if (!data.Results) {
    return (
      <EmptyState title="No Data" message="Sorry there is no data available for selected network" />
    );
  }

  return (
    <Table size="sm" border="1px solid" borderColor="gray.100">
      <Thead>
        <Tr>
          <Th>
            Category
          </Th>
          <Th>
            Ref
          </Th>
          <Th>
            Metric Description
          </Th>
          <Th>
            Dimension
          </Th>
          <Th>
            Importance
          </Th>
          <Th>
            ONRC Customer Outcome
          </Th>
          <Th>
            Result
          </Th>
          <Th>
            Trend
          </Th>
          <Th>
            {!data.isPartYear && <Text>Comparison of all RCAs</Text>}
            {data.isPartYear
            && (
            <HStack spacing={4}>
              <Box>
                <Text borderLeftColor="brand.blue.600" borderLeft="3px solid" color="brand.blue.600" pl={2}>This Year</Text>
              </Box>
              <Box>
                <Text borderLeftColor="brand.blue.200" borderLeft="3px solid" color="brand.blue.200" pl={2}>Last Year</Text>
              </Box>
            </HStack>
            )}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.Results.map((x) => (
          <Tr key={x.Code}>
            <Td>
              <Text>
                {x.Category}
                :
              </Text>
              <Text fontWeight="bold">{x.SubCategory}</Text>
            </Td>
            <Td>
              <Link href={`${config.LEGACY_PMRT_URL}/DQMetricLibrary?code=${x.Code}`} target="_blank" p={0}>
                {x.Code}
              </Link>
            </Td>
            <Td>
              <Popover placement="bottom" matchWidth="true">
                <HStack>
                  <PopoverTrigger>
                    <IconButton
                      variant="ghost"
                      colorScheme="brand.blue"
                      icon={<Icon as={FiInfo} boxSize={6} />}
                    />
                  </PopoverTrigger>
                  <PopoverAnchor>
                    <Text mb={0}>{x.Title}</Text>
                  </PopoverAnchor>
                </HStack>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>{x.Faq}</PopoverBody>
                </PopoverContent>
              </Popover>
            </Td>
            <Td>
              {x.DQType}
            </Td>
            <Td>
              {x.Importance}
            </Td>
            <Td>
              {renderCustomerOutcomes(x.CustomerOutcomes)}
            </Td>
            {data.isPartYear && x.Range < 1
              ? <Td colSpan="3">No results displayed until all data for this metric has been finalised for the financial year</Td>
              : (
                <>
                  <Td>
                    {renderResult(x.ResultF, x.ShowNullAs, x.ResultColor)}
                  </Td>
                  <Td>
                    {renderTrend(x.Trend, x.Range, x.ResultF, x.ShowNullAs)}
                  </Td>
                  <Td>
                    <ResultsChart data={x} isPartYear={data.isPartYear} colors={colors} />
                  </Td>
                </>
              )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
