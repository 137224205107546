import PageLoader from '@src/components/layouts/shared/PageLoader';
import HomePage from '@src/components/pages/HomePage';
import SignedOut from '@src/components/pages/SignedOut';
import TceRoutes from '@src/modules/tce/src/routes/Routes';
import OnfRoutes from '@src/modules/onf/routes/Routes';
import RegionalReportingRoutes from '@src/modules/regional-reporting/routes/Routes';
import PerformanceRoutes from '@src/modules/performance/routes/Routes';
import CalendarRoutes from '@src/modules/calendar/routes/Routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SilentSignIn, useUserInfo } from '@transport-insights/uikit';
import { AuthenticateUser, SignOut } from './auth';
import IdentityAccount from './IdentityAccount';

export default function Routes() {
  const { isLoading } = useUserInfo();

  return (
    <Switch>
      {/* These routes are public  */}
      <Route exact path="/signout" component={SignOut} />
      <Route path={[
        '/login',
        '/signin',
      ]}
      >
        <AuthenticateUser redirectToLoginPage>
          <Redirect to="/home" />
        </AuthenticateUser>
      </Route>
      <Route>
        <SilentSignIn redirectUri={`${window.location.origin}/msal.html`}>
          <PageLoader isLoading={isLoading}>
            <Switch>
              {/* These routes are public, however we still check if a user is logged in
                  as they may have access to additional functionality */}
              <Route path={[
                '/home',
                '/onf',
                '/performance',
                '/activity-management-plans',
                '/signedout',
              ]}
              >
                <Route path="/home" component={HomePage} />
                <Route path="/onf" component={OnfRoutes} />
                <Route path="/performance" component={PerformanceRoutes} />
                <Route path="/signedout" component={SignedOut} />
              </Route>

              {/* These routes are protected, a user must be signed in to access them  */}
              <Route path={[
                '/identity-account',
                '/tce',
                '/regional-reporting',
                '/calendar',
              ]}
              >
                <AuthenticateUser redirectToLoginPage>
                  <Route exact path="/identity-account" component={IdentityAccount} />
                  <Route path="/tce" component={TceRoutes} />
                  <Route path="/regional-reporting" component={RegionalReportingRoutes} />
                  <Route path="/calendar" component={CalendarRoutes} />
                </AuthenticateUser>
              </Route>

              {/* Redirect to home as it is the default page */}
              <Redirect exact from="/" to="/home" />
            </Switch>
          </PageLoader>
        </SilentSignIn>
      </Route>
    </Switch>
  );
}
