import { Box, Flex, Text } from '@chakra-ui/react';

function Header({
  children, title, subtitle, info,
}) {
  return (
    <Flex
      background="gray.50"
      width="100%"
      borderBottom="1px solid"
      borderColor="gray.100"
      p={6}
      position="sticky"
      zIndex="200"
      top="130px"
      justify="space-between"
      borderTopLeftRadius="4"
      borderTopRightRadius="4"
      align="center"
    >
      <Box>
        <Flex align="center">
          <Text fontWeight="bold" fontSize={21} mr={2}>{title}</Text>
          {info}
        </Flex>
        <Text fontWeight="bold" color="gray">{subtitle}</Text>
      </Box>
      {children}
    </Flex>
  );
}
function Body({ children }) {
  return <Box width="100%" padding="6">{children}</Box>;
}

function ReportCard(props) {
  const { children, ...rest } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      background="white"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      borderRadius="4"
      boxShadow="base"
      width="100%"
      border="1px solid"
      borderColor="gray.100"
      {...rest}
    >
      { children }
    </Box>
  );
}

ReportCard.Header = Header;
ReportCard.Body = Body;

export default ReportCard;
