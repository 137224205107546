import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ReportsList from '@src/modules/onf/pages/ReportsList';
import ReportView from '@src/modules/onf/pages/ReportView';
import NetworkCharacteristics from '@src/modules/onf/pages/NetworkCharacteristics';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/transport-outcomes/:reportId`} component={ReportView} />
      <Route path={`${path}/transport-outcomes/`} component={ReportsList} />
      <Route path={`${path}/network-characteristics/`} component={NetworkCharacteristics} />
    </Switch>
  );
}
