import { Line } from 'react-chartjs-2';
import { ChartColours as COLOURS } from './ChartColours';

function AreaLineChart({
  grouping, labels, datasets, colours, unitprefix, unitpostfix, showLegend, adjustYmax,
  // index of the first 'line' which is used to construct the area
  areaIndex = 2,
}) {
  const height = 250;
  const width = 250;

  const data = {
    labels: grouping, // grouping on x axis
    datasets: [],
  };

  // populate the datasets
  // we hide the dots and line for the 3rd and 4th dataset
  datasets.forEach((ds, index) => {
    const config = {
      label: labels[index],
      data: ds,
      borderColor: COLOURS[colours[index]], // line colour
      backgroundColor: COLOURS[colours[index]], // also sets the colour of the legend
      pointRadius: index < areaIndex ? 2 : 0, // hide the dots
      borderWidth: index < areaIndex ? 2 : 0, // thickness of the line
    };
    // this ensures only the area between the 3rd and 4th line is filled
    if (index === areaIndex) {
      config.fill = true;
    }
    if (index === areaIndex + 1) {
      config.fill = 2;
    }
    data.datasets.push(config);
  });

  // adjustYmax controls the decimal precision, and that auto manages the y-axis scale for us
  let fixedDecimals = 1;
  if (adjustYmax !== null) {
    fixedDecimals = 2;
  }

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${unitprefix}${parseFloat(tooltipItem.raw).toFixed(fixedDecimals)}${unitpostfix}`,
        },
        // hide tooltips for 3rd and 4th dataset
        filter: (tooltipItem) => tooltipItem.datasetIndex < areaIndex,
      },
      legend: {
        display: showLegend,
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
          /* this hides the third legend item - which is 'white' or 'top' */
          filter(legendItem) {
            const index = legendItem.datasetIndex;
            return index !== areaIndex;
          },
        },
        // position: 'bottom'
      },
      datalabels: {
        display: false,
      },
    },
    spanGaps: true,
    scales: {
      y: {
        position: 'right', // y-axis on the right
        ticks: {
          precision: adjustYmax !== null ? 1 : 0, // no decimals on y axis
          padding: 20, // spacing between ticks and y-values
          callback: (value) => `${unitprefix}${value}${unitpostfix}` // add units to y axis values
          ,
        },
        grid: {
          drawBorder: false, // hide the y-axis bar
        },
      },
      x: {
        grid: {
          display: false, // hide the vertical grid lines
        },
      },
    },
  };

  return (
    <Line data={data} options={options} height={height} width={width} />
  );
}

export default AreaLineChart;
