export const reportsList = [
  {
    id: 'hsp1',
    group: 'Healthy and Safe People',
    name: 'Safe Travel - Deaths and Serious Injuries',
    toggles: [
      {
        key: 'measureCode',
        options: [
          { label: 'Calendar Year', value: 'onf_crash_cal' },
          { label: 'Financial Year', value: 'onf_crash' },
        ],
        default: ['onf_crash_cal'],
        text: 'Report on:',
        isMulti: false,
      },
      {
        key: 'countType',
        options: [
          { label: 'Deaths and Serious Injury totals', value: 'DSI' },
          { label: 'Deaths only', value: 'Fatal' },
          { label: 'Serious Injuries only', value: 'Serious' },
          { label: 'Crashes involving DSI', value: 'Crash' }],
        default: ['DSI'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    relevantLinks: [
      {
        url: '/performance/dashboard/safety',
        title: 'Performance Dashboard: Safety',
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-treatment-length-management.pdf',
        title: 'Practice overview: Treatment length management',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-traffic-count-data.pdf',
        title: 'Practice overview: Traffic count data',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/safety/partners/crash-analysis-system',
        title: 'Crash Analysis System',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'hsp2',
    group: 'Healthy and Safe People',
    name: 'Safe Travel - Personal/Collective Risk',
    toggles: [
      {
        key: 'measureCode',
        options: [
          { label: 'Calendar Year', value: 'onf_crashrisk_cal' },
          { label: 'Financial Year', value: 'onf_crashrisk' },
        ],
        default: ['onf_crashrisk_cal'],
        text: 'Report on:',
        isMulti: false,
      },
      {
        key: 'report_type',
        options: [
          { label: 'Personal Risk', value: 'Personal' },
          { label: 'Collective Risk', value: 'Collective' },
        ],
        default: ['Personal'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    relevantLinks: [
      {
        url: '/performance/dashboard/safety',
        title: 'Performance Dashboard: Safety',
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-treatment-length-management.pdf',
        title: 'Practice overview: Treatment length management',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-traffic-count-data.pdf',
        title: 'Practice overview: Traffic count data',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/safety/partners/crash-analysis-system',
        title: 'Crash Analysis System',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'hsp3',
    group: 'Healthy and Safe People',
    name: 'Safe Travel - Wet Roads / Night / Intersections',
    toggles: [
      {
        key: 'measureCode',
        options: [
          { label: 'Calendar Year', value: 'onf_crash_cal' },
          { label: 'Financial Year', value: 'onf_crash' },
        ],
        default: ['onf_crash_cal'],
        text: 'Report on:',
        isMulti: false,
      },
      {
        key: 'wni_toggle_value',
        options: [
          { label: 'Loss of control on wet roads', value: 'Wet' },
          { label: 'Loss of driver control at Night', value: 'Night' },
          { label: 'Intersections', value: 'Intersection' }],
        default: ['Wet'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-traffic-count-data.pdf',
        title: 'Practice overview: Traffic count data',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/safety/partners/crash-analysis-system',
        title: 'Crash Analysis System',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'hsp4',
    group: 'Healthy and Safe People',
    name: 'Safe Travel - Vulnerable Users',
    toggles: [
      {
        key: 'measureCode',
        options: [
          { label: 'Calendar Year', value: 'onf_vul_cal' },
          { label: 'Financial Year', value: 'onf_vul' },
        ],
        default: ['onf_vul_cal'],
        text: 'Report on:',
        isMulti: false,
      },
      {
        key: 'veh_type_desc',
        options: [
          { label: 'All', value: 'All' },
          { label: 'Bicycle', value: 'Bicycle' },
          { label: 'Equestrian', value: 'Equestrian' },
          { label: 'Moped', value: 'Moped' },
          { label: 'Motor Cycle', value: 'Motor Cycle' },
          { label: 'Pedestrian Crossing Road', value: 'Pedestrian Crossing Road' },
          { label: 'Wheeled Pedestrian', value: 'Wheeled Pedestrian' },
          { label: 'Other Pedestrian', value: 'Other Pedestrian' }],
        default: ['All'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-traffic-count-data.pdf',
        title: 'Practice overview: Traffic count data',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/safety/partners/crash-analysis-system',
        title: 'Crash Analysis System',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'hsp5',
    group: 'Healthy and Safe People',
    name: 'Safe Travel - Infrastructure Risk Rating',
    toggles: [
      {
        key: 'irrBand',
        options: [
          { label: 'High', value: 'High' },
          { label: 'Medium-High', value: 'Medium High' },
          { label: 'Medium', value: 'Medium' },
          { label: 'Low-Medium', value: 'Low Medium' },
          { label: 'Low', value: 'Low' },
        ],
        default: ['High', 'Medium', 'Low Medium', 'Low', 'Medium High'],
        text: 'Filter by IRR Band:',
        isMulti: true,
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/resources/infrastructure-risk-rating-manual-road-to-zero-edition/infrastructure-risk-rating-manual-road-to-zero-edition-2022.pdf',
        title: 'Infrastructure Risk Rating Manual - Road to Zero Edition 2022',
        isExternal: true,
      },
      {
        url: 'https://nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-crash-data.pdf',
        title: 'Practice Overview - Crash data in RAMM',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/safety/partners/crash-analysis-system',
        title: 'Crash Analysis System',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'ia1',
    group: 'Inclusive Access',
    name: 'Smooth Travel Exposure',
    relevantLinks: [
      {
        url: '/performance/dashboard/achievements',
        title: 'Performance Dashboard: Achievements',
      },
    ],
    toggles: [
      {
        key: 'report_type',
        options: [
          { label: 'Vehicles per Day (VPD) bands', value: 'ONFVPD' },
          { label: 'ONF Movement Classes (DRAFT only - not for official use)', value: 'ONFMP' },
        ],
        default: ['ONFVPD'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-smooth-travel-exposure.pdf',
        title: 'Smooth Travel Exposure Practice Overview',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'ia2',
    group: 'Inclusive Access',
    name: 'Peak Roughness',
    relevantLinks: [
      {
        url: '/performance/dashboard/achievements',
        title: 'Performance Dashboard: Achievements',
      },
    ],
    toggles: [
      {
        key: 'report_type',
        options: [
          { label: '75th Percentile', value: '75' },
          { label: '85th Percentile', value: '85' },
          { label: '95th Percentile', value: '95' },
        ],
        default: ['75'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    importantLinks: [
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-surfacing-data.pdf',
        title: 'Practice overview: Surfacing data',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/assets/Road-Efficiency-Group-2/docs/ONRC-Practice-Note-Network-Inspections-FINAL.pdf',
        title: 'Practice overview: Network Inspections',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    enabled: true,
  },
  {
    id: 'ep1',
    group: 'Economic Prosperity',
    name: 'Unplanned Road Closures',
    toggles: [
      {
        key: 'detourDescription',
        options: [
          { label: 'Unplanned road closures with no detour', value: 'Unplanned road closures with no detour' },
          { label: 'Unplanned closures with a detour', value: 'Unplanned closures with a detour provided' },
        ],
        default: ['Unplanned road closures with no detour'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    relevantLinks: [
      {
        url: '/performance/dashboard/network-availability',
        title: 'Performance Dashboard: Network Availability',
      },
    ],
    enabled: true,
  },
  {
    id: 'ep2',
    group: 'Economic Prosperity',
    name: 'Heavy Vehicle Accessibility',
    toggles: [
      {
        key: 'causeDescription',
        options: [
          { label: 'Class 1 Heavy', value: 'Class 1 HCV' },
          { label: 'HPMV', value: 'HPMV' },
          { label: '50MAX', value: '50MAX' },
        ],
        default: ['Class 1 HCV'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    enabled: true,
    relevantLinks: [
      {
        url: '/performance/dashboard/network-availability',
        title: 'Performance Dashboard: Network Availability',
      },
    ],
  },
  {
    id: 'ep3',
    group: 'Economic Prosperity',
    name: 'Maintenance Costs',
    toggles: [
      {
        key: 'costGroup',
        options: [
          { label: 'Bridge Maint', value: 'BR' },
          { label: 'Drainage', value: 'DR' },
          { label: 'Pavement', value: 'PA' },
          { label: 'Shoulder', value: 'SH' },
          { label: 'Surfacing', value: 'SU' },
        ],
        default: ['BR'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    enabled: true,
    relevantLinks: [
      {
        url: '/performance/dashboard/delivery',
        title: 'Performance Dashboard: Delivery',
      },
    ],
  },
  {
    id: 'ep4',
    group: 'Economic Prosperity',
    name: 'Sealed Road Pavement Rehabilitation & Resurfacing',
    toggles: [
      {
        key: 'report_type',
        options: [
          { label: 'Asphalt Resurfacing', value: 'Asphalt Resurfacing' },
          { label: 'Chipseal Resurfacing', value: 'Chipseal Resurfacing' },
          { label: 'Pavement Rehabilitation', value: 'Pavement Rehabilitation' },
        ],
        default: ['Asphalt Resurfacing'],
        text: 'Report:',
        isMulti: false,
      },
      {
        key: 'subtype',
        options: [
          { label: 'Lane km', value: 'Length' },
          { label: 'Area', value: 'Area' },
          { label: 'Average Life', value: 'AvgLife' },
          { label: 'Cost', value: 'Cost' },
        ],
        default: ['Length'],
        text: 'Choose:',
        isMulti: false,
      },
    ],
    importantLinks: [
      {
        url: 'https://nzta.govt.nz/assets/Road-Efficiency-Group/docs/practice-overviews/REG-practice-overview-work-origin.pdf',
        title: 'Practice Overview - Work Origin and Original Cost',
        isExternal: true,
      },
      {
        url: 'https://www.nzta.govt.nz/planning-and-investment/planning/one-network-framework',
        title: 'One Network Framework',
        isExternal: true,
      },
    ],
    relevantLinks: [
      {
        url: '/performance/dashboard/delivery',
        title: 'Performance Dashboard: Delivery',
      },
      {
        url: '/performance/dashboard/achievements',
        title: 'Performance Dashboard: Achievements',
      },
    ],
    enabled: true,
  },
];
