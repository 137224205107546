import {
  Box, HStack, Radio, RadioGroup, Spacer, Text, VStack,
} from '@chakra-ui/react';
import { toString } from 'lodash';
import { getChartData } from '../../context/levels-of-service-api-hooks';

function ConditionSelector({ summarize, setSummarize }) {
  const categories = getChartData({}, summarize);

  return (
    <HStack
      width="100%"
      align="start"
      border="1px solid"
      bgColor="white"
      borderColor="gray.100"
      borderRadius="md"
      boxShadow="sm"
      p={3}
    >
      <VStack align="start">
        <Text fontWeight="bold" as="span" fontSize="xs">
          Selected conditions for comparison:
        </Text>
        <RadioGroup
          onChange={(value) => {
            setSummarize(value === 'true');
          }}
          value={toString(summarize)}
        >
          <HStack>
            <Radio size="sm" value="true">Good vs Poor</Radio>
            <Radio size="sm" value="false">All 5 Conditions</Radio>
          </HStack>
        </RadioGroup>
      </VStack>
      <Spacer />
      <VStack align="flex-start">
        <Text fontWeight="bold" as="span" fontSize="xs">
          Conditions:
        </Text>
        <HStack spacing={2}>
          {categories.map((c) => (
            <HStack spacing={1} key={c.label}>
              <Box w="10px" h="10px" rounded="full" bg={c.color} />
              <Text fontSize="sm">{c.label}</Text>
            </HStack>
          ))}
        </HStack>
      </VStack>
    </HStack>
  );
}

export default ConditionSelector;
