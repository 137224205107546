import { useState, useEffect } from 'react';
import { useServicePerformanceMapKPI } from '@src/modules/performance/context/map-api-hooks';
import TrafficLightWidget from './TrafficLightWidget';

import { mapServicePerformanceToText } from '../shared/IndicatorText';

const TITLE = 'Service Performance';

const colorMap = {
  pass: 'GREEN',
  partial: 'ORANGE',
  fail: 'RED',
  na: 'GREY',
  'n/a': 'GREY',
};

function ServicePerformanceIndicator({ selection, setSelection, filter }) {
  const { data } = useServicePerformanceMapKPI();
  const [colour, setColour] = useState('GREY');
  const [text, setText] = useState('');
  const [assessment, setAssessment] = useState('');
  const [lockYear, setLockYear] = useState(null);

  useEffect(() => {
    if (data) {
      const selectedType = data.find((x) => x.Type_name === filter);
      setColour(colorMap[selectedType?.Result?.toLowerCase()] || 'na');
      setAssessment(mapServicePerformanceToText(selectedType?.Result?.toLowerCase() || 'noData'));
      setText(selectedType?.Type_name);
      setLockYear(selectedType?.LockYear);
    }
  }, [data, filter]);

  return (
    <TrafficLightWidget
      title={TITLE}
      colour={colour}
      lockYear={lockYear}
      subtext1={assessment}
      subtext2={text}
      selected={selection === 'servicePerformance'}
      handleClick={() => {
        setSelection('servicePerformance');
      }}
    />
  );
}

export default ServicePerformanceIndicator;
