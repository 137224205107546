const getContrastColor = (hex) => (parseInt(hex.replace('#', ''), 16) > 0xffffff / 1.05 ? 'gray.800' : 'white');

const COMPARISON_COLORS = ['#dcd8d9', '#a6a6a6', '#737373', '#404040', '#150a0d', '#d9d9d9', '#a6a6a6', '##595959', '#262626'];

const RAG_COLORS = {
  red: '#C01F24',
  yellow: '#F7C622',
  green: '#5FAB12',
  gray: '#999',
  darkGray: '#666',
  orange: '#F47B20',
  lightGreen: '#8FC459',
  yellowGreen: '#c3ca08',
};

const getTrendlineColor = (t1, t2, reversed, isNeutral = false) => {
  const {
    red, green, gray, darkGray,
  } = RAG_COLORS;
  if (t1 === t2) {
    return gray;
  } if (isNeutral) {
    return darkGray;
  } if (t1 < t2) {
    return reversed ? green : red;
  }
  return reversed ? red : green;
};

export {
  getContrastColor, COMPARISON_COLORS, getTrendlineColor, RAG_COLORS,
};
