import { isNaN, toNumber, isFinite } from 'lodash';

const numberFormatter = (params) => {
  if (!isFinite(params?.value)) {
    return null;
  }
  if (Number.isInteger(params?.value)) {
    return params?.value;
  }
  return params?.value?.toFixed(3);
};

/**
 * Function to generate an editable coldef for the given name
 * @param {string} name field name
 * @param {string} editableFlagName name of flag in data record indicating if cell is editable
 * @param {string} headerName
 * @param {array} range Value range check: [10, 50] means value between 10 and 50 inclusive
 * @returns {{cellClassRules: {"tce-editable": (function(*): *)}, autoHeight: boolean, cellEditorPopup: boolean, field: string, cellRenderer: ((function(*): *)|*), editable: (function(*): *), cellEditor: React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>, width: number, wrapText: boolean, cellEditorPopupPosition: string}}
 */
const agEditableColdef = (name, editableFlagName, headerName = null, range = null) => {
  const isEditable = (params) => (params.data && params.data[editableFlagName]) || false;
  const colDef = {
    autoHeight: false,
    cellClassRules: {
      'tce-editable': (params) => params?.data?.[editableFlagName] ?? false,
    },
    cellEditor: 'agTextCellEditor',
    editable: isEditable,
    field: name,
    cellEditorParams: {
      parseValue: (newValue) => {
        const parsed = toNumber(newValue);
        if (isNaN(parsed)) {
          return 0;
        }
        return parsed;
      },
      useFormatter: true,
    },
    valueFormatter: numberFormatter,
    wrapText: false,
    width: 90,
  };

  if (headerName) colDef.headerName = headerName;

  if (range !== null) {
    colDef.cellEditorParams.range = range;
  }
  return colDef;
};

/**
 * Event handler called when a cell gets focus. It starts the edit if the cell is editable.
 * Particularly handy when the user uses the arrow key to navigate from cell.
 * @param params
 */
const agOnCellFocused = (params) => {
  if (params.column === null) return;

  const colKey = params.column.colId;
  const { rowIndex } = params;

  const row = params.api.getDisplayedRowAtIndex(rowIndex);
  let isEditable = params.column.colDef.editable ?? false;
  if (isEditable instanceof Function) { isEditable = isEditable(row); }
  if (isEditable) {
    params.api.startEditingCell({
      rowIndex,
      colKey,
    });
  }
};

/**
 * Get the max height of the header cells
 * @returns {number}
 */
const agHeaderHeightGetter = () => {
  const columnHeaderTexts = [
    ...document.querySelectorAll('.ag-header-cell-text'),
  ];
  const clientHeights = columnHeaderTexts.map(
    (headerText) => headerText.clientHeight,
  );
  return Math.max(...clientHeights);
};

/**
 * Set the height of the header
 * @param event
 */
const agHeaderHeightSetter = (event) => {
  const padding = 5;
  const height = agHeaderHeightGetter() + padding;
  event.api.setHeaderHeight(height);
  event.api.resetRowHeights();
};

/**
 * Page size change handler
 * @type {(function(*): void)|*}
 */
const agOnPageSizeChanged = (gridRef, event) => {
  const { value } = event.target;
  gridRef.current.api.paginationSetPageSize(Number(value));
};

const dateFormatter = (date) => {
  if (!date) return null;
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat('en-NZ', options).format(new Date(date));
};

const agTrafficMixColdef = (name, headerName) => ({
  autoHeight: false,
  editable: false,
  field: name,
  headerName,
  valueFormatter: numberFormatter,
  wrapText: false,
  width: 90,
});

export {
  agEditableColdef,
  agOnCellFocused,
  agHeaderHeightGetter,
  agHeaderHeightSetter,
  agOnPageSizeChanged,
  agTrafficMixColdef,
  dateFormatter,
  numberFormatter,
};
