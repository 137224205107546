import { useState, useEffect } from 'react';
import {
  Container,
  Heading,
  Box,
  Text,
  Flex,
  Grid,
  LinkBox,
  LinkOverlay,
  Stack,
  Button,
  useTheme,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { reportsList } from '@src/modules/onf/api/reports-list';
import MainLayout from '@src/components/layouts/main';

export default function ReportsList() {
  const theme = useTheme();
  const enabledReportsList = reportsList.filter((x) => x.enabled);
  const groups = [...new Set(enabledReportsList.map((x) => x.group))];
  const [filter, setFilter] = useState('all');
  const [filteredList, setFilteredList] = useState([]);

  const handleFilter = (val) => {
    setFilter(val);
  };

  useEffect(() => {
    if (filter !== 'all') {
      setFilteredList(enabledReportsList.filter((x) => x.group === filter));
    } else {
      setFilteredList(enabledReportsList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Heading as="h1">ONF Transport Outcomes</Heading>
        <Stack direction="row" spacing={4} align="center" my={6}>
          <Button
            variant={filter === 'all' ? 'solid' : 'outline'}
            _hover={{ background: 'gray.800', color: 'white' }}
            _focus={{ background: 'gray.800', color: 'white' }}
            _active={{ background: 'gray.800', color: 'white' }}
            background={filter === 'all' ? 'gray.800' : ''}
            color={filter === 'all' ? 'white' : 'gray.800'}
            onClick={() => handleFilter('all')}
            borderRadius="full"
          >
            All
          </Button>
          {groups.map((group) => (
            <Button
              key={group}
              variant={filter === group ? 'solid' : 'outline'}
              _hover={{ background: theme.colors.transportIndicator[group], color: 'white' }}
              _focus={{ background: theme.colors.transportIndicator[group], color: 'white' }}
              _active={{ background: theme.colors.transportIndicator[group], color: 'white' }}
              background={filter === group ? theme.colors.transportIndicator[group] : ''}
              color={filter === group ? 'white' : theme.colors.transportIndicator[group]}
              borderRadius="full"
              onClick={() => handleFilter(group)}
            >
              {group}
            </Button>
          ))}
        </Stack>
        <Grid templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={4} mt={4}>
          {filteredList.map((report) => (
            <LinkBox
              as="article"
              backgroundColor="white"
              p={4}
              borderRadius="md"
              boxShadow="sm"
              key={report.id}
              border="2px solid"
              borderColor="transparent"
              transition="all 200ms"
              _hover={{ borderColor: theme.colors.transportIndicator[report.group] }}
            >
              <Flex align="center" mb={4}>
                <Box backgroundColor={theme.colors.transportIndicator[report.group]} width={5} height={5} mr={2} borderRadius="full" />
                <Text color={theme.colors.transportIndicator[report.group]} fontWeight="bold" fontSize="sm">{report.group}</Text>
              </Flex>
              <Heading size="md" my="2">
                <LinkOverlay
                  as={RouterLink}
                  to={`/onf/transport-outcomes/${report.id}`}
                  color="gray.800"
                  fontWeight="normal"
                  textDecoration="none"
                >
                  {report.name}
                </LinkOverlay>
              </Heading>
            </LinkBox>
          ))}
        </Grid>
      </Container>
    </MainLayout>
  );
}
