import { Checkbox, Flex } from '@chakra-ui/react';

function CheckAllHeaderComponent({ selectAll, onChange }) {
  return (
    <Flex width={50} pl="15px">
      <Checkbox
        title="Select All"
        colorScheme="blue"
        isChecked={selectAll}
        onChange={onChange}
      />
    </Flex>
  );
}

export default CheckAllHeaderComponent;
