import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
  FormHelperText,
  HStack,
  Text,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useConfig } from '@src/context/config';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useState } from 'react';
import { TTM_SITE_STATUSES } from '@src/modules/performance/shared/constants';
import { useJsApiLoader } from '@react-google-maps/api';
import { useCreateTtmSiteInspection } from '../../../context/temporary-traffic-management-api-hooks';
import LocationMapSelector from './LocationMapSelector';

const schema = yup.object().shape({
  description: yup.string(),
  address: yup.string().required('Site location is required'),
});

function TtmSiteInspectionForm({
  isOpen, onClose,
}) {
  const config = useConfig();
  const { isLoaded: googleApiIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GOOGLE_API_KEY,
  });
  const [googlePlace, setGooglePlace] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: '',
      address: '',
      siteStatus: '1',
    },
    resolver: yupResolver(schema),
  });
  const { mutateAsync: doCreateTtmSiteInspection, isLoading: isCreating } = useCreateTtmSiteInspection();
  const [gpsCoords, setGpsCoords] = useState(null);

  const modalTitle = 'Add Site Inspection';
  const submitButtonLabel = 'Save Inspection';

  const handleLocationChange = async (coords) => {
    setGpsCoords(coords);
    await geocodeByLatLng(coords).then((results) => {
      if (results.length > 0) {
        const address = results[0].formatted_address;
        setValue('address', address);
        setGooglePlace({ placeId: results[0].place_id, address });
      }
    });
  };

  const handleClose = () => {
    reset({
      description: '',
      address: '',
      siteStatus: '1',
    });
    setGooglePlace(null);
    setGpsCoords(null);
    onClose();
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      location: `${gpsCoords.lat}, ${gpsCoords.lng}`,
      googlePlaceId: googlePlace.placeId,
    };
    await doCreateTtmSiteInspection(formData);
    reset({
      description: '',
      address: '',
      siteStatus: '1',
    });
    setGooglePlace(null);
    setGpsCoords(null);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          { modalTitle }
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {googleApiIsLoaded && (
              <FormControl mb={4} isInvalid={!!errors.address}>
                <FormLabel>Site Location</FormLabel>
                <FormHelperText mb={4}>Drag the map marker to select the site location </FormHelperText>
                <LocationMapSelector onLocationChange={handleLocationChange} />
                <FormErrorMessage>{errors.address && errors.address.message}</FormErrorMessage>
              </FormControl>
            )}
            {gpsCoords && (
            <Stack mb={4}>
              <HStack>
                <Text fontSize="xs">
                  Latitude:
                  {gpsCoords.lat}
                </Text>
                <Text fontSize="xs">
                  Longitude:
                  {gpsCoords.lng}
                </Text>
              </HStack>
              <Text fontSize="xs">
                Location:
                {googlePlace?.address}
              </Text>
            </Stack>
            )}
            <FormControl mb={4} isInvalid={!!errors.description}>
              <FormLabel>Description</FormLabel>
              <Textarea {...register('description')} rows="3" placeholder="Optional..." />
              <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.siteStatus}>
              <FormLabel>Site Status</FormLabel>
              <Controller
                name="siteStatus"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value} onChange={field.onChange}>
                    <Stack direction="column">
                      {TTM_SITE_STATUSES.map((status) => (
                        <Radio key={status.id} value={status.id.toString()}>
                          {status.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>{errors.siteStatus && errors.siteStatus.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="orange" type="submit" isLoading={isCreating}>
              {submitButtonLabel}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default TtmSiteInspectionForm;
