import { ChakraProvider } from '@chakra-ui/react';
import theme from '@src/assets/theme';

// Customize the default Chakra theme here
// See: https://chakra-ui.com/docs/getting-started#add-custom-theme-optional

export default function ChakraTheming({ children }) {
  return (
    <ChakraProvider theme={theme}>
      { children }
    </ChakraProvider>
  );
}
