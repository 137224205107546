import {
  Flex,
  Heading,
  Box,
  Icon,
} from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

export default function EmptyState({ title = 'No Data', message = 'Data not available' }) {
  return (
    <Flex align="center" justify="center" width="100%" py={8} height="100%" flexGrow={1}>
      <Box textAlign="center">
        <Icon as={FiAlertCircle} boxSize="16" color="gray.400" mb={4} />
        <Heading color="gray.600" fontSize="3xl" mb={2}>{title}</Heading>
        <Box color="gray.400">{message}</Box>
      </Box>
    </Flex>
  );
}
