import { Bar } from 'react-chartjs-2';
import { ChartColours as COLOURS } from './ChartColours';

function BasicBarChart({
  grouping, labels, datasets, colours, unitprefix, unitpostfix, precision, height, width, hideLegend,
}) {
  const cheight = height || 250;
  const cwidth = width || 250;

  const data = {
    // grouping on x-axis
    labels: grouping.map((label) => {
      // auto split the grouping labels by whitespace,
      // so we get multiline labels and the chart looks nicer
      if (/\s/.test(label)) {
        return label.split(' ');
      }
      return label;
    }),
    datasets: [],
  };

  const maxValues = [];

  // populate the datasets
  datasets.forEach((ds, index) => {
    data.datasets.push({
      label: labels[index],
      data: ds,
      backgroundColor: COLOURS[colours[index]],
      categoryPercentage: 0.9, // this controls the width of the categories - not full width so gap
      barPercentage: 1.0, // this controls the width of the bars within a category - full width, so no gap
    });
    maxValues.push(Math.max(...ds));
  });

  let fixedPrecision = 1;
  if (precision !== null) {
    fixedPrecision = precision;
  }

  if (Math.max(...maxValues) <= 1) {
    fixedPrecision += 1;
  }
  if (Math.max(...maxValues) <= 0.1) {
    fixedPrecision += 1;
  }

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${unitprefix}${parseFloat(tooltipItem.raw).toFixed(fixedPrecision)}${unitpostfix}`,
          title: (tooltipItem) => {
            let l = tooltipItem[0].label;
            if (l.indexOf(',') > -1) {
              l = l.replace(',', ' ');
            }
            return l;
          },
        },
        // hide tooltips if there is not data
        filter: (tooltipItem) => !!tooltipItem.raw,
      },
      legend: {
        display: !hideLegend,
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
        },
      },
      datalabels: {
        display: false,
      },
    },
    scale: {

    },
    scales: {
      y: {
        position: 'right', // y-axis on the right
        ticks: {
          precision: fixedPrecision, // no decimals on y axis
          padding: 20, // spacing between ticks and y-values
          callback: (value) => `${unitprefix}${value}${unitpostfix}` // add units to y axis values
          ,
        },
        grid: {
          drawBorder: false, // hide the y-axis bar
        },
      },
      x: {
        grid: {
          display: false, // hide the vertical grid lines
        },
      },
    },
  };

  return (
    <Bar data={data} options={options} height={cheight} width={cwidth} />
  );
}

export default BasicBarChart;
