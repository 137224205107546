import {
  Route, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import ActivityManagement from '@src/modules/performance/views/reports/activity-management/ActivityManagement';
import ServicePerformance from '@src/modules/performance/views/reports/ServicePerformance';
import CoInvestorAssurance from '@src/modules/performance/views/reports/CoInvestorAssurance';
import Delivery from '@src/modules/performance/views/reports/Delivery';
import DeliveryPerformance from '@src/modules/performance/views/reports/DeliveryPerformance';
import AmenityCondition from '@src/modules/performance/views/reports/AmenityCondition';
import Safety from '@src/modules/performance/views/reports/Safety';
import NetworkAvailability from '@src/modules/performance/views/reports/NetworkAvailability';
import TerritorialActivity from '@src/modules/performance/views/reports/TerritorialActivity';
import RoadNetworkUse from '@src/modules/performance/views/reports/RoadNetworkUse';
import PublicTransport from '@src/modules/performance/views/reports/PublicTransport';
import { AuthenticateUser } from '@src/routes/auth';
import Map from '../views/map';
import LevelsOfService from '../views/levels-of-service';
import AnnualReports from '../views/annualreports';
import NetworkPhysicalCharacteristics from '../views/reports/NetworkPhysicalCharacteristics';
import NetworkCharacteristics from '../views/reports/NetworkCharacteristics';
import ActivityManagementPlans from '../views/activity-management-plans';
import CrashMapPoc from '../views/crash-map-poc';
import PlanningQualityPillars from '../views/reports/activity-management/PlanningQualityPillars';
import MaintenanceOperations from '../views/reports/MaintenanceOperations';
import TemporaryTrafficManagement from '../views/temporary-traffic-management';
import TemporaryTrafficManagementReport from '../views/reports/TemporaryTrafficManagement';
import Dashboard from '../views/dashboard';
import StateHighwaysPotholeRepairs from '../views/reports/StateHighwaysPotholeRepairs';
import E3 from '../views/reports/E3';

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* Redirect reports to new url */}
      <Route
        path={`${path}/dashboard/:report`}
        render={({ match }) => (
          <Redirect to={`${path}/reports/${match.params.report}`} />
        )}
      />
      <Route path={`${path}/dashboard`} component={Dashboard} />
      <Route path={`${path}/annual-reports`} component={AnnualReports} />
      <Route path={`${path}/reports/delivery-performance`} component={DeliveryPerformance} />
      <Route path={`${path}/reports/amenity-and-condition`} component={AmenityCondition} />
      <Route path={`${path}/reports/activity-management/planning-quality-pillars`} component={PlanningQualityPillars} exact />
      <Route path={`${path}/reports/activity-management`} component={ActivityManagement} />
      <Route path={`${path}/reports/coinvestor-assurance`} component={CoInvestorAssurance} />
      <Route path={`${path}/reports/delivery`} component={Delivery} />
      <Route path={`${path}/reports/network-availability`} component={NetworkAvailability} />
      <Route path={`${path}/reports/network-physical-characteristics`} component={NetworkPhysicalCharacteristics} />
      <Route path={`${path}/reports/public-transport`} component={PublicTransport} />
      <Route path={`${path}/reports/service-performance`} component={ServicePerformance} />
      <Route path={`${path}/reports/road-network-use`} component={RoadNetworkUse} />
      <Route path={`${path}/reports/territorial-activity`} component={TerritorialActivity} />
      <Route path={`${path}/reports/safety`} component={Safety} />
      <Route path={`${path}/reports/delivery`} component={Delivery} />
      <Route path={`${path}/reports/state-highways-pothole-repairs`} component={StateHighwaysPotholeRepairs} />
      <Route path={`${path}/reports/value-for-money`} component={E3} />
      <Route path={`${path}/reports/temporary-traffic-management`} component={TemporaryTrafficManagementReport} />
      <Route path={`${path}/asset-condition`} component={LevelsOfService} />
      <Route path={`${path}/maintenance-operations`} component={MaintenanceOperations} exact />
      <Route path={`${path}/map`} component={Map} />
      <Route path={`${path}/networkchar`} component={NetworkCharacteristics} />
      <Route path={`${path}/network-characteristics`} component={NetworkCharacteristics} />
      <Route path={`${path}/reports/network-physical-characteristics`} component={NetworkPhysicalCharacteristics} />
      <Route path={`${path}/activity-management-plans`} component={ActivityManagementPlans} />
      <Route path={`${path}/crash-map`} component={CrashMapPoc} />
      <AuthenticateUser redirectToLoginPage>
        <Route path={`${path}/temporary-traffic-management`} component={TemporaryTrafficManagement} />
      </AuthenticateUser>
      <Route path={`${path}/`} component={Map} />
    </Switch>
  );
}
