import { useQuery } from 'react-query';
import useOnfApi from '../api/useOnfApi';

export function useGlossary() {
  const api = useOnfApi();

  return useQuery(
    ['glossary-data'],
    async () => api.getGlossaryData(),
    { },
  );
}
