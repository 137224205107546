import {
  Box,
  Button, Container, Flex, Heading, Spinner, Stack, Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import {
  councilSelectorEnabledState,
  lockYearsSelectorEnabledState,
  selectedDatabaseIdState,
} from '@transport-insights/uikit';
import TceWizard from '@tce/components/pages/traffic-count-estimates/components/TceWizard';
import { useJob } from '@tce/context/jobs';
import {
  selectedJobIdState, tceWizardFilterSelector,
} from '@tce/state/tce';
import '@tce/styles/styles.scss';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import MoreDrawer from '../../shared/MoreDrawer';

export default function TrafficCountEstimates() {
  const { jobId: specifiedJobId } = useParams();
  const setEnableCouncilSelector = useSetRecoilState(councilSelectorEnabledState);
  const setEnableLockyearSelector = useSetRecoilState(lockYearsSelectorEnabledState);
  const setSelectedDatabaseId = useSetRecoilState(selectedDatabaseIdState);
  const [selectedDatabaseId] = useRecoilState(selectedDatabaseIdState);
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdState);
  const { job, isLoading } = useJob();
  const history = useHistory();

  useEffect(() => {
    if (job && job.rcaId !== selectedDatabaseId) setSelectedDatabaseId(job.rcaId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, selectedDatabaseId]);

  useEffect(() => {
    // lock database selector
    setEnableCouncilSelector(false);
    setSelectedJobId(specifiedJobId);
  }, [setEnableCouncilSelector, setSelectedJobId, specifiedJobId]);
  const resetFilter1 = useResetRecoilState(tceWizardFilterSelector('step1'));
  const resetFilter2 = useResetRecoilState(tceWizardFilterSelector('step2'));
  const resetFilter3 = useResetRecoilState(tceWizardFilterSelector('step3'));
  const resetFilter4 = useResetRecoilState(tceWizardFilterSelector('step4'));

  // reset the page when loading for the first time
  useEffect(() => {
    resetFilter1();
    resetFilter2();
    resetFilter3();
    resetFilter4();
  }, [resetFilter1, resetFilter2, resetFilter3, resetFilter4, selectedJobId]);

  useEffect(() => () => {
    setEnableLockyearSelector(true);
    setEnableCouncilSelector(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let pageBody;
  if (isLoading) {
    pageBody = (
      <Box>
        <Spinner />
      </Box>
    );
  } else if (!job) {
    pageBody = (
      <Box>
        <Heading as="h1" mb="3">Traffic Count Estimates</Heading>
        <Text mb="3">No job has been selected</Text>
        <Button onClick={() => history.push('/tce/estimates')}>Select Job</Button>
      </Box>
    );
  } else {
    pageBody = (
      <Flex direction="column" flex="1">
        <Flex align="center" justify="space-between" mb={4}>
          <Heading as="h1">
            Traffic Count Estimation for
            {' '}
            {job.lockYear}
            {' '}
            #
            {job.importId}
          </Heading>
          <MoreDrawer />
        </Flex>
        <TceWizard />
      </Flex>
    );
  }

  return (
    <MainLayout>
      <Container maxW="full">
        <Stack spacing={8}>
          {pageBody}
        </Stack>
      </Container>
    </MainLayout>
  );
}
