import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ApiProvider, QueryStringParser } from '@transport-insights/uikit';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.scss';
import ChakraTheming from './configure/ChakraTheming';
import ConfigBehaviour, { authConfig } from './configure/ConfigBehaviour';
import ErrorBoundary from './configure/ErrorBoundary';
import GoogleAnalytics from './configure/GoogleAnalytics';
import NewsModal from './configure/NewsModal';
import QueryClientBehaviour from './configure/QueryClientBehaviour';
import Routes from './routes/Routes';

export default function App() {
  const pca = new PublicClientApplication(authConfig);

  return (
    <RecoilRoot>
      <ConfigBehaviour>
        <QueryClientBehaviour>
          <ChakraTheming>
            <BrowserRouter>
              <MsalProvider instance={pca}>
                <ErrorBoundary>
                  <QueryStringParser>
                    <GoogleAnalytics>
                      <ApiProvider>
                        <NewsModal>
                          <Routes />
                        </NewsModal>
                      </ApiProvider>
                    </GoogleAnalytics>
                  </QueryStringParser>
                </ErrorBoundary>
              </MsalProvider>
            </BrowserRouter>
          </ChakraTheming>
        </QueryClientBehaviour>
      </ConfigBehaviour>
    </RecoilRoot>
  );
}
