import {
  Box,
  Container,
  Flex, Text,
  Wrap,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MainLayout from '@src/components/layouts/main';
import ReportCard from '@src/modules/performance/components/ReportCard';
import {
  useAllTransportActivities,
  useCoInvestExpenditure, useDeliveryKPI,
  useInvestManagement, useMaintenanceOperations,
  useNewRoadsRoadImprovements,
  useRoadingAssets,
  useRoadMaintenance,
  useRoadSafetyPromotion,
  useTotalExpenditure,
  useWalkingCycling,
} from '@src/modules/performance/context/reports-api-hooks';
import Pagination from '../../components/Pagination';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import SLAreaLineChart from './components/selfloading/SLAreaLineChart';
import SLLineChart from './components/selfloading/SLLineChart';
import SLStackedLineChart from './components/selfloading/SLStackedLineChart';
import html from './help/Delivery.html';
import { CircleIndicator } from '../../components/Indicators';
import { roundKpiValue } from '../../shared/helpers';

const subheader = 'Expenditure, Funding, Cost Efficiency and Valuation';

const stackedReportChartColours = [
  'transblue', 'transgrey', 'transred', 'transgreen', 'transorange',
];
const stackedReportChartColours2 = [
  'transblue', 'transgrey',
];

const areaLineReportChartColours = [
  'blue', 'black', 'white', 'lightgrey',
];

const lineChartColours = [
  'blue', 'black',
];

function Delivery() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: deliveryData, isLoadingKpi } = useDeliveryKPI();
  const allTransportActivities = useAllTransportActivities();
  const newRoadsRoadImprovements = useNewRoadsRoadImprovements();
  const roadMaintenance = useRoadMaintenance();
  const coInvestExpenditure = useCoInvestExpenditure();
  const roadSafetyPromotion = useRoadSafetyPromotion();
  const walkingCycling = useWalkingCycling();
  const investManagement = useInvestManagement();
  const roadingAssets = useRoadingAssets();
  const totalExpenditure = useTotalExpenditure();
  const maintenanceOperations = useMaintenanceOperations();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header
            title="Transport Programme Delivery"
            subtitle={subheader}
            info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
          >
            <Flex align="center">
              <Flex align="center">
                <Box mr={2}>
                  <CircleIndicator
                    size={60}
                    colour={deliveryData?.currentPercentage !== undefined ? 'gray.700' : null}
                    value={deliveryData && deliveryData?.currentPercentage !== null ? `${roundKpiValue(deliveryData?.currentPercentage, 5, 1)}%` : null}
                    isLoading={isLoadingKpi && !deliveryData}
                  />
                </Box>
                <Text
                  fontWeight="bold"
                  fontSize="sm"
                  color="gray"
                  lineHeight={1.2}
                >
                  Current
                  {' '}
                  {deliveryData?.budgetPeriod}
                  {' '}
                  LTP
                  <br />
                  Approved Funding Spent
                  <br />
                  as at
                  {' '}
                  {deliveryData?.lockYear}
                </Text>
              </Flex>
              <Flex align="center" ml={6}>
                <Box mr={2}>
                  <Text
                    fontWeight="normal"
                    color="gray.700"
                    fontSize="2xl"
                  >
                    {roundKpiValue(deliveryData?.originalPercentage, 5, 1)}
                    %
                  </Text>
                </Box>
                <Text fontWeight="bold" color="gray" lineHeight={1.2} fontSize="xs">
                  Original
                  {' '}
                  {deliveryData?.budgetPeriod}
                  {' '}
                  LTP
                  <br />
                  Approved Funding Spent
                  <br />
                  as at
                  {' '}
                  {deliveryData?.lockYear}
                </Text>
              </Flex>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Co-Invested Expenditure</Text>
                <Flex alignContent="start">
                  {/* three charts */}
                  <SLStackedLineChart
                    queryInfo={allTransportActivities}
                    title1="All Transport Activities"
                    title2="&nbsp;"
                    labels={[
                      'Road maintenance',
                      'Walking and cycling',
                      'Road improvement',
                      'Other',
                    ]}
                    colours={stackedReportChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                  <SLStackedLineChart
                    queryInfo={newRoadsRoadImprovements}
                    title1="New roads and road improvements"
                    title2="&nbsp;"
                    labels={[
                      'Minor Improvements',
                      'New Roads',
                      'Road Improvements',
                      'Bridges and Structures',
                      'Other',
                    ]}
                    colours={stackedReportChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                  <SLStackedLineChart
                    queryInfo={roadMaintenance}
                    title1="Road maintenance, operations"
                    title2="and renewals"
                    labels={[
                      'Corridor',
                      'Pavement and Surfacing',
                      'Emergency',
                      'Environment and Drainage',
                      'Footpaths, Cycleways, and Structures',
                    ]}
                    colours={stackedReportChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                </Flex>
              </Box>

              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Funding</Text>
                <Flex alignContent="start">
                  {/* one chart */}
                  <SLStackedLineChart
                    queryInfo={coInvestExpenditure}
                    title1="Co-invested expenditure"
                    title2="and funding"
                    labels={[
                      'TA',
                      'NLTF',
                    ]}
                    colours={stackedReportChartColours2}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                </Flex>
              </Box>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Activity Class Expenditure</Text>
                <Flex alignContent="start" className="gap">
                  {/* three charts */}
                  <SLAreaLineChart
                    queryInfo={roadSafetyPromotion}
                    title1="Road safety promotion"
                    title2="&nbsp;"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                  <SLAreaLineChart
                    queryInfo={walkingCycling}
                    title1="New and improved walking and cycling facilities"
                    title2="(Excl. low cost, low risk)"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                  <SLAreaLineChart
                    queryInfo={investManagement}
                    title1="Investment management, network"
                    title2="and property management"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                </Flex>
              </Box>

              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Valuation</Text>
                <Flex alignContent="start">
                  {/* one chart */}
                  <SLLineChart
                    queryInfo={roadingAssets}
                    title1="Roading Assets"
                    title2="&nbsp;"
                    labels={[
                      'Total estimated replacement cost',
                      'Carrying amount',
                    ]}
                    colours={lineChartColours}
                    unitprefix="$"
                    unitpostfix="M"
                  />
                </Flex>
              </Box>
              <Box>
                <Text mt={5} mb={5} textStyle="reportSectionHeader">Cost Efficiency</Text>
                <Flex alignContent="start">
                  {/* two charts */}
                  <SLAreaLineChart
                    queryInfo={totalExpenditure}
                    title1="Total expenditure/length"
                    title2="($1,000/km)"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix="$"
                    unitpostfix=""
                  />
                  <SLAreaLineChart
                    queryInfo={maintenanceOperations}
                    title1="Maintenance, operations and renewals"
                    title2="expenditure/length ($1,000/km)"
                    labels={[
                      'TA',
                      'Peer Group',
                      'top',
                      '25-75th National %ile',
                    ]}
                    colours={areaLineReportChartColours}
                    unitprefix="$"
                    unitpostfix=""
                  />
                </Flex>
              </Box>
            </Wrap>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default Delivery;
