import {
  Box,
  Grid,
  GridItem,
  Text,
  useTheme,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { groupBy } from 'lodash';

import { COMPARISON_COLORS } from '@src/utils/color';
import { roundToDecimal, convertBubbleRange, getTrendDirection } from '@src/utils/math';
import { useTrendSvg } from '../../../../hooks/useTrendSvg';
import ReportSubheading from '../../../shared/ReportSubheading';
import { useFilteredCategories } from '../../../../hooks/useFilteredCategories';
import ChartContainer from '../../../shared/ChartContainer';
import DataTable from './DataTable';

export default function CollectiveRiskReport({ filters, reportData, networkSelector }) {
  const theme = useTheme();
  const { upRedSvg, downGreenSvg, flatSvg } = useTrendSvg();
  const filteredCategories = useFilteredCategories(filters);
  const rca = reportData.rcas[0];
  const { lockYear } = reportData;
  const rcaOverview = reportData.data.filter((x) => x.id === rca.id)
    .filter((x) => x.countTotal > 0)
    .map((item) => ({
      category: item.category,
      value: item.countTotal,
      collectiveRisk: item.collectiveRisk,
      vkt: roundToDecimal(item.vkt, 1),
      trendM: item.collectiveTrendM,
      length: roundToDecimal(item.length_km, 1),
      trendDirection: getTrendDirection(roundToDecimal(item.collectiveTrendB, 3), roundToDecimal(item.collectiveTrendM, 3), item.collectiveTrendCount),
    }));
  const transformedDataByRca = Object.entries(groupBy(reportData.data, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.category,
        y: item.collectiveRisk.toFixed(1),
      })),
    }));
  const transformedDataByCategory = Object.entries(groupBy(reportData.data, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        rcaName: item.rcaName,
        personalRisk100M: item.personalRisk100M.toFixed(2),
        collectiveRisk: item.collectiveRisk.toFixed(1),
      })),
    }));

  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '2fr 1fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>Average annual reported crashes and trend in crashes</ReportSubheading>
          <Text mb={8}>
            This chart shows for each ONF street category, the number of crashes involving deaths and serious injuries per 1000km, averaged over ten years.
            The arrow above each bar shows the direction of the trend for each ONF street category.
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  xaxis: {
                    type: 'category',
                    categories: [...new Set(rcaOverview.map((x) => x.category))],
                    labels: {
                      style: {
                        colors: rcaOverview.map((x) => theme.colors.onfCategory[x.category].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      forceNiceScale: false,
                      tickAmount: 8,
                      title: {
                        text: 'Reported crashes per 1000km',
                      },
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)}`;
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  colors: rcaOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: false,
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                    custom({ seriesIndex, dataPointIndex, w }) {
                      const {
                        x, y, trend, length, vkt,
                      } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                      return `<div style="padding: 10px">
                      <p style="color: ${theme.colors.onfCategory[x].base}; font-weight: bold; margin-bottom: 10px;">${x}</p> 
                      <ul>
                      <li><b>Crashes per 1000km</b>: ${y.toFixed(1)}</li>
                      <li><b>Trend</b>: ${trend.toFixed(1)}</li>
                      <li><b>Length</b>: ${length} km</li>
                      <li><b>VKT</b>: ${vkt} mil</li>
                      </ul></div>`;
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                  annotations: {
                    points: rcaOverview.map((item) => (
                      {
                        x: item.category,
                        y: item.collectiveRisk,
                        marker: {
                          size: 0,
                        },
                        customSVG: {
                          // eslint-disable-next-line no-nested-ternary
                          SVG: item.trendDirection === 'flat' ? flatSvg : item.trendDirection === 'up' ? upRedSvg : downGreenSvg,
                          cssClass: undefined,
                          offsetX: -30,
                          offsetY: -50,
                        },
                      }
                    )),
                  },
                }
              }
              series={
                [
                  {
                    name: lockYear,
                    data: rcaOverview.map((x) => ({
                      y: x.collectiveRisk, x: x.category, trend: x.trendM, length: x.length, vkt: x.vkt,
                    })),
                  },
                ]
              }
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading color={theme.colors.transportIndicator['Healthy and Safe People'].base}>Length (km) % per ONF Street Category</ReportSubheading>
          <Text mb={8}>This chart shows the percentage of network length (km) per ONF Street Category, for categories that have recorded crashes.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: rcaOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: false,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                  },
                  labels: rcaOverview.map((x) => x.category),
                }
              }
              series={rcaOverview.map((x) => x.length)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>Average annual reported crashes and trend in crashes</ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the annual average crashes involving deaths and serious injuries per kilometre for each ONF street category.
              The higher the bubble is in the chart, the more crashes there are per 1000 kilometres for that category.
              The bubble size shows the number of crashes involving deaths and serious injuries in the last ten years.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
              {
                colors: rcaOverview.map((x) => theme.colors.onfCategory[x.category].base),
                grid: {
                  padding: {
                    left: 20,
                    bottom: 20,
                  },
                },
                xaxis: {
                  type: 'numeric',
                  title: {
                    text: 'Street Category Network Length (km)',
                    offsetY: 70,
                  },
                  tickAmount: 10,
                  min: -5,
                  max: Math.round(Math.max(...rcaOverview.map((x) => x.length)) * 1.1),
                  labels: {
                    formatter(val) {
                      if (val < 0) return '0 km';
                      return `${val.toFixed(0)} km`;
                    },
                  },
                },
                yaxis: [
                  {
                    forceNiceScale: true,
                    tickAmount: 8,
                    labels: {
                      formatter(val) {
                        return val.toFixed(0);
                      },
                    },
                    title: {
                      text: 'Reported Crashes per 1000km',
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                fill: {
                  opacity: 0.8,
                },
                tooltip: {
                  marker: {
                    show: false,
                  },
                  z: {
                    title: 'Crash Count:',
                  },
                  y: {
                    title: {
                      formatter: () => 'Trend:',
                    },
                  },
                  x: {
                    show: true,
                    formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                  },
                  custom({ seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const { name } = w.globals.initialSeries[seriesIndex];

                    return `<div style="padding: 10px">
                    <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                    <ul>
                    <li><b>Length</b>: ${data.x} km</li>
                    <li><b>Crashes per 1000km</b>: ${data.y.toFixed(2)}</li>
                    <li><b>10 year Crash Count</b>: ${data.crashCount}</li> 
                    <li><b>Trend</b>: ${data.trend.toFixed(2)}</li>
                    </ul></div>`;
                  },
                },
                annotations: {
                  position: 'back',
                  yaxis: [
                    {
                      x: 0,
                      strokeDashArray: 0,
                      borderColor: '#666',
                      borderWidth: 1,
                      opacity: 0.5,
                    },
                  ],
                },
                plotOptions: {
                  bubble: {
                    zScaling: true,
                  },
                },
              }
            }
                series={rcaOverview.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.collectiveRisk,
                      z: convertBubbleRange(item.value, [Math.min(...rcaOverview.map((x) => x.value)), Math.max(...rcaOverview.map((x) => x.value))], [1, 8]),
                      trend: item.trendM,
                      crashCount: item.value,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>
          Comparison with other networks
        </ReportSubheading>
        <Text mb={8}>
          This graph shows the comparison between your network and the other networks you have selected, or between your network and your region, your peer group,
          and the National network.
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    forceNiceScale: true,
                    tickAmount: 8,
                    labels: {
                      formatter(val) {
                        return val.toFixed(0);
                      },
                    },
                    title: {
                      text: 'Reported Crashes per 1000km',
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter(val) {
                    return val || 0;
                  },
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="bar"
            width="100%"
            height="500"
          />
          <Text mt={4} fontSize="sm" fontStyle="italic">
            Categories with low kilometres will show skewed results due to
            the graph calculating average annual crashes per kilometre.
          </Text>
        </ChartContainer>
      </Box>
      <DataTable data={transformedDataByCategory} />
    </>
  );
}
