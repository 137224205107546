import { useEffect, useState, createRef } from 'react';
import {
  GoogleMap, Data, Marker, useLoadScript,
} from '@react-google-maps/api';
import { useRecoilValue } from 'recoil';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import { Box, Center, Text } from '@chakra-ui/react';
import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

import { useMapData } from '@src/modules/performance/context/map-api-hooks';
import councildata from '../CouncilData';
import { COLOURS, BORDERHIGHLIGHTSHADES, HIGHLIGHTSHADES } from '../shared/MapColours';
import PopupBox from './PopupBox';

import {
  mapActivityToText,
  mapCoassuranceToText,
  mapCoInvestorLabel,
  mapServicePerformanceToText,
} from '../shared/IndicatorText';

import { getCouncilNameFromId } from '../../../shared/councilnames';

/* google map attributes */
const containerStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexGrow: '1',
  left: 0,
  userSelect: 'none',
};

const center = {
  lat: -40.8,
  lng: 175.5,
};

const zoom = 5.7;

// turn off the labels
const mapStyle = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  // Bryan wanted a lighter blue colour ocean, so thats what this does
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      { hue: COLOURS.OCEANBLUE },
      { lightness: 50 },
      { saturation: -25 },
    ],
  },
];

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: mapStyle,
};

function RCAMap({ initMap, currentSelection, filter }) {
  const config = useConfig();
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  // using this useLoadScript hook ensures there is no 'map instance' error on the infobox
  // when going back and forth between
  // this map and other pages
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_API_KEY,
    preventGoogleFonts: true,
  });
  const { data: mapData } = useMapData(currentSelection, filter);
  // these reference the areas on the map
  const [mapRefs, setMapRefs] = useState({});
  // track our mapdata so we can display it in hoverbox
  // const [mapData, setMapData] = useState({});

  // map hover box
  const [hover, setHover] = useState({
    visible: false,
    // just some random point in NZ
    // causes the map to center on this point!
    position: { lat: -39.23225314171489, lng: 175.95703125000003 },
    council: '',
    value: '',
    subtext: null,
  });

  // configure the refs
  useEffect(() => {
    const refs = {};
    Object.keys(councildata.councils).forEach((council) => {
      refs[council] = createRef();
    });
    setMapRefs(refs);
  }, []);

  // blank - no colour
  const clearArea = (council) => {
    if (mapRefs[council] && mapRefs[council].current) {
      mapRefs[council].current.state.data?.setStyle({
        fillColor: 'white', // don't colour regions if we have no data
        fillOpacity: 0.0,
        strokeWeight: 0,
      });
      // clear this custom property
      mapRefs[council].current.colour = null;
    }
  };

  const cleanMap = () => {
    Object.keys(councildata.councils).forEach((council) => {
      clearArea(council);
    });
  };

  const setAreaColour = (council, colour, colourName) => {
    if (mapRefs[council] && mapRefs[council].current) {
      mapRefs[council].current.state.data.setStyle({
        fillColor: colour,
        fillOpacity: 1.0,
        strokeWeight: 0.2,
        strokeColor: '#FFFFFF',
      });
      // set this custom property
      mapRefs[council].current.colour = colourName;
    }
  };

  // map the council kpi values to nice values shown in map indicators
  const getValuesForInfobox = (council, kpi) => {
    const result = {
      value: '',
      subtext: null,
    };
    switch (kpi) {
      case 'safety':
        if (mapData[council] && mapData[council].value && mapData[council].value !== null) {
          // always format as an integer
          result.value = mapData[council].value;
          result.subtext = `(${mapData[council].totalCrashes} crashes)`;
        } else {
          result.value = 'No data';
        }
        break;
      case 'amenityCondition':
        if (mapData[council] && mapData[council].value && mapData[council].value !== null) {
        // format as nice percentage
          result.value = `${mapData[council].value.toFixed(1)}%`;
        } else {
          result.value = 'No data';
        }
        break;
      case 'activity':
      // map to text string
        result.value = mapActivityToText(mapData[council].value, filter);
        result.subtext = mapData[council].subText;
        break;
      case 'coassurance':
        if (mapData[council] && mapData[council].score) {
        // map to text string
          result.value = mapCoassuranceToText(mapData[council].score);
          if (mapData[council].score > 0) {
            result.subtext = `${mapCoInvestorLabel(mapData[council].isTechnical)} ${mapData[council].auditDate}`;
          }
        } else {
          result.value = 'Not Available';
        }
        break;
      case 'servicePerformance':
        // map to text string
        result.value = mapServicePerformanceToText(mapData[council].value);
        break;
      case 'dataQuality':
        // map to text string
        if (mapData[council] && mapData[council].value >= 0) {
          // map to text string
          result.value = `${mapData[council]?.value}%`;
        } else {
          result.value = '';
        }
        break;
      default:
      // nothing
        break;
    }
    return result;
  };

  // update the map if the mapdata changes
  useEffect(() => {
    cleanMap();
    if (!currentSelection) return;
    if (currentSelection === 'safety' || currentSelection === 'amenityCondition') {
      // data is just a colour
      Object.keys(mapData).forEach((council) => {
        if (mapData[council] && mapData[council].colour !== 'none') {
          setAreaColour(council, COLOURS[mapData[council].colour], mapData[council].colour);
        } else {
          clearArea(council);
        }
      });
    } else if (currentSelection === 'coassurance') {
      // data is a object and we need to map it
      Object.keys(mapData).forEach((council) => {
        const c = councildata.mapValueToColourRange(mapData[council].score, councildata.colours[currentSelection]);
        if (mapData[council] && mapData[council].score > 0) {
          setAreaColour(council, COLOURS[c], c);
        } else {
          clearArea(council);
        }
      });
    } else if (currentSelection === 'servicePerformance') {
      // data is a object and we need to map it
      Object.keys(mapData).forEach((council) => {
        const c = councildata.colours.servicePerformance[mapData[council].value];
        if (mapData[council]) {
          setAreaColour(council, COLOURS[c], c);
        } else {
          clearArea(council);
        }
      });
    } else if (currentSelection === 'dataQuality') {
      // data is a object and we need to map it
      Object.keys(mapData).forEach((council) => {
        const c = councildata.mapValueToColourRange(mapData[council].value, councildata.colours[currentSelection]);
        if (mapData[council] && c !== 'none') {
          setAreaColour(council, COLOURS[c], c);
        } else {
          clearArea(council);
        }
      });
    } else if (currentSelection === 'activity') {
      // data is a value and we need to map it
      Object.keys(mapData).forEach((council) => {
        const c = councildata.calculateActivityColor(mapData[council].value, filter);
        if (mapData[council] && c !== 'none') {
          setAreaColour(council, COLOURS[c], c);
        } else {
          clearArea(council);
        }
      });
    } else {
      // data is a value and we need to map it
      Object.keys(mapData).forEach((council) => {
        const c = councildata.mapValueToColourRange(mapData[council], councildata.colours[currentSelection]);
        if (mapData[council] && c !== 'none') {
          setAreaColour(council, COLOURS[c], c);
        } else {
          clearArea(council);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);

  // nice error message
  if (loadError) {
    return (
      <Box>
        <Center>
          <Text>
            Map cannot be loaded right now, sorry.
          </Text>
        </Center>
      </Box>
    );
  }

  if (!isLoaded) {
    // placeholder box
    return (
      <PlaceHolder height="100%" width="100%" />
    );
  }

  const getLatLonCenterFromGeom = (coords) => {
    const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
    const centerLat = arrAvg(coords.map((c) => c[1]));
    const centerLon = arrAvg(coords.map((c) => c[0]));
    if (Number.isNaN(centerLat) || Number.isNaN(centerLon)) return null;
    return { lat: centerLat, lng: centerLon };
  };

  // we're good, render the actual map
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      id="map"
      options={options}
      onLoad={initMap}
    >
      {parseInt(rcaId, 10) < 88 && parseInt(rcaId, 10) !== 81 && councildata.councils[rcaId]?.geometry?.coordinates[0][0]
        && (
        <Marker
          position={getLatLonCenterFromGeom(councildata.councils[rcaId].geometry.coordinates[0][0])}
        />
        )}
      { /* Child components, such as markers, info windows, etc. */ }
      {Object.keys(councildata.councils).map((council) => (
        <Data
          key={council}
          ref={mapRefs[council]}
          onLoad={(data) => {
            data.addGeoJson(councildata.councils[council]);
            data.setStyle({
              fillColor: '#FFFFFF',
              fillOpacity: 0.0,
              strokeWeight: 0,
            });
          }}
          onMouseOver={(event) => {
            // on hover, show popup
            const result = getValuesForInfobox(council, currentSelection);
            setHover({
              visible: true,
              position: event.latLng,
              council: getCouncilNameFromId(council),
              value: result.value,
              subtext: result.subtext,
            });
            // and highlight the council border in black
            if (mapRefs[council] && mapRefs[council].current) {
              if (mapRefs[council].current.colour) {
                const c = mapRefs[council].current.colour;
                mapRefs[council].current.state.data.overrideStyle(event.feature, {
                  fillColor: HIGHLIGHTSHADES[c],
                  strokeColor: BORDERHIGHLIGHTSHADES[c],
                  strokeWeight: 1,
                  zIndex: 10, // ensure the district is drawn on top of the others. Otherwise border is only partially visible
                });
              } else {
                // these are blank areas on the map - give them a light grey border on hover
                mapRefs[council].current.state.data.overrideStyle(event.feature, {
                  strokeColor: 'grey',
                  strokeWeight: 1,
                  zIndex: 10, // ensure the district is drawn on top of the others. Otherwise border is only partially visible
                });
              }
            }
          }}
          onMouseOut={(event) => {
            // hide the hover
            setHover({
              visible: false,
              council: '',
              position: event.latLng,
              subtext: null,
            });
            // revert styling on map element
            if (mapRefs[council] && mapRefs[council].current) {
              mapRefs[council].current.state.data.revertStyle();
            }
          }}
        />
      ))}
      {currentSelection
        && (
        <PopupBox
          visible={hover.visible}
          position={hover.position}
          title={hover.council}
          value={hover.value}
          fontSize={currentSelection === 'safety' || currentSelection === 'amenityCondition' || currentSelection === 'dataQuality' ? 22 : 12}
          subtext={hover.subtext}
        />
        )}
    </GoogleMap>
  );
}

export default RCAMap;
