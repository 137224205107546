import {
  Menu, MenuButton, MenuList, MenuItem, MenuGroup, Button, Spinner,
  Icon, Text,
} from '@chakra-ui/react';
import { FiChevronDown, FiCheckCircle, FiCircle } from 'react-icons/fi';
import { useRecoilValue, useRecoilState } from 'recoil';
import { groupBy } from 'lodash';
import { selectedDatabaseIdState, useIsUserInRole } from '@transport-insights/uikit';
import { useStateHighwaysSubnetworks } from '../context/reports-api-hooks';
import { selectedStateHighwaysSubnetwork } from '../state';

function StateHighwaysSubnetworkSelector({ allowedGroups = ['hno', 'noc'], ...rest }) {
  const hasDeveloperRole = useIsUserInRole('Developer');
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const { data, isLoading, isError } = useStateHighwaysSubnetworks();
  const [selectedSubnetwork, setSelectedSubnetwork] = useRecoilState(selectedStateHighwaysSubnetwork);

  // Group subnetworks by 'networkGroup'
  const groupedSubnetworks = data ? groupBy(data, 'networkGroup') : {};

  // Handler for selection
  const handleSelect = (value) => {
    setSelectedSubnetwork(value);
  };

  // Get the selected subnetwork's displayName for the button
  const selectedSubnetworkName = selectedSubnetwork === ''
    ? 'All'
    : data?.find((subnetwork) => subnetwork.id === selectedSubnetwork)?.displayName;

  if (rcaId !== 81 || !hasDeveloperRole) return null;

  return (
    <Menu>
      <MenuButton
        width="fit-content"
        as={Button}
        rightIcon={<Icon as={FiChevronDown} />}
        isDisabled={isError || isLoading || !selectedSubnetworkName}
        colorScheme="gray"
        {...rest}
      >
        State Highways:
        {' '}
        {isLoading || !selectedSubnetworkName ? <Spinner size="sm" /> : selectedSubnetworkName}
      </MenuButton>
      <MenuList maxHeight="300px" overflowY="auto" zIndex="500">
        <MenuItem
          onClick={() => handleSelect('')}
          value=""
          icon={selectedSubnetwork === ''
            ? <Icon as={FiCheckCircle} color="brand.orange.500" boxSize={4} /> : <Icon as={FiCircle} color="gray.200" boxSize={4} />}
          fontSize="sm"
        >
          All
        </MenuItem>

        {Object.keys(groupedSubnetworks)
          .filter((group) => allowedGroups.includes(group.toLowerCase()))
          .map((group) => (
            <MenuGroup key={group}>
              {group === 'HNO' && <Text fontSize="sm" fontWeight="bold" color="brand.orange.500" py={2} px={2}>Regions</Text>}
              {group === 'NOC' && <Text fontSize="sm" fontWeight="bold" color="brand.orange.500" py={2} px={2}>Network Outcome Contracts (NOCs)</Text>}
              {groupedSubnetworks[group].map((subnetwork) => (
                <MenuItem
                  key={subnetwork.id}
                  onClick={() => handleSelect(subnetwork.id)}
                  value={subnetwork.id}
                  icon={selectedSubnetwork === subnetwork.id ? <Icon as={FiCheckCircle} color="brand.orange.500" boxSize={4} /> : <Icon as={FiCircle} color="gray.200" boxSize={4} />}
                  fontSize="sm"
                >
                  {subnetwork.displayName}
                </MenuItem>
              ))}
            </MenuGroup>
          ))}
      </MenuList>
    </Menu>
  );
}

export default StateHighwaysSubnetworkSelector;
