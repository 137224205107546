import { useConfig } from '@src/context/config';
import { useAuthenticatedApi } from '@transport-insights/uikit';
import { replace } from 'lodash';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getLevelsOfServiceAssets(rcaId, lockYear) {
      const res = await api.get(`levels-of-service/assets/${rcaId}/${replace(lockYear, '/', '-')}`);
      return res?.data ?? [];
    },
    async getLevelsOfServiceDataConfidence(rcaId, lockYear) {
      const res = await api.get(`levels-of-service/data-confidence/${rcaId}/${replace(lockYear, '/', '-')}`);
      return res?.data ?? [];
    },
    async getLevelsOfServiceAssetHistorical(name, subType, rcaId, lockYear) {
      const res = await api.get(`levels-of-service/asset/historical/${name}/${subType}/${rcaId}/${replace(lockYear, '/', '-')}`);
      return res?.data ?? [];
    },
    async getLevelsOfServiceAssetCategory(name, subType, rcaId, lockYear) {
      const res = await api.get(`levels-of-service/asset/category/${name}/${subType}/${rcaId}/${replace(lockYear, '/', '-')}`);
      return res?.data ?? [];
    },
  };
};
