export const emptyChart = {
  id: 'emptyChart',
  afterDraw(chart) {
    const dataArray = chart.data.datasets;
    let isEmpty = true;

    dataArray.forEach((x) => {
      if (x.data.length === 0) {
        isEmpty = true;
      } else if (x.data.some((element) => element !== null) && x.data.some((element) => element !== 0)) {
        isEmpty = false;
      } else if (x.data.every((element) => element === 0)) {
        isEmpty = false;
      }
    });

    if (isEmpty) {
      const { ctx } = chart;
      const { width: w } = chart;
      const { height: h } = chart;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '24px Arial';
      ctx.fillText('No data', w / 2, h / 2);
      ctx.restore();
    }
  },
};
