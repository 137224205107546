import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import {
  convertBubbleRange,
} from '@src/utils/math';
import { getContrastColor, COMPARISON_COLORS, RAG_COLORS } from '@src/utils/color';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import ReportSubheading from '../shared/ReportSubheading';
import CollapsedContent from '../shared/CollapsedContent';
import ChartContainer from '../shared/ChartContainer';

const { red, green } = RAG_COLORS;

export default function Ep2({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const filteredCategories = useFilteredCategories(filters);
  const rca = reportData.rcas[0];
  const filteredReportData = reportData.data.filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = filteredReportData.filter((x) => x.id === rca.id);
  const totalUnavailable = selectedRcaData.map((item) => item.lengthPerc).reduce((a, b) => a + b, 0) / filteredCategories.length;
  const barChart = Object.entries(groupBy(selectedRcaData, 'causeDescription'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({ x: item.category, y: item.lengthPerc })),
    }));

  const bubbleChartData = Object.entries(groupBy(selectedRcaData, 'category'))
    .map(([k, v]) => ({
      category: k,
      length: v[0].length_km,
      heavyVKT: v[0].heavyVKT,
      lengthPerc: v.map((item) => item.lengthPerc).reduce((a, b) => a + b, 0),
    }));
  const comparisonData = Object.entries(groupBy(filteredReportData, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        category: item.category,
        lengthPerc: item.lengthPerc,
      })),
    }))
    .map((x) => ({
      name: x.name,
      data: Object.entries(groupBy(x.data, 'category'))
        .map(([k, v]) => ({
          x: k,
          y: v.map((item) => item.lengthPerc).reduce((a, b) => a + b, 0),
        })),
    }));
  const dataTable = Object.entries(groupBy(reportData.data, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        rcaName: item.rcaName,
        year: item.lockYear,
        causeDescription: item.causeDescription,
        lengthPerc: item.lengthPerc,
      })),
    }))
    .map((x) => ({
      name: x.name,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          year: v[0].year,
          class1Perc: v.filter((y) => y.causeDescription === 'Class 1 HCV').map((item) => item.lengthPerc).reduce((a, b) => a + b, 0),
          hpmvPerc: v.filter((y) => y.causeDescription === 'HPMV').map((item) => item.lengthPerc).reduce((a, b) => a + b, 0),
          max50Perc: v.filter((y) => y.causeDescription === '50MAX').map((item) => item.lengthPerc).reduce((a, b) => a + b, 0),
        })),
    }));

  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '1fr 2fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Heavy Vehicle Accessibility
          </ReportSubheading>
          <Text mb={8}>This chart shows the overall percentage of the network that is available and unavailable to the selected class of heavy vehicle.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: [red, green],
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: false,
                    marker: {
                      show: false,
                    },
                  },
                  labels: ['Unavailable', 'Available'],
                }
              }
              series={[totalUnavailable, 100 - totalUnavailable]}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Heavy Vehicle Accessibility by ONF Street Category
          </ReportSubheading>
          <Text mb={4}>
            The bars show the percentage of the overall network that is not accessible to the selected heavy vehicle class, for each ONF Street Category.
          </Text>

          <ChartContainer>
            <Chart
              options={
                {
                  colors: filteredCategories.map((x) => theme.colors.onfCategory[x.description].base),
                  xaxis: {
                    labels: {
                      style: {
                        colors: filteredCategories.map((x) => theme.colors.onfCategory[x.description].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'Percentage of Network Unavailable',
                      },
                      labels: {
                        formatter(value) {
                          return `${value.toFixed(1)}%`;
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    marker: {
                      show: false,
                    },
                    y: {
                      title: { formatter: () => 'Unavailable' },
                      formatter(value) {
                        return `${value}%`;
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                }
              }
              series={barChart}
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>
          Heavy Vehicle Accessibility
        </ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the percentage of the overall network that is not accessible to the selected heavy vehicle class, compared to the network length for each ONF category.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
                  {
                    colors: bubbleChartData.map((x) => theme.colors.onfCategory[x.category].base),
                    grid: {
                      padding: {
                        left: 20,
                        bottom: 20,
                      },
                    },
                    xaxis: {
                      type: 'numeric',
                      title: {
                        text: 'Street Category Network Length (km)',
                        offsetY: 70,
                      },
                      min: 0,
                      max: Math.max(...bubbleChartData.map((x) => x.length)) * 1.1,
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)} km`;
                        },
                      },
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: (Math.max(...bubbleChartData.map((x) => x.lengthPerc)) + 0.5) * 1.1,
                        title: {
                          text: 'Percentage of Network Unavailable',
                        },
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(1)}%`;
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    fill: {
                      opacity: 0.8,
                    },
                    tooltip: {
                      marker: {
                        show: false,
                      },
                      z: {
                        title: 'Vehicle Count',
                      },
                      y: {
                        title: {
                          formatter: () => 'Crash Count:',
                        },
                      },
                      x: {
                        show: true,
                        formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                      },
                      custom({ seriesIndex, dataPointIndex, w }) {
                        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        const { name } = w.globals.initialSeries[seriesIndex];

                        return `<div style="padding: 10px">
                        <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                        <ul>
                        <li><b>Unavailable</b>: ${data.y}%</li>
                        <li><b>Heavy VKT</b>: ${data.heavyVKT}</li>
                        <li><b>Length</b>: ${data.x}km</li>
                        </ul></div>`;
                      },
                    },
                    plotOptions: {
                      bubble: {
                        zScaling: true,
                      },
                    },
                  }
                }
                series={bubbleChartData.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.lengthPerc,
                      z: convertBubbleRange(item.heavyVKT, [Math.min(...bubbleChartData.map((x) => x.heavyVKT)), Math.max(...bubbleChartData.map((x) => x.heavyVKT))], [1, 8]),
                      heavyVKT: item.heavyVKT,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>
          Comparison with other networks
        </ReportSubheading>
        <Text mb={8}>
          This graph shows the percentage of the overall network that is not accessible to the selected heavy vehicle class for the other networks you are comparing with (defaults to the Peer Group and Region of the RCA, and National).
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    title: {
                      text: 'Percentage of Network Unavailable',
                    },
                    labels: {
                      formatter(value) {
                        return `${value.toFixed(1)}%`;
                      },
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter(val) {
                    return val || 0;
                  },
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={comparisonData}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">Category</Th>
                  <Th background="gray.50">Network</Th>
                  <Th background="gray.50">Year</Th>
                  <Th background="gray.50">Class 1 Heavy % Unavailable</Th>
                  <Th background="gray.50">HPMV % Unavailable</Th>
                  <Th background="gray.50">50MAX % Unavailable</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataTable.map((item) => item.data.map(({
                  rcaName, year, class1Perc, hpmvPerc, max50Perc,
                }, i) => (
                  <Tr key={`cat_${i}`}>
                    {
                        i === 0
                        && (
                        <Td
                          rowSpan={item.data.length}
                          color={getContrastColor(theme.colors.onfCategory[item.name].base)}
                          background={theme.colors.onfCategory[item.name].base}
                          borderBottom="2px solid"
                          borderColor={i === 0 ? theme.colors.onfCategory[item.name].base : 'gray.100'}
                          fontWeight="bold"
                        >
                          {item.name}
                        </Td>
                        )
                      }
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{rcaName}</Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{year}</Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>
                      {class1Perc}
                      %
                    </Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>
                      {hpmvPerc}
                      %
                    </Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>
                      {max50Perc}
                      %
                    </Td>
                  </Tr>
                )))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
