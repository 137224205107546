import {
  Flex,
  FormControl, FormLabel, RadioGroup, Switch,
  Text, VStack, Radio, HStack,
} from '@chakra-ui/react';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import { useState } from 'react';
import Chart from 'react-apexcharts';

function PieChart({ chartData }) {
  return (
    <Chart
      options={{
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        chart: {
          selection: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: false,
          y: {
            formatter: (val) => `${val.toFixed(2)}%`,
          },
        },
        colors: chartData.map((d) => d.color),
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
              value: null,
            },
          },
        },
        stroke: {
          width: 1,
        },
        labels: chartData.map((d) => d.label),
      }}
      series={chartData.map((d) => d.value)}
      type="pie"
      width="100%"
    />
  );
}

export function AssetSelectorCard({
  title, assetList, isSelected, subType, onSetSelection,
}) {
  const subTypes = assetList.map((x) => x.subType).sort();
  // This is gross but needed to handle keeping the correctly selected sub type
  // when we switch councils
  const [selectedSubType, setSelectedSubType] = useState(isSelected ? subType : subTypes[0]);
  const filteredAsset = assetList.find((x) => x.subType === selectedSubType);

  return (
    <ChartContainer
      cursor="pointer"
      borderColor={isSelected ? 'brand.orange.400' : 'gray.100'}
      borderWidth="2px"
      _hover={{ borderColor: 'brand.orange.400' }}
      onClick={() => onSetSelection(filteredAsset)}
    >
      <Flex h="100%">
        <VStack>
          <Text textStyle="cardHeader" whiteSpace="nowrap">
            {title}
          </Text>
          <PieChart chartData={filteredAsset?.chartData || []} />
          {subTypes.length > 1
            && (
            <FormControl display="flex" alignItems="center" isDisabled={!isSelected} justifyContent="center">
              {title === 'Surface Water Channels' && (
              <FormLabel htmlFor="toggle" mb="0" fontSize="11px">
                Include SWCD/SWCS/ESWC?
              </FormLabel>
              )}
              {title === 'Drainage' ? (
                <RadioGroup
                  size="sm"
                  value={selectedSubType}
                  onChange={(s) => {
                    setSelectedSubType(s);
                    const a = assetList.find((x) => x.subType === s);
                    onSetSelection(a);
                  }}
                >
                  <HStack>
                    {subTypes.map((option) => (
                      <Radio key={option} value={option} opacity="1">
                        <Text fontSize="11px" fontWeight="bold">
                          {option}
                        </Text>
                      </Radio>
                    ))}
                  </HStack>
                </RadioGroup>
              )
                : (
                  <Switch
                    id="toggle"
                    onChange={(e) => {
                      const s = e.target.checked === true ? 'All' : 'Above';
                      setSelectedSubType(s);
                      const a = assetList.find((x) => x.subType === s);
                      onSetSelection(a);
                    }}
                    isChecked={selectedSubType === 'All'}
                    size="sm"
                  />
                )}
            </FormControl>
            )}
        </VStack>
      </Flex>
    </ChartContainer>
  );
}
