import { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import TornadoChart from '../charts/TornadoChart';

function SLTornadoChart({
  queryInfo, title1, title2, labels, colours, unitprefix, unitpostfix, precision, ...props
}) {
  const [chart, setChart] = useState({
    grouping: [],
    datasets: [],
  });

  useEffect(() => {
    if (queryInfo?.data) {
      setChart({
        categories: queryInfo.data.categories,
        datasets: queryInfo.data.values,
      });
    }
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box>
      <Text align="center" fontSize={12} fontWeight="bold">{title1}</Text>
      <Text align="center" fontSize={12} fontWeight="bold" mb={5}>{title2}</Text>
      <LockYearBadge lockYear={queryInfo?.data?.lockYear} />
      <Center>
        <TornadoChart
          categories={chart.categories}
          labels={labels}
          datasets={chart.datasets}
          colours={colours}
          unitprefix={unitprefix}
          unitpostfix={unitpostfix}
          precision={precision}
          {...props}
        />
      </Center>
    </Box>
  );
}

export default SLTornadoChart;
