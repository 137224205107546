import { Bar, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartColours as COLOURS } from './ChartColours';

Chart.register([annotationPlugin, ChartDataLabels]);

function TornadoChart({
  categories, labels, datasets, colours, unitprefix, unitpostfix, precision, ...props
}) {
  const height = 400;
  const width = 400;

  let fixedPrecision = 1;
  if (precision !== null) {
    fixedPrecision = precision;
  }

  const data = {
    labels: categories,
    datasets: [
      {
        label: labels[0],
        // transform to negative value
        data: datasets && datasets.length > 0 ? datasets[0].map((a) => -a) : [],
        backgroundColor: COLOURS[colours[0]],
        datalabels: {
          anchor: 'start',
          align: 'left',
          formatter: (value) => {
            if (value !== null) {
              return `${unitprefix}${-value.toFixed(fixedPrecision)}${unitpostfix}`;
            }
            return `${unitprefix}0${unitpostfix}`;
          },
        },
      },
      {
        label: labels[1],
        data: datasets[1] || [],
        backgroundColor: COLOURS[colours[1]],
        datalabels: {
          anchor: 'end',
          align: 'right',
          formatter: (value) => {
            if (value !== null) {
              return `${unitprefix}${value.toFixed(fixedPrecision)}${unitpostfix}`;
            }
            return `${unitprefix}0${unitpostfix}`;
          },
        },
      },
    ],
  };

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    indexAxis: 'y', // horizontal bar
    scales: {
      y: {
        position: 'right', // y-axis on the right
        stacked: true,
        beginAtZero: true,
        grid: {
          drawBorder: false, // hide the y-axis bar
          display: false,
        },
        ticks: {
          padding: 45, // spacing between ticks and y-values
        },
      },
      x: {
        display: false,
        stacked: true,
        grid: {
          display: false, // hide the vertical grid lines
        },
      },
    },
    // this ensures the numbers on the left don't get chopped off
    layout: {
      padding: {
        left: 40,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${unitprefix}${parseFloat(tooltipItem.raw).toFixed(fixedPrecision)}${unitpostfix}`,
        },
        // hide tooltips if there is no data
        filter: (tooltipItem) => !!tooltipItem.raw,
      },
      legend: {
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
        },
      },
      // use the annotation plugin to draw a vertical line in the center of the graph
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            xMin: 0,
            xMax: 0,
            borderColor: '#333',
            borderWidth: 1,
            label: {
              content: '',
              enabled: false,
            },
          },
        ],
      },
    },
  };

  return (
    <Bar data={data} options={options} height={height} width={width} {...props} />
  );
}

export default TornadoChart;
