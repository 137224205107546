import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
import { currentDatabaseSelector, selectedLockYearState } from '@transport-insights/uikit';
import useOnfApi from '../api/useOnfApi';

export function useReports(name, params) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = useOnfApi();

  return useQuery(
    ['onf-reports', name, rca?.id, lockYear, params],
    async () => api.getReportData(name, rca?.id, lockYear, params),
    {
      enabled: rca?.id !== undefined && lockYear !== null,
      cacheTime: 0,
    },
  );
}

export function useDataQualityReport(reportCode) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = useOnfApi();
  const onfReportCode = `onf${reportCode}`;

  return useQuery(
    ['data-quality-report', onfReportCode, rca?.id, lockYear],
    async () => api.getDataQuality(onfReportCode, rca.id, lockYear),
    {
      enabled: rca?.id !== undefined && lockYear !== null,
    },
  );
}
