import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import useOnfApi from '@src/modules/onf/api/useOnfApi';

export const LOCK_YEAR = 'latest';

export function useReports(name) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockYear = 'latest';
  const api = useOnfApi();
  return useQuery(
    ['regional-reports', name, rca?.id, lockYear],
    async () => api.getRegionalReportData(name, rca?.id, lockYear),
    {
      enabled: rca?.id !== undefined && lockYear !== null,
    },
  );
}
