import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import PageLoader from '@src/components/layouts/shared/PageLoader';
import ErrorPage from '@src/components/pages/ErrorPage';

export default function SignOut() {
  const { instance } = useMsal();
  const [redirect, setRedirect] = useState({ loading: true, error: undefined });

  useEffect(() => {
    instance.logoutRedirect()
      .catch((error) => setRedirect({ loading: false, error }));
  }, [instance]);

  return (
    <PageLoader isLoading={redirect.loading}>
      { redirect.error && <ErrorPage error={redirect.error} />}
    </PageLoader>
  );
}
