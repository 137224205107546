import {
  Box,
  Button,
  Container,
  Flex, Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { FiAlertCircle } from 'react-icons/fi';

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <MainLayout>
      <Container maxW="container.lg">
        <Flex align="center" justify="center" width="100%" py={8}>
          <Box textAlign="center">
            <Icon as={FiAlertCircle} boxSize="16" color="gray.400" mb={4} />
            <Heading color="gray.600" fontSize="3xl" mb={2}>Oops, there was an error</Heading>
            <Text color="gray.400">Please try reloading the page. Contact support if the problem persists.</Text>
            <Button onClick={resetErrorBoundary} mt={8}>Reload Page</Button>
          </Box>
        </Flex>
      </Container>
    </MainLayout>
  );
}

function logError(error, info) {
  // eslint-disable-next-line no-console
  console.error('ErrorBoundary caught an error');
  // eslint-disable-next-line no-console
  console.error(error, info);
}

export default function ErrorBoundary({ children }) {
  return (
    <ReactErrorBoundary fallbackRender={ErrorFallback} onError={logError}>
      { children }
    </ReactErrorBoundary>
  );
}
