import {
  Container,
  Heading,
  Grid,
  LinkBox,
  LinkOverlay,
  Box,
  Text,
  Link,
  useTheme,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';
import MainLayout from '@src/components/layouts/main';

export default function ReportsList() {
  const theme = useTheme();
  const enabledReportsList = reportsList.filter((x) => x.enabled);

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Heading as="h1">
          Regional Reporting
        </Heading>
        <Box p={4} my={4} borderRadius="md" backgroundColor="gray.100">
          <Text mb={4}>Transport is critical for connecting people, goods, places, supporting a thriving economy, and society. A well-connected transport system is a mechanism for social equity and access for all people.  However, the transport system in New Zealand is also a major source of greenhouse gas emissions, and the increasing impacts of climate change causing severe weather events on our transport networks are highlighting the need for change in how we move people and goods about New Zealand.</Text>
          <Text mb={4}>
            The regional reporting in Transport Insights has been developed to assist regional council transport staff in sourcing reliable data for
            {' '}
            <Link href="https://www.nzta.govt.nz/assets/planning-and-investment/nltp/2024/docs/Developing-regional-land-transport-plans-guidance.pdf" isExternal>monitoring and reporting on Regional Land Transport Plans (RLTPs)</Link>
            . The goal is to achieve greater consistency in regional reporting over time and the data is a consistent data source for all New Zealand regions.  The intent is to have consistent regional data that is centrally available and accessible to all New Zealand regions, not just the major cities.  Users are encouraged to develop and include additional indicators in their reporting to cover needs/issues specific to each region.
          </Text>
          <Text mb={4}>
            Available capture notes on the source data and its limitations are located on the &apos;More Information&apos; flyout. Click on the &apos;More Information&apos; icon.  Users can download images of the measure graphs or the data that populates the measure graph with the hamburger icon located on the graph to customise their own reporting.
          </Text>
          <Text>
            Except where otherwise stated, the regional reporting period is annual, by financial year (1 July-30 June).  Current business process is to have updated data available for all annual measures by mid-September each year.
          </Text>
        </Box>
        <Grid templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={4} mt={4}>
          {enabledReportsList.map((report) => (
            <LinkBox
              as="article"
              backgroundColor="white"
              p={4}
              borderRadius="md"
              boxShadow="sm"
              key={report.url}
              border="2px solid"
              borderColor="transparent"
              transition="all 200ms"
              _hover={{ borderColor: theme.colors.brand.orange[500] }}
            >
              <Heading size="md" my="2">
                <LinkOverlay
                  as={RouterLink}
                  to={`/regional-reporting/transport-outcomes/${report.url}`}
                  color="gray.800"
                  fontWeight="normal"
                  textDecoration="none"
                >
                  {report.name}
                </LinkOverlay>
              </Heading>
            </LinkBox>
          ))}
        </Grid>
      </Container>
    </MainLayout>
  );
}
