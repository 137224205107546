import { Box, Flex, Text } from '@chakra-ui/react';

function NoDataChart({ height, width, ...props }) {
  const bheight = height || 250;
  const bwidth = width || 250;
  return (
    <Box width={bwidth} height={bheight} border="solid 1px #ccc" borderRadius={5} {...props}>
      <Flex alignItems="center" height="99%" alignContent="center" justifyContent="center">
        <Text color="#ccc">No Data</Text>
      </Flex>
    </Box>
  );
}

export default NoDataChart;
