import {
  Button, Checkbox,
  HStack, Radio,
  RadioGroup, Text,
} from '@chakra-ui/react';
import useTceApi from '@src/modules/tce/src/api/useTceApi';
import { dateFormatter } from '@tce/components/pages/traffic-count-estimates/components/wizard/table';
import { isEmpty, isFinite } from 'lodash';
import { useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';

const defaultColumns = [
  {
    id: 'cwayNo',
    displayName: 'carr_way_no',
  },
  {
    id: 'roadId',
    displayName: 'road_id',
  },
  {
    id: 'name',
    displayName: 'road_name',
  },
  // no cway end?
  {
    id: 'cwayStart',
    displayName: 'carrway_start_m',
  },
  {
    id: 'cwayMid',
    displayName: 'carrway_mid_m',
  },
  {
    id: 'direction',
    displayName: 'direction',
  },
  {
    id: 'latest',
    displayName: 'latest',
  },
  {
    id: 'calculatedEstimateDate',
    displayName: 'count_date',
  },
  {
    id: 'estimationIndicator',
    displayName: 'Indicator',
  },
  {
    id: 'countStatus',
    displayName: 'count_status',
  },
  {
    id: 'lockyear',
    displayName: 'financial_year',
  },
  // the calculated values
  {
    id: 'calculatedEstimate',
    displayName: 'adt',
  },
  {
    id: 'pcCar',
    displayName: 'pccar',
  },
  {
    id: 'pcLcv',
    displayName: 'pclcv',
  },
  {
    id: 'pcMcv',
    displayName: 'pcmcv',
  },
  {
    id: 'pcHcvI',
    displayName: 'pchcvi',
  },
  {
    id: 'pcHcvII',
    displayName: 'pchcvii',
  },
  {
    id: 'pcBus',
    displayName: 'pcbus',
  },
  {
    id: 'pcHeavy',
    displayName: 'pcheavy',
  },
  {
    id: 'calculationMethod',
    displayName: 'notes',
  },
  {
    id: 'roadType',
    displayName: 'road_type',
  },
  {
    id: 'cwayArea',
    displayName: 'cway_area',
  },
  {
    id: 'cwaySubArea',
    displayName: 'cway_sub_area',
  },
  {
    id: 'hierarchy',
    displayName: 'cway_hierarchy',
  },
  {
    id: 'urbanRural',
    displayName: 'urban_rural',
  },
  {
    id: 'pavementType',
    displayName: 'pavement_type',
  },
  {
    id: 'pavementUse',
    displayName: 'pavement_use',
  },
  {
    id: 'ownerType',
    displayName: 'owner_type',
  },
  {
    id: 'categoryId',
    displayName: 'category_id',
  },
  {
    id: 'category',
    displayName: 'category_desc',
  },
];

const trafficGroupColumns = [
  {
    id: 'trafficGroup',
    displayName: 'traffic_group',
  },
  {
    id: 'udtKey',
    displayName: 'udt_key',
  },
];

export default function DownloadButton({ job }) {
  const [trafficMix, setTrafficMix] = useState('calculated');
  const [showTrafficMixRadio, setShowTrafficMixRadio] = useState(false);
  const [includeTrafficGroups, setIncludeTrafficGroups] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);

  const [results, setResults] = useState(null);
  const api = useTceApi();

  useEffect(() => {
    const getResults = async () => {
      const rows = await api.retrieveResults(job.id);
      if (!isEmpty(rows)) {
        setShowTrafficMixRadio(rows.some((x) => isFinite(x?.estimatedPcCar)));
        setResults(rows);
      }
    };
    getResults();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getResults = () => results.map((s) => {
    const sectionMix = trafficMix === 'estimated' ? {
      pcCar: (s?.estimatedPcCar ?? 0).toFixed(3),
      pcLcv: (s?.estimatedPcLcv ?? 0).toFixed(3),
      pcMcv: (s?.estimatedPcMcv ?? 0).toFixed(3),
      pcHcvI: (s?.estimatedPcHcvI ?? 0).toFixed(3),
      pcHcvII: (s?.estimatedPcHcvII ?? 0).toFixed(3),
      pcBus: (s?.estimatedPcBus ?? 0).toFixed(3),
      pcHeavy: (
        (s?.estimatedPcMcv ?? 0)
        + (s?.estimatedPcHcvI ?? 0)
        + (s?.estimatedPcHcvII ?? 0)
        + (s?.estimatedPcBus ?? 0)
      ).toFixed(3),
    } : {
      pcCar: (s?.calculatedPcCar ?? 0).toFixed(3),
      pcLcv: (s?.calculatedPcLcv ?? 0).toFixed(3),
      pcMcv: (s?.calculatedPcMcv ?? 0).toFixed(3),
      pcHcvI: (s?.calculatedPcHcvI ?? 0).toFixed(3),
      pcHcvII: (s?.calculatedPcHcvII ?? 0).toFixed(3),
      pcBus: (s?.calculatedPcBus ?? 0).toFixed(3),
      pcHeavy: (s?.calculatedPcHeavy ?? 0).toFixed(3),
    };

    return {
      ...s,
      ...sectionMix,
      calculatedEstimateDate: dateFormatter(new Date(s?.calculatedEstimateDate)),
    };
  });

  useEffect(() => {
    if (includeTrafficGroups) {
      setColumns([...columns, ...trafficGroupColumns]);
    } else {
      setColumns(columns.filter((x) => x.id !== 'trafficGroup' && x.id !== 'udtKey'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeTrafficGroups]);

  return (
    <HStack
      spacing={10}
      border="1px solid"
      borderColor="gray.100"
      background="gray.50"
      boxShadow="sm"
      p={2}
      pl={4}
    >
      {showTrafficMixRadio
      && (
      <RadioGroup
        onChange={setTrafficMix}
        value={trafficMix}
      >
        <HStack>
          <Text>Traffic Mix: </Text>
          <Radio value="calculated">Calculated</Radio>
          <Radio value="estimated">Original from RAMM</Radio>
        </HStack>
      </RadioGroup>
      )}

      <Checkbox
        value={includeTrafficGroups}
        onChange={(e) => setIncludeTrafficGroups(e.target.checked)}
      >
        Include Traffic Groups
      </Checkbox>
      <CsvDownloader
        isDisabled={!results}
        filename={`results-${job.rcaId}-${job.importId}-${job?.lockYear.replace('/', '-')}`}
        extension=".csv"
        separator=","
        columns={columns}
        datas={getResults}
      >
        <Button isLoading={!results}>
          Download
        </Button>
      </CsvDownloader>
    </HStack>
  );
}
