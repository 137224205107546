import { Bar, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Text, Box, Center } from '@chakra-ui/react';

Chart.register([annotationPlugin, ChartDataLabels]);

function StackedTornadoChart({ settings, title }) {
  const height = 200;
  const width = 600;

  const data = {
    labels: settings.labels,
    datasets: [],
  };

  // disabled for now, too much clutter
  // this would display the percentage beside the bar
  settings.data.forEach((d, index) => {
    let anchor = 'start';
    let align = 'left';
    let display = false;
    if (index > 0 && index < settings.data.length - 1) {
      anchor = 'end';
      align = 'right';
      display = false;
    }
    if (index >= settings.data.length - 1) {
      anchor = 'end';
      align = 'right';
      display = false;
    }
    data.datasets.push(
      {
        label: d.label,
        data: d.data,
        backgroundColor: d.colour,
        datalabels: {
          display,
          anchor,
          align,
          formatter: (value) => `${Math.round(Math.abs(value))}%`,
        },
      },
    );
  });

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    indexAxis: 'y', // horizontal bar
    scales: {
      y: {
        position: 'left', // y-axis on the right
        stacked: true,
        beginAtZero: true,
        grid: {
          drawBorder: false, // hide the y-axis bar
          display: false,
        },
        ticks: {
          padding: 25, // spacing between ticks and y-values
        },
      },
      x: {
        display: true,
        stacked: true,
        grid: {
          display: true, // hide the vertical grid lines
        },
        ticks: {
          callback: (value) => Math.abs(value), // + '%'

        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value) => `${Math.round(Math.abs(value))}%`,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';

            if (label && context.parsed.y !== null) {
              if (Math.round(Math.abs(context.parsed.x)) > 0) {
                label = Math.round(Math.abs(context.parsed.x)) + label;
              } else {
                label = '';
              }
            }
            return label;
          },
        },
      },
      legend: {
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
        },
        position: 'bottom',
        align: 'start', // align legend to the left so it lines up better with data shown above
      },
      // use the annotation plugin to draw a vertical line in the center of the graph
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            xMin: 0,
            xMax: 0,
            borderColor: '#333',
            label: {
              content: '',
              enabled: false,
            },
          },
        ],
      },
    },
  };

  return (
    <Box mb={20}>
      <Center>
        <Text textStyle="cardHeader">
          {title}
        </Text>
      </Center>
      <Center>
        <Bar data={data} options={options} width={width} height={height} />
      </Center>
    </Box>
  );
}

export default StackedTornadoChart;
