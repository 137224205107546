import { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import StackedLineChart from '../charts/StackedLineChart';

function SLStackedLineChart({
  queryInfo, title1, title2, labels, colours, unitprefix, unitpostfix, ...props
}) {
  const [chart, setChart] = useState({
    grouping: [],
    labels,
    datasets: [],
    colours,
    unitprefix,
    unitpostfix,
  });

  useEffect(() => {
    if (queryInfo?.data) {
      setChart({
        ...chart,
        grouping: queryInfo.data.years,
        datasets: queryInfo.data.values,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box>
      <Text fontSize={12} align="center" fontWeight="bold">{title1}</Text>
      <Text fontSize={12} align="center" mb={5} fontWeight="bold">{title2}</Text>
      <LockYearBadge lockYear={queryInfo?.data?.lockYear} />
      <Center>
        <StackedLineChart
          grouping={chart.grouping}
          labels={chart.labels}
          datasets={chart.datasets}
          colours={chart.colours}
          unitprefix={chart.unitprefix}
          unitpostfix={chart.unitpostfix}
          {...props}
        />
      </Center>
    </Box>
  );
}

export default SLStackedLineChart;
