// Colours used by map page and widgets on map page
const COLOURS = {
  LIGHTGREEN: 'green', // not used
  GREEN: '#5FAB12',
  YELLOW: '#F7C622',
  ORANGE: '#F66F22',
  RED: '#C01F24',
  GREY: '#CCCCCC',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  OCEANBLUE: '#cae8ff',
  DQCOLOR1: '#2e22ac',
  DQCOLOR2: '#5e2ea8',
  DQCOLOR3: '#9b3ca4',
  DQCOLOR4: '#df4a9c',
};

const HIGHLIGHTSHADES = {
  GREEN: '#7FBB41', // lighter by 2
  YELLOW: '#FADC7A', // lighter by 4
  ORANGE: '#F78B4E',
  RED: '#CC4B4F',
  GREY: COLOURS.GREY,
  DQCOLOR1: '#827acd',
  DQCOLOR2: '#9e82cb',
  DQCOLOR3: '#c38ac8',
  DQCOLOR4: '#ec92c4',
};

const BORDERHIGHLIGHTSHADES = {
  GREEN: '#42770C', // darker by 3
  YELLOW: '#AC8A17',
  ORANGE: '#AC4D17',
  RED: '#861519',
  GREY: '#7A7A7A',
  DQCOLOR1: '#1c1467',
  DQCOLOR2: '#381c65',
  DQCOLOR3: '#5d2462',
  DQCOLOR4: '#862c5e',
};

export {
  COLOURS,
  HIGHLIGHTSHADES,
  BORDERHIGHLIGHTSHADES,
};
