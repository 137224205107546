import { useState, useEffect } from 'react';
import { useCoInvestorAssuranceKPI } from '@src/modules/performance/context/map-api-hooks';
import TrafficLightWidget from './TrafficLightWidget';

import councildata from '../CouncilData';
import { mapCoassuranceToText, mapCoInvestorLabel } from '../shared/IndicatorText';

function CoInvestorIndicator({ selection, setSelection }) {
  const { data: coinvest } = useCoInvestorAssuranceKPI();
  const [colour, setColour] = useState('GREY');
  const [text, setText] = useState('');
  const [assessment, setAssessment] = useState('');

  useEffect(() => {
    if (coinvest) {
      const col = councildata.mapValueToColourRange(coinvest.score, councildata.colours.coassurance);
      setColour(col);
      if (coinvest.score > 0) {
        // also set the text on the indicator
        setText(`${mapCoInvestorLabel(coinvest.isTechnical)} ${coinvest.auditDate}`);
        setAssessment(mapCoassuranceToText(coinvest.score));
      } else {
        setText('Not available');
        setAssessment('');
      }
    }
  }, [coinvest]);

  return (
    <TrafficLightWidget
      title="Co-Investor Assurance"
      colour={colour}
      subtext1={assessment}
      subtext2={text}
      selected={selection === 'coassurance'}
      handleClick={() => {
        setSelection('coassurance');
      }}
    />
  );
}

export default CoInvestorIndicator;
