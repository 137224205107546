import { useEffect, useState } from 'react';
import Card from '@src/modules/performance/components/Card';
import { Text, Icon, Flex } from '@chakra-ui/react';
import { COLOURS } from '../shared/MapColours';

function TinyCircleIcon(props) {
  return (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
}

function MapLegend({ selection, filter, ...props }) {
  const [items, setItems] = useState([]);
  const [colours, setColours] = useState([]);

  useEffect(() => {
    switch (selection) {
      case 'safety':
        setItems([
          'Decreasing trend of 1% or greater',
          'Between a 1% decrease and 1% increase',
          'Increasing trend of 1% or greater',
        ]);
        setColours([
          'GREEN',
          'YELLOW',
          'RED',
        ]);
        break;
      case 'amenityCondition':
        setItems([
          'Increasing trend of 1% or greater',
          'Between a 1% decrease and 1% increase',
          'Decreasing trend of 1% or greater',
        ]);
        setColours([
          'GREEN',
          'YELLOW',
          'RED',
        ]);
        break;
      case 'activity':
        if (filter.activity === 'Overall') {
          setItems([
            'Good/Effective',
            'Fit for purpose/developing',
            'Room for Improvement',
            'Unacceptable/basic',
          ]);
          setColours([
            'GREEN',
            'YELLOW',
            'ORANGE',
            'RED',
          ]);
        } else if (filter.activity === 'Smart_buyer_selfassessment') {
          setItems([
            'A Smart Buyer',
            'Developing',
            'Limited',
            'Basic',
          ]);
          setColours([
            'GREEN',
            'YELLOW',
            'ORANGE',
            'RED',
          ]);
        } else {
          setItems([
            'Good',
            'Fit for purpose',
            'Room for Improvement',
            'Not assessed',
          ]);
          setColours([
            'GREEN',
            'YELLOW',
            'ORANGE',
            'GREY',
          ]);
        }
        break;
      case 'coassurance':
        setItems([
          'Effective',
          'Some improvement needed',
          'Significant improvement needed',
          'Unacceptable',
        ]);
        setColours([
          'GREEN',
          'YELLOW',
          'ORANGE',
          'RED',
        ]);
        break;
      case 'servicePerformance':
        setItems([
          'Target Achieved',
          'Partially Achieved',
          'Target Not Achieved',
          'Not Reported',
        ]);
        setColours([
          'GREEN',
          'ORANGE',
          'RED',
          'GREY',
        ]);
        break;
      case 'dataQuality':
        setItems([
          '80-100%',
          '70-79%',
          '50-69%',
          '0-49%',
        ]);
        setColours([
          'DQCOLOR4',
          'DQCOLOR3',
          'DQCOLOR2',
          'DQCOLOR1',
        ]);
        break;
      default:
        // nothing
        setColours([]);
        setItems([]);
        break;
    }
  }, [selection, filter]);

  return (
    <Card width="100%" padding={2} {...props}>
      <Flex direction="column">
        {items.map((item, index) => (
          <Flex key={`legend${index}`} align="center" mb={index >= items.length - 1 ? 0 : 2}>
            <TinyCircleIcon mt={0} color={COLOURS[colours[index]]} boxSize={6} />
            <Text ml={2} mb={0} fontSize={14}>{item}</Text>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}

export default MapLegend;
