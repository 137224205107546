import { useState, useEffect } from 'react';
import {
  Grid, GridItem, VStack, Box, Radio, RadioGroup, Stack, Flex, Text,
} from '@chakra-ui/react';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import { selectedLockYearState } from '@transport-insights/uikit';
import { first, round } from 'lodash';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import ApexCharts from 'apexcharts';
import { useAssetHistorical } from '../../context/levels-of-service-api-hooks';
import OnfBreakdown from './OnfBreadown';

function BarChart({ chartData }) {
  const lockYear = useRecoilValue(selectedLockYearState);

  return (
    <Chart
      options={{
        title: {
          text: `${lockYear} Condition`,
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          zoom: {
            enabled: false,
          },
        },
        colors: chartData.map((c) => c.color),
        legend: {
          show: false,
        },
        xaxis: {
          categories: chartData.map((c) => c.label),
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter: (val) => `${round(val, 2)}%`,
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: () => null,
            },
          },
          marker: {
            show: false,
          },
        },
      }}
      series={[{ data: chartData.map((c) => c.value) }]}
      type="bar"
      height={350}
    />
  );
}

function HistoricalLineChart({ asset, summarize }) {
  const { isLoading, data } = useAssetHistorical({
    table: asset.table,
    subType: asset.subType,
    summarize,
  });
  const categories = first(data)?.chartData?.map((c) => c.label) ?? [];
  const colors = first(data)?.chartData?.map((c) => c.color) ?? [];
  const series = categories.map((c) => ({
    name: c,
    data: data.map((d) => d.chartData.find((cd) => cd.label === c)?.value ?? 0),
  }));
  const [selectedToggle, setSelectedToggle] = useState('Not Specified');
  const showCustomToggle = series.length === 3;

  const filters = [
    { label: 'Good', value: 'Good', color: colors[0] },
    { label: 'Poor', value: 'Poor', color: colors[1] },
    { label: 'Both', value: 'Not Specified', color: colors[2] },
  ];

  // Custom function for custom legend toggles
  const toggleSeries = (val) => {
    setSelectedToggle(val);
    const hidden = filters.filter((x) => x.label !== val).map((x) => x.value);
    if (val === 'Not Specified') {
      filters.forEach((x) => ApexCharts.exec('chart', 'showSeries', x.value));
    } else {
      ApexCharts.exec('chart', 'showSeries', val);
      hidden.forEach((x) => ApexCharts.exec('chart', 'hideSeries', x));
    }
  };

  // We need to reset the chart with new data
  // in order to reset the legend toggle
  useEffect(() => {
    ApexCharts.exec('chart', 'resetSeries');
    if (showCustomToggle) {
      toggleSeries(selectedToggle);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Chart
        options={{
          ...{
            chart: {
              id: 'chart',
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: !showCustomToggle,
              },
              onItemHover: {
                highlightDataSeries: false,
              },
            },
            stroke: {
              curve: 'smooth',
            },
            title: {
              text: 'Condition Trend',
            },
            colors,
            fill: {
              colors: ['#CCC'],
            },
            yaxis: {
            // Allow y-axis to scale dynamically
            // uncomment this out if we want a strict 0 - 100 scale
            // which seems reasonable to me as it is a percentage
            // min: 0,
            // max: 100,
              labels: {
                formatter: (val) => `${round(val, 2)}%`,
              },
            },
          },
          xaxis: {
            categories: data?.map((d) => d.label),
          },
        }}
        type="line"
        series={series}
        height={showCustomToggle ? 325 : 350}
      />
      {showCustomToggle
      && (
      <Flex align="center" justify="center">
        <Stack direction="row">
          <Text>Showing: </Text>
          <RadioGroup onChange={(v) => toggleSeries(v)} defaultValue="Not Specified" value={selectedToggle}>
            <Stack direction="row">
              {filters.map((f) => (
                <Radio
                  key={f.value}
                  value={f.value}
                  borderColor={f.color}
                  _checked={{
                    bg: 'white',
                    borderColor: f.color,
                    borderWidth: '4px',
                  }}
                >
                  { f.label }
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Stack>
      </Flex>
      )}
    </Box>
  );
}

function AssetInspector({ asset, summarize }) {
  return (
    <VStack align="stretch">
      <Grid templateColumns="repeat(6, 1fr)" gap={2}>
        <GridItem colSpan={2}>
          <ChartContainer h="400px">
            <BarChart chartData={asset?.chartData || []} />
          </ChartContainer>
        </GridItem>
        <GridItem colSpan={4}>
          <ChartContainer flex={4} h="400px">
            <HistoricalLineChart asset={asset} summarize={summarize} />
          </ChartContainer>
        </GridItem>
      </Grid>
      <OnfBreakdown asset={asset} summarize={summarize} />
    </VStack>
  );
}

export default AssetInspector;
