import {
  Flex,
  Heading,
  Divider,
} from '@chakra-ui/react';

export default function ReportSubheading({ children }) {
  return (
    <Flex my={4} alignItems="center">
      <Heading as="h2" fontSize="2xl" whiteSpace="nowrap" pr="4">
        {children}
      </Heading>
      <Divider borderColor="gray.300" borderWidth={1} />
    </Flex>
  );
}
