import {
  Box, Button, HStack, Spinner, VStack, Spacer, Icon,
} from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi';
import { useJob } from '@src/modules/tce/src/context/jobs';
import PageSize from '@tce/components/pages/traffic-count-estimates/components/wizard/PageSize';
import Pagination from '@tce/components/pages/traffic-count-estimates/components/wizard/Pagination';
import {
  agOnCellFocused,
} from '@tce/components/pages/traffic-count-estimates/components/wizard/table';
import { useRoadSections, useUpdateRoadSectionMutation } from '@tce/context/roadsections';
import {
  currentPageState,
  numTotalRowsFilteredState,
  numTotalRowsSelectedState,
  pageSizeState,
  tceWizardFilterSelector,
  tceWizardInvalidDataEntryState,
} from '@tce/state/tce';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import debouncePromise from 'awesome-debounce-promise';
import { assign, isEqual } from 'lodash';
import {
  Children, cloneElement, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import CsvDownloader from 'react-csv-downloader';
import { useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useTceApi from '@src/modules/tce/src/api/useTceApi';

function TceGrid({
  columnDefs,
  isLoading = false,
  children,
  selectAll = false,
  name = 'TceGrid',
  hasDownload = true,
}) {
  const gridRef = useRef();
  const api = useTceApi();
  const { job } = useJob();
  const [pageModified, setPageModified] = useState(false);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
  const [totalRowsFiltered, setTotalRowsFiltered] = useRecoilState(numTotalRowsFilteredState);
  const setTotalRowsSelected = useSetRecoilState(numTotalRowsSelectedState);
  const [firstDisplayedRow, setFirstDisplayedRow] = useState(0);
  const pageSize = useRecoilValue(pageSizeState);
  const roadFilter = useRecoilValue(tceWizardFilterSelector(name));
  const [currentRoadFilter, setCurrentRoadFilter] = useState(null);
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const roadSections = [];
  const roadSectionsRef = useRef(roadSections);
  const [startRow, setStartRow] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const {
    roadSections: roadSectionData, isLoading: isRoadSectionsLoading,
  } = useRoadSections(roadFilter, startRow, numberOfRows);
  const roadSectionDataRef = useRef();
  const [gridParams, setGridParams] = useState();
  const queryClient = useQueryClient();
  const [tceWizardInvalidDataEntry, setTceWizardInvalidDataEntry] = useRecoilState(tceWizardInvalidDataEntryState);

  useEffect(() => {
    roadSectionDataRef.current = roadSectionData;
  }, [roadSectionData]);

  useEffect(() => {
    if (!isEqual(roadFilter, currentRoadFilter)) {
      setStartRow(0);
      setCurrentPage(0);
      gridRef?.current?.api?.refreshInfiniteCache();
      gridRef?.current?.api?.paginationGoToPage(0);
      // Invalidate Query
      queryClient.invalidateQueries(roadFilter);
      setCurrentRoadFilter(roadFilter);
    }
  }, [currentRoadFilter, queryClient, roadFilter, setCurrentPage]);

  useEffect(() => {
    setFirstDisplayedRow(gridRef?.current?.api?.getFirstDisplayedRow());
  }, [currentPage]);

  const selectDeselectAllRowsInPage = (selectAllRows) => {
    const gridApi = gridRef?.current?.api;
    if (!gridApi) return;

    // Deselect previously selected rows to reset selection
    gridApi.deselectAll();

    // Initialize pagination data
    const paginationSize = gridApi.paginationGetPageSize();
    const currentPageNum = gridApi.paginationGetCurrentPage();
    const totalRowsCount = gridApi.getDisplayedRowCount();

    // Calculate current page row indexes
    const currentPageRowStartIndex = (currentPageNum * paginationSize);
    let currentPageRowLastIndex = (currentPageRowStartIndex + paginationSize);
    if (currentPageRowLastIndex > totalRowsCount) currentPageRowLastIndex = (totalRowsCount);

    for (let i = currentPageRowStartIndex; i < currentPageRowLastIndex; i++) {
      const node = gridApi.getDisplayedRowAtIndex(i);
      node.setSelected(selectAllRows);
    }
  };

  // Select or Deselect all rows
  useEffect(() => {
    selectDeselectAllRowsInPage(selectAll);
  }, [selectAll]);

  // Change the page size of the grid
  useEffect(() => {
    const currentPageBeforeChange = gridRef.current?.api?.paginationGetCurrentPage();
    const currentPageSize = gridRef.current?.api?.paginationGetPageSize();
    if (currentPageBeforeChange === undefined
      || currentPageSize === undefined || currentPageSize === pageSize) return;
    const firstRow = currentPageSize * currentPageBeforeChange;
    gridRef.current?.api?.paginationSetPageSize(Number(pageSize));
    const newPage = parseInt(firstRow / pageSize, 10);
    gridRef.current?.api?.paginationGoToPage(newPage);
    setCurrentPage(newPage);
  }, [currentPage, pageSize, setCurrentPage]);

  // Sort the data using the grid's sort functions
  const sortData = (sortModel, data) => {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    // do an in memory sort of the data, across all the fields
    const resultOfSort = data.slice();
    resultOfSort.sort((a, b) => {
      for (let k = 0; k < sortModel.length; k += 1) {
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        // this filter didn't find a difference, move onto the next one
        if (valueA === valueB) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        }
        return sortDirection * -1;
      }
      // no filters found a difference
      return 0;
    });
    return resultOfSort;
  };

  useEffect(() => {
    if (roadSectionData) {
      const params = gridParams;
      const data = roadSectionData;
      if (data && params) {
        setTceWizardInvalidDataEntry(null);
        roadSectionsRef.current = data.roadSections;
        setTotalRowsFiltered(data.pagination.totalRows);
        const { roadSections: selectedRoadSections } = data;
        const sortedData = sortData(params?.sortModel, selectedRoadSections);
        // Pass the data to the grid
        params.successCallback(sortedData, false);
      }
    }
  }, [gridParams, roadSectionData, setTceWizardInvalidDataEntry, setTotalRowsFiltered]);

  const getRows = async (params) => {
    const { startRow: firstRow, endRow } = params;
    setStartRow(firstRow);
    setNumberOfRows(endRow - firstRow);
    setGridParams(params);
  };

  const dataSource = useMemo(() => ({
    rowCount: undefined,
    getRows: async (params) => getRows(params),
  }), []);

  /**
   * Give the grid the data
   * @param e
   */
  const onGridReady = (e) => {
    gridRef.current?.api.paginationSetPageSize(pageSize);
    e.api.setDatasource(dataSource);
  };

  // Go to next page
  const onNext = () => {
    const lastPage = gridRef.current.api.paginationGetTotalPages();
    if (lastPage !== null && lastPage === currentPage) {
      return;
    }
    if (pageModified) {
      queryClient.invalidateQueries(roadFilter);
      gridRef.current.api.setFocusedCell(0, 'roadId');
      setPageModified(false);
    } else {
      setCurrentPage(currentPage + 1);
      gridRef?.current?.api.paginationGoToNextPage();
      setFirstDisplayedRow(gridRef.current.api.getFirstDisplayedRow());
    }
  };

  // Go to previous page
  const onPrevious = () => {
    if (currentPage !== 0) setCurrentPage(currentPage - 1);
    gridRef?.current?.api.paginationGoToPreviousPage();
    setFirstDisplayedRow(gridRef.current.api.getFirstDisplayedRow());
  };

  const editableFields = [
    'pcCar', 'pcLcv', 'pcMcv', 'pcHcvI', 'pcHcvII', 'pcBus', 'aadtEstimate',
  ];
  const {
    mutate: saveRoadSection,
  } = useUpdateRoadSectionMutation({
    onSuccess: (savedRoadSection) => {
      // Update the data of the grid with the data received.
      // This is more efficient than invalidating the query for the whole grid
      const roadSection = roadSectionsRef.current.find((s) => s.id === savedRoadSection.id);
      if (roadSection) {
        // check all field, roadsection should be most up to date,
        // it has the changes the user has made
        // eslint-disable-next-line no-return-assign
        editableFields.forEach((key) => {
          if (savedRoadSection[key] !== roadSection[key]) {
            console.log(`TceGrid.jsx::() => key: ${key}; ${savedRoadSection[key]} <=> ${roadSection[key]}`);
            savedRoadSection[key] = roadSection[key];
          }
        });
        savedRoadSection.reviewTrafficMix = true;
        savedRoadSection.updateAadtEstimate = true;
        // copy the data from the updated road section to the one in the grid
        assign(roadSection, savedRoadSection);
        // set the saved flag
        roadSection.saved = new Date();
        roadSection.backendUpdate = null;
        // refresh the screen
        gridRef?.current?.api?.refreshCells();
      }
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onSelectionChanged = (event) => {
    // Keep track on the number of selected rows
    const rowCount = event.api.getSelectedNodes().length;
    setTotalRowsSelected(rowCount);
  };

  // Save all changed road sections, use to save the data from editable columns
  const saveRoadSections = async () => {
    // Find updated sections
    //  Filter changed timestamp has been set and not backendUpdate (indictating update in progress)
    const updatedSections = roadSectionsRef.current
      .filter((r) => !!r?.changed && !(r?.backendUpdate));
    // Send updated sections to backend
    updatedSections.forEach((s) => {
      // remove extra properties
      // eslint-disable-next-line no-unused-vars
      const { changed, saved, ...roadSection } = s;
      s.changed = null;
      // keep track off outstanding requests
      s.backendUpdate = new Date();
      setPageModified(true);
      saveRoadSection(roadSection, {
        onSettled: () => {
          // call this function again to check if there are any updates to be processed
          saveRoadSections();
        },
      });
    });
  };

  // Debounce the save of editable columns
  const saveRoadSectionsDebounced = debouncePromise(saveRoadSections, 200);
  /**
     * Flag the roadsection as being changed so that it can be sent to the backend later
     * @type {(function(*): void)|*}
     */
  const onCellValueChanged = useCallback((event) => {
    if (tceWizardInvalidDataEntry === null && event?.oldValue !== event?.newValue) {
      event.data.changed = new Date();
      event.data.saved = null;
      saveRoadSectionsDebounced();
    }
  }, [saveRoadSectionsDebounced, tceWizardInvalidDataEntry]);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.rowModel.cacheParams.blockSize = pageSize;
      gridRef.current.api.purgeInfiniteCache();
    }
  }, [pageSize]);

  useEffect(() => {
    if (isRoadSectionsLoading) {
      gridRef?.current?.api?.showLoadingOverlay();
    } else {
      gridRef?.current?.api?.hideOverlay();
    }
  }, [isRoadSectionsLoading]);

  const arrayChildren = Children.toArray(children);

  if (isLoading) return <Spinner />;

  const allColumns = gridRef?.current?.columnApi?.getAllGridColumns() ?? [];
  const csvColumns = allColumns
    .filter((c) => c.visible)
    .filter(({ colDef }) => colDef.headerName)
    .map(({ colDef }) => ({
      id: colDef.field,
      displayName: colDef.headerName,
    }));

  const getDisplayedRoadSections = async () => {
    setIsDownloadingCsv(true);
    const { roadSections: allDisplayedSections } = await api.getRoadSections(job.id, 0, 99999, roadFilter);
    setIsDownloadingCsv(false);
    return allDisplayedSections;
  };

  return (
    <VStack align="stretch">
      {gridRef?.current && (
        <VStack w="100%">
          {hasDownload && (
          <HStack w="100%" mt={-12} mb={1}>
            <Spacer />
            <CsvDownloader
              filename={`${job.rcaId}-${job.importId}-${job.lockYear.replace('/', '-')}`}
              extension=".csv"
              separator=","
              columns={csvColumns}
              datas={getDisplayedRoadSections}
            >
              <Button colorScheme="gray" isLoading={isDownloadingCsv}>
                <Icon boxSize="4" as={FiDownload} mr={2} />
                Download CSV
              </Button>
            </CsvDownloader>
          </HStack>
          )}
          <HStack w="100%">
            <PageSize />
            <Spacer />
            <Pagination
              currentPage={currentPage}
              firstDisplayedRow={firstDisplayedRow}
              totalRows={totalRowsFiltered}
              onNext={onNext}
              onPrevious={onPrevious}
              gridRef={gridRef}
            />
          </HStack>
        </VStack>
      )}

      {
          // This construct is used to pass the gridRef to the children
          Children.map(arrayChildren, (child) => (
            <>
              {cloneElement(child, { gridRef })}
            </>
          ))
        }
      <Box
        w="full"
        className="ag-theme-alpine"
        style={{
          // This is really gross but ag-grid sucks it will only accept either a fixed height
          // e.g. 500px or a percentage of the screen height.  So we have to calculate the
          // height of the screen and subtract the height of the header and footer.
          height: 'calc(100vh - 550px)',
        }}
      >
        <AgGridReact
          loadingOverlayComponent={Spinner}
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={{
            resizable: true,
            sortable: true,
          }}
          onCellFocused={agOnCellFocused}
          pagination
          onGridReady={onGridReady}
          suppressPaginationPanel
          rowModelType="infinite"
          cacheBlockSize={pageSize}
          rowSelection="multiple"
          suppressRowClickSelection
          paginationAutoPageSize={false}
          onSelectionChanged={onSelectionChanged}
          animateRows={false}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </VStack>
  );
}
export default TceGrid;
