import { Button, Link, Spinner } from '@chakra-ui/react';
import { useUserInfo } from '@transport-insights/uikit';
import {
  useCreateJobMutation,
} from '@src/modules/tce/src/context/jobs';
import { selectedImportIdState, selectedJobIdState } from '@src/modules/tce/src/state/tce';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export default function EstimateAction({ imp }) {
  const estimate = imp?.job;
  const history = useHistory();
  const [, setSelectedJobId] = useRecoilState(selectedJobIdState);
  const [, setSelectedImportId] = useRecoilState(selectedImportIdState);
  const { mutate: createJob, isLoading: isCreateJobLoading, isSuccess: isCreateJobSuccess } = useCreateJobMutation({
  });
  const {
    isLoading: isUserLoading, userId, email, firstName,
  } = useUserInfo();

  const selectTheJob = (id, destination) => {
    setSelectedJobId(id);
    setSelectedImportId(imp.id);
    history.push(`/tce/${destination}/${id}`);
  };

  const renderAction = () => {
    if (isUserLoading || isCreateJobLoading) {
      return <Spinner />;
    }

    if (!estimate) {
      // this import has no job, so check the status of the import, and show the create new job link
      if (imp?.status === 'Completed' && !isCreateJobSuccess) {
        return (
          <Button
            mt={4}
            size="sm"
            onClick={() => {
              createJob({
                importId: imp.id,
                lockYear: imp.lockYear,
                userId,
                email,
                username: firstName,
              });
            }}
          >
            Calculate Estimates
          </Button>
        );
      }
      // the import has errored or is still in progress - no action
      return ('');
    }

    // we have a job - this one has results
    if (estimate.status === 'COMPLETED' || estimate.status === 'RESULTS') {
      return <Link onClick={() => { selectTheJob(estimate.id, 'estimates'); }}>View Results</Link>;
    }

    if (estimate.status === 'READY') {
      // we have a job - and it is ready, so the user can go ahead and edit/create it
      return (<Link onClick={() => { selectTheJob(estimate.id, 'estimates'); }}>Edit Estimates</Link>);
    }

    if (estimate.status === 'CALCULATING RATES' || estimate.status === 'CALCULATING ESTIMATES') {
      return (<Link onClick={() => { selectTheJob(estimate.id, 'estimates'); }}>Check Progress</Link>);
    }

    if (estimate.status === 'FAILED') {
      // something went wrong with the job, no action to be taken.
      return '';
    }

    // in all other cases, we show the spinner
    // if (estimate.status === 'NEW' || estimate.status === 'IN PROGRESS' || estimate.status === 'COPYING' || estimate.status === 'PREPARING')
    return <Spinner />;
  };

  return renderAction();
}
