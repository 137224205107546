import { Skeleton } from '@chakra-ui/react';

function PlaceHolder(props) {
  const { children, ...rest } = props;
  return (
    <Skeleton fadeDuration={0} speed={0} startColor="#efefef" endColor="#efefef" {...rest}>
      {children}
    </Skeleton>
  );
}

export default PlaceHolder;
