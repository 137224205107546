import {
  Button, Modal, ModalBody,
  ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import { useSettingsApi } from '@src/modules/settings/api/settings';
import { useNews } from '@src/modules/settings/context/settings-api-hooks';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import './newsModalStyles.css';

export default function NewsModal({ children }) {
  const api = useSettingsApi();
  const { data: news } = useNews();
  const [selectedIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const onClose = async () => {
    setIsOpen(false);
  };

  const onConfirm = async () => {
    setIsOpen(false);
    await api.setNewsViewed();
  };

  useEffect(() => {
    // use news will only return news if there's something new that the user hasn't seen
    // therefore if we have news - open the news modal
    setIsOpen(!isEmpty(news));
  }, [news]);

  return (
    <>
      {children}
      {!isEmpty(news) && (
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottomColor="gray.100"
            borderBottomWidth={1}
            boxShadow="sm"
          >
            {news[selectedIndex].title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            className="news-modal-body"
            dangerouslySetInnerHTML={{
              __html: news[selectedIndex].message,
            }}
          />
          <ModalFooter
            w="100%"
            align="flex-end"
            borderTopColor="gray.100"
            borderTopWidth={1}
          >
            <Button onClick={onConfirm}>
              Got it!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      )}
    </>
  );
}
