import { atom, atomFamily, selectorFamily } from 'recoil';

const pageSizeState = atom({
  key: 'pageSizeState',
  default: 250,
});

const currentPageState = atom({
  key: 'currentPage',
  default: 0,
});

const numTotalRowsSelectedState = atom({
  key: 'grid/numTotalRowsSelected',
  default: 0,
});

const numTotalRowsFilteredState = atom({
  key: 'grid/numTotalRowsFiltered',
  default: 0,
});

const roadSectionParametersState = atom({
  key: 'roadSectionParametersState',
  default: {
    startRow: -1,
    endRow: -1,
    roadName: null,
  },
});

const selectedImportIdState = atom({
  key: 'selectedImportIdState',
  default: -1,
});

const selectedJobIdState = atom({
  key: 'selectedJobIdState',
  default: null,
});

const tceWizardFilterRoadState = atomFamily({
  key: 'tceWizardFilter/road',
  default: null,
});
const tceWizardFilterTrafficGroupState = atomFamily({
  key: 'tceWizardFilter/trafficGroup',
  default: null,
});
const tceWizardFilterOnrcCategoryState = atomFamily({
  key: 'tceWizardFilter/onrcCategory',
  default: null,
});
const tceWizardFilterCwayHierarchyState = atomFamily({
  key: 'tceWizardFilter/cwayHierarchy',
  default: null,
});
const tceWizardFilterUrbanRuralState = atomFamily({
  key: 'tceWizardFilter/urbanRural',
  default: null,
});

const tceWizardFilterOnlyMissingAADT = atomFamily({
  key: 'tceWizardFilter/onlyMissingAADT',
  default: false,
});

const tceWizardFilterOnlyMissingTrafficGroup = atomFamily({
  key: 'tceWizardFilter/onlyMissingTrafficGroup',
  default: false,
});

const tceWizardFilterOnlyInconsistentTrafficMix = atomFamily({
  key: 'tceWizardFilter/OnlyInconsistentTrafficMix',
  default: false,
});

const tceWizardFilterOnlyRoadSectionsWithCounts = atomFamily({
  key: 'tceWizardFilter/OnlyRoadSectionsWithCounts',
  default: false,
});

const tceWizardFilterOnlySignificantChanges = atomFamily({
  key: 'tceWizardFilter/OnlySignificantChanges',
  default: false,
});

const tceWizardInvalidDataEntryState = atom({
  key: 'tceWizardInvalidDataEntry',
  default: null,
});

const tceWizardFilterSelector = selectorFamily({
  key: 'tceWizardFilter/get',
  get: (name) => ({ get }) => ({
    roadName: get(tceWizardFilterRoadState(name)),
    trafficGroupId: get(tceWizardFilterTrafficGroupState(name)),
    categoryId: get(tceWizardFilterOnrcCategoryState(name)),
    hierarchy: get(tceWizardFilterCwayHierarchyState(name)),
    urbanRural: get(tceWizardFilterUrbanRuralState(name)),
    onlyMissingAADT: get(tceWizardFilterOnlyMissingAADT(name)),
    onlyMissingTrafficGroups: get(tceWizardFilterOnlyMissingTrafficGroup(name)),
    onlyInconsistentTrafficMix: get(tceWizardFilterOnlyInconsistentTrafficMix(name)),
    onlyRoadSectionsWithCounts: get(tceWizardFilterOnlyRoadSectionsWithCounts(name)),
    onlySignificantCountChanges: get(tceWizardFilterOnlySignificantChanges(name)),
  }),
  // this performs a reset - bit hacky
  set: (name) => ({ set }) => {
    set(tceWizardFilterRoadState(name), null);
    set(tceWizardFilterTrafficGroupState(name), null);
    set(tceWizardFilterOnrcCategoryState(name), null);
    set(tceWizardFilterCwayHierarchyState(name), null);
    set(tceWizardFilterUrbanRuralState(name), null);
    set(tceWizardFilterOnlyMissingAADT(name), name === 'step2');
    set(tceWizardFilterOnlyMissingTrafficGroup(name), name === 'step3');
    set(tceWizardFilterOnlyInconsistentTrafficMix(name), name === 'step1');
    set(tceWizardFilterOnlySignificantChanges(name), false);
  },
});

export {
  currentPageState,
  numTotalRowsFilteredState,
  numTotalRowsSelectedState,
  pageSizeState,
  roadSectionParametersState,
  selectedImportIdState,
  selectedJobIdState,
  tceWizardFilterCwayHierarchyState,
  tceWizardFilterUrbanRuralState,
  tceWizardFilterOnrcCategoryState,
  tceWizardFilterRoadState,
  tceWizardFilterSelector,
  tceWizardFilterTrafficGroupState,
  tceWizardFilterOnlyMissingAADT,
  tceWizardFilterOnlyMissingTrafficGroup,
  tceWizardFilterOnlyInconsistentTrafficMix,
  tceWizardFilterOnlyRoadSectionsWithCounts,
  tceWizardFilterOnlySignificantChanges,
  tceWizardInvalidDataEntryState,
};
