import { useEffect, useState } from 'react';
import {
  Icon, Flex, Text, Box, Center,
} from '@chakra-ui/react';
import {
  TrafficLightColours,
  ChartColours,
} from '../charts/ChartColours';

function TinyCircleIcon(props) {
  return (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
}

// this is an interactive version of the legend component
// duplicates functionality from built in chart legend
// user can click on legend labels to toggle display of data in a given chart

// vertical and chartColours are booleans
function InteractiveLegend({
  values, colours, vertical, chartColours, title, chartRef, ...props
}) {
  const [visible, setVisible] = useState([]);

  useEffect(() => {
    const r = [];
    values.forEach(() => {
      r.push(true);
    });
    setVisible(r);
  }, [values]);

  // when a user clicks on a legend label, update chart and label accordingly
  const handleClick = (index) => {
    if (chartRef && chartRef.current) {
      chartRef.current.setDatasetVisibility(index, !chartRef.current.isDatasetVisible(index));
      // have to update the chart to see changes
      chartRef.current.update();

      // display legend label with strikethrough
      const clone = [...visible];
      clone[index] = chartRef.current.isDatasetVisible(index);
      setVisible(clone);
    }
  };

  return (
    <Box {...props}>
      {title && (<Text fontSize={12} fontWeight="bold" mb={5}>{title}</Text>)}
      <Center>
        <Flex flexDirection={vertical ? 'column' : 'row'}>
          {values.map((text, index) => (
            <Flex key={`legend${index}`} onClick={() => { handleClick(index); }} style={{ cursor: 'pointer' }}>
              <TinyCircleIcon mt={0} mr={3} color={chartColours ? ChartColours[colours[index]] : TrafficLightColours[colours[index]]} size={32} />
              <Text mr={10} fontSize={12} style={visible[index] ? {} : { textDecoration: 'line-through 2px' }}>{text}</Text>
            </Flex>
          ))}
        </Flex>
      </Center>
    </Box>
  );
}

export default InteractiveLegend;
