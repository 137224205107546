import { Container } from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';

export default function PageLoader({ isLoading = false, children }) {
  if (!isLoading) return children;

  return (
    <MainLayout>
      <Container maxW="full">
        <LoadingSpinner />
      </Container>
    </MainLayout>
  );
}
