import { ConfigProvider } from '@src/context/config';

/** @typedef {import('@src/context/config').ConfigDefinition} ConfigDefinition */

/**
 * @returns {ConfigDefinition}
 */
function readConfigFromEnvironment() {
  // NOTE: If you get a "ReferenceError: process is not defined" on runtime, it means
  // that the environment variable has not been defined. Webpack, through the configured
  // EnvironmentPlugin, replaces these with string constants in the generated bundler.
  // If environment variable is not defined, then it is not replaced, and line left as-is,
  // meaning that browser complains about unknown "process" object!
  //
  // Make sure to update 'serverless.yml' to define the new environment variable.
  // Then you will need to restart 'npm start', because environment variables are loadded
  // before webpack starts, so webpack hot-reload feature is not applicable here.

  const stage = process.env.REACT_APP_STAGE || 'dev';
  const isNonProd = stage !== 'prod';
  const isDev = process.env.REACT_APP_STAGE === 'local' || process.env.REACT_APP_STAGE === 'dev';

  return {
    LEGACY_PMRT_URL: process.env.REACT_APP_LEGACY_PMRT_URL,
    PORTAL_NAV_API_URL: process.env.REACT_APP_PORTAL_NAV_API_URL,
    SETTINGS_API_URL: process.env.REACT_APP_SETTINGS_API_URL,
    TCE_API_URL: process.env.REACT_APP_TCE_API_URL,
    ONF_API_URL: process.env.REACT_APP_ONF_API_URL,
    PERFORMANCE_API_URL: process.env.REACT_APP_PERFORMANCE_API_URL,
    GOOGLE_API_KEY: process.env.REACT_APP_STAGE !== 'local' ? process.env.REACT_APP_GOOGLE_API_KEY : process.env.REACT_APP_GOOGLE_API_KEY_LOCAL,
    GOOGLE_ANALYTICS_MEASUREMENT_ID: isNonProd ? process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID_NON_PROD : process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID_PROD,
    stage,
    isNonProd,
    isDev,
  };
}

export const authConfig = {
  auth: {
    // This comes from the b2c setup in azure
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    knownAuthorities: (process.env.REACT_APP_B2C_KNOWN_AUTHORITIES).split(','),
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_PATH,
    postLogoutRedirectUri: `${process.env.REACT_APP_LEGACY_PMRT_URL}/Home/LogOut`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
};
export default function ConfigBehaviour({ children }) {
  const config = readConfigFromEnvironment();

  // Make sure they are all empty strings, if not defined
  Object.keys(config).forEach((key) => { config[key] = config[key] || ''; });

  return (
    <ConfigProvider config={config}>
      { children }
    </ConfigProvider>
  );
}
