import {
  Button,
  HStack,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import { useIsUserInRole, selectedDatabaseIdState } from '@transport-insights/uikit';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import TtmResults from './TtmResults';
import TtmReporting from './TtmReporting';

const tabStyle = {
  backgroundColor: 'transparent',
  borderBottom: '2px solid',
  borderColor: 'transparent',
  color: 'gray.600',
  marginBottom: '-2px!important',
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 3,
  paddingRight: 3,
  height: 'auto',
  fontSize: 'sm',
  lineHeight: '1.5',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 0,
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    background: 'gray.100',
  },
};

const activeTabStyle = {
  ...tabStyle,
  borderColor: 'brand.orange.500',
  color: 'gray.800',
  outline: 'none',
  _hover: {
    background: 'transparent',
    cursor: 'default',
  },
};

export default function TemporaryTrafficManagement() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const hasDeveloperRole = useIsUserInRole('Developer');
  const hasTtmAdminRole = useIsUserInRole('TTMAdmin');
  const hasRcaUserRole = useIsUserInRole(`db:${rcaId}:User`);
  const hasRcaAdminRole = useIsUserInRole(`db:${rcaId}:Admin`);
  const hasRcaRole = hasRcaUserRole || hasRcaAdminRole;

  const [tabView, setTabView] = useState('Results');

  useEffect(() => {
    setTabView('Results');
  }, [rcaId]);

  // If the user has the Developer role or
  // has an RCA role as well as TTMAdmin we let them choose which page to view
  if (hasDeveloperRole || (hasRcaRole && hasTtmAdminRole)) {
    return (
      <MainLayout>
        <HStack
          mx={4}
          mb={6}
          borderBottom="2px solid"
          borderColor="gray.100"
          spacing={2}
        >
          <Button
            onClick={() => setTabView('Results')}
            variant="unstyled"
            sx={tabView === 'Results' ? activeTabStyle : tabStyle}
          >
            RCA Results Entry
          </Button>
          <Button
            onClick={() => setTabView('Reporting')}
            variant="unstyled"
            sx={tabView === 'Reporting' ? activeTabStyle : tabStyle}
          >
            Admin Reporting
          </Button>
        </HStack>
        {tabView === 'Results' && (
          <TtmResults />
        )}
        {tabView === 'Reporting' && (
          <TtmReporting />
        )}
      </MainLayout>
    );
  }

  // If the user has the TTM Reporting role, show the TTM Reporting page
  if (hasTtmAdminRole && !hasDeveloperRole) {
    return (
      <MainLayout>
        <TtmReporting />
      </MainLayout>
    );
  }

  // If no roles are found, show results page
  // this will handle authentication errors
  return (
    <MainLayout>
      <TtmResults />
    </MainLayout>
  );
}
