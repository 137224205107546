import { useRef, useState } from 'react';
import {
  Box,
  Tooltip,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Heading,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FiMoreHorizontal, FiDownload, FiExternalLink } from 'react-icons/fi';
import useTceApi from '../../api/useTceApi';

const faqList = [
  {
    question: 'What does Traffic Count Estimation (TCE) calculate?',
    answer: 'TCE calculates new Average Daily Traffic (ADT) estimates and traffic loading (a.k.a. traffic mix) for all locally owned road sections in your network using the traffic counts from the last six years and the current estimates.',
  },
  {
    question: 'Do I need to run an Import From RAMM before running TCE, every time I make changes in RAMM?',
    answer: 'If you have made changes in RAMM that will affect the TCE calculation, you will need to run a new Import from RAMM before running TCE. These changes include: updating counts, adding missing estimates, setting the confidence on counts to zero, adding or updating records in the TCE Traffic Groups UDT. TCE only uses the data that comes in the import you have selected to run; TCE does not pull any data directly from RAMM during the calculation.',
  },
  {
    question: 'Do I have to finish one TCE run before I can start another run?',
    answer: 'You do not have to finish a TCE run for an import before you start a run for a different import. You may choose to never complete a run for an import. ',
  },
  {
    question: 'How do I exclude counts from the calculation?',
    answer: 'The calculation will automatically ignore any counts more than six years older than the year you are running the calculation for. To exclude counts that are within six years, set the confidence on the count to zero (0) in RAMM and reimport your data from RAMM.',
  },
  {
    question: 'Why does the traffic mix need to be all filled in on counts?',
    answer: 'TCE uses all counts in the last six years, not just latest counts. The mix on these counts is used to calculate the mix for the Traffic Groups so TCE relies on the count mix being correct. If any mix is provided on a count, all mix fields must be entered and must add up to 100%. However, TCE will ignore Volume Only counts when calculating the mix.',
  },
  {
    question: 'How are Volume Only counts used?',
    answer: 'TCE will use the count values from Volume Only counts to calculate the rates of growth and ADT on the road sections. TCE will ignore Volume Only counts when calulating traffic mix.',
  },
  {
    question: 'Why do I need to have an estimate preloaded for each road section before I can run TCE?',
    answer: 'TCE calculates the new estimate by applying the annual growth rate to the latest estimate on the road section, for all sections that do not have counts in the last six years.',
  },
  {
    question: 'What are the Traffic Groups used for?',
    answer: 'TCE calculates growth rate and average traffic mix for each Traffic Group using all counts within the road sections in each group. TCE then applies the calculated growth rates to the road sections in each Traffic Group that do not have three or more years of counts in the last six years. TCE also applies the calculated mix for each Traffic Group to the road sections in the group.',
  },
  {
    question: 'Can I assign road sections to Traffic Groups using a map?',
    answer: 'You can download the XML schema for a TCE Traffic Groups UDT in RAMM. You can use this UDT to assign road sections to the Traffic Groups, including via a map layer. The XML schema is available as a link from the TCE flyout.',
  },
  {
    question: 'What should I consider when assigning road sections to Traffic Groups?',
    answer: 'Assign road sections to the same group that are expected to have similar growth rates and traffic mix.',
  },
  {
    question: 'Which Traffic Groups should I use and can I change the names or set up my own?',
    answer: 'There is 36 static groups set up for you to use. You cannot change the names of the groups or add or remove groups. You can however choose to use whichever groups you wish for whatever purpose you wish. Some group names imply they should be used for specific purposes (e.g. Rural - Forestry High) but you can choose to use any of the groups that you wish.',
  },
  {
    question: 'What do the Derived From descriptions mean in the final results page?',
    answer: 'The Derived From descriptions indicate which method was used to calculate the ADT for the road section. A longer description of the Derived From values is in the Preparing For TCE instruction document.',
  },
];

function MoreDrawer({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const api = useTceApi();
  const [downloadError, setDownloadError] = useState(false);

  const handleDownloadFile = async (fileName, fileType) => {
    try {
      await api.downloadTceFile(fileName)
        .then((res) => {
          const file = new Blob(
            [res],
            { type: fileType },
          );
          const url = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        });
      setDownloadError(false);
    } catch (error) {
      console.log(error);
      setDownloadError(true);
    }
  };

  const handleExternalLink = (href) => {
    const a = document.createElement('a');
    a.href = href;
    a.setAttribute('target', '_blank');
    a.click();
  };

  return (
    <>
      <Tooltip label="More Information">
        <IconButton ref={btnRef} onClick={onOpen} icon={<FiMoreHorizontal />} borderRadius="full" colorScheme="gray" ml={3} />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            More Information
          </DrawerHeader>
          <DrawerBody>
            <Heading as="h3" fontSize="md" mb={6}>Downloads</Heading>
            <VStack align="flex-start" spacing={4}>
              <Button leftIcon={<FiDownload />} onClick={() => handleDownloadFile('Instructions for running Traffic Count Estimation.pdf', 'application/pdf')} variant="link">
                Instructions on running TCE.pdf
              </Button>
              <Button leftIcon={<FiDownload />} onClick={() => handleDownloadFile('Preparing for TCE.pdf', 'application/pdf')} variant="link">
                Instructions on preparing for TCE.pdf
              </Button>
              <Button leftIcon={<FiDownload />} onClick={() => handleDownloadFile('TCE Traffic Groups UDT.xml', 'txt/xml')} variant="link">
                TCE Traffic Groups UDT.xml
              </Button>
              <Button leftIcon={<FiExternalLink />} onClick={() => handleExternalLink('https://apopo.co.nz/product/traffic-monitoring-estimation-guidelines/')} variant="link">
                Traffic Monitoring Estimation Guidelines
              </Button>
            </VStack>
            {downloadError && <Text color="red" fontSize="sm">Sorry, there was an error downloading the file. Please try again later.</Text>}
            <Heading as="h3" fontSize="md" my={6}>Frequently Asked Questions</Heading>
            <Accordion allowToggle>
              {faqList.map((faq, idx) => (
                <AccordionItem key={idx} borderColor="gray.100">
                  <AccordionButton w="100%" justify="space-between" _expanded={{ bg: 'gray.50' }} outline="none">
                    <Box as="span" flex="1" textAlign="left" pr={4} color="gray.800">
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel bg="gray.50">
                    {faq.answer}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MoreDrawer;
