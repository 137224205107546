import { useEffect, useState } from 'react';
import {
  Tooltip, Box, Text, Flex,
} from '@chakra-ui/react';
import TrafficLight from '../indicators/TrafficLight';

function HeaderCoinvestorTrafficLight({
  queryInfo, size, mapText, mapColour, title, alttitle, isTechnical,
}) {
  const [colour, setColour] = useState('GREY');
  const [text, setText] = useState('No data');
  const [visible, setVisible] = useState(true);
  const [showAltTitle, setShowAltTitle] = useState(false);

  useEffect(() => {
    if (queryInfo?.data) {
      const { data } = queryInfo;
      setColour(mapColour(data.score));
      setText(mapText(data.score));
      // non technical indicator can hide itself
      if (!isTechnical) {
        setVisible(data.visible);
      }
      if (isTechnical) {
        // toggle the title if the result is not technical
        setShowAltTitle(!data.isTechnical);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!visible || !queryInfo?.data) {
    return null;
  }

  return (
    <Flex align="center">
      <Box mr={3}>
        <Tooltip hasArrow label={text} fontSize="12px">
          <TrafficLight size={size} colour={colour} strokeWidth="12px" stroke="white" />
        </Tooltip>
      </Box>
      <Box pr={15}>
        <Text fontWeight="bold" color="gray" whiteSpace="pre-line">
          {showAltTitle ? alttitle : title}
        </Text>
      </Box>
    </Flex>
  );
}

export default HeaderCoinvestorTrafficLight;
