import { Checkbox, HStack } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { tceWizardFilterOnlyMissingAADT } from '@tce/state/tce';

function Step2Filter({ name }) {
  const [onlyMissingAADT, setOnlyMissingAADT] = useRecoilState(
    tceWizardFilterOnlyMissingAADT(name),
  );

  const onChangeOnlyMissingAADT = () => setOnlyMissingAADT(!onlyMissingAADT);

  return (
    <HStack w="full">
      <Checkbox
        colorScheme="blue"
        isChecked={onlyMissingAADT}
        onChange={(e) => onChangeOnlyMissingAADT(e)}
      >
        Only show carriageways with zero or no AADT
      </Checkbox>
    </HStack>
  );
}

export {
  Step2Filter,
};
