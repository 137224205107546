import { Checkbox, HStack } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { tceWizardFilterOnlyMissingTrafficGroup } from '@tce/state/tce';

function Step3Filter({ name }) {
  const [onlyMissingTrafficGroup, setOnlyMissingTrafficGroup] = useRecoilState(tceWizardFilterOnlyMissingTrafficGroup(name));

  const onChangeOnlyMissingTrafficGroup = () => setOnlyMissingTrafficGroup(!onlyMissingTrafficGroup);

  return (
    <HStack w="full">
      <Checkbox
        colorScheme="blue"
        isChecked={onlyMissingTrafficGroup}
        onChange={(e) => onChangeOnlyMissingTrafficGroup(e)}
      >
        Only show Carriageways with no Traffic Group
      </Checkbox>
    </HStack>
  );
}

export {
  Step3Filter,
};
