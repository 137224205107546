import { useState, useEffect } from 'react';
import { useMapData } from '@src/modules/performance/context/map-api-hooks';
import TrafficLightWidget from './TrafficLightWidget';
import councildata from '../CouncilData';
import { mapActivityToText } from '../shared/IndicatorText';

const TITLE = 'Activity Management';

function ActivityManagementIndicator({
  selection, setSelection, filter, rcaId, filterLabel,
}) {
  const { data: activity, isLoading } = useMapData('activity', filter);
  const [colour, setColour] = useState('GREY');
  const [text, setText] = useState('');

  useEffect(() => {
    if (activity[rcaId] && Object.hasOwn(activity[rcaId], 'value') && activity[rcaId].value >= 0) {
      const col = councildata.calculateActivityColor(activity[rcaId].value, filter);
      setColour(col);
      // also set the text on the indicator
      setText(mapActivityToText(activity[rcaId].value, filter));
    } else {
      setColour('GREY');
      setText('');
    }
  }, [activity, isLoading, filter, rcaId]);

  return (
    <TrafficLightWidget
      title={TITLE}
      colour={colour}
      subtext1={text}
      subtext2={filterLabel}
      selected={selection === 'activity'}
      handleClick={() => {
        setSelection('activity');
      }}
    />
  );
}

export default ActivityManagementIndicator;
