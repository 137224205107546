import {
  Box,
  Divider,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import Card from '@src/modules/performance/components/Card';
import PlaceHolder from '@src/modules/performance/components/Placeholder';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useNetworkCharacteristics } from '@src/modules/performance/context/map-api-hooks';
import { calculateTotals } from '@src/modules/performance/shared/networkcharacteristics';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';

function NetworkCharacteristics() {
  const { data: networkCharacteristics, isLoading } = useNetworkCharacteristics('latest');
  const lockYear = networkCharacteristics?.[0]?.LockYear;
  const totals = calculateTotals(networkCharacteristics ?? []);

  return (
    <>
      <Flex justifyContent="space-between" align="center" py={4}>
        <Text fontWeight="bold" fontSize="sm">Network Lengths</Text>
        <LockYearBadge lockYear={lockYear} />
      </Flex>
      <Card width="100%" padding={3}>
        <Box width="100%">
          {isLoading
            && Array.from(Array(6), (e, i) => (
              <React.Fragment key={`kpi-${i}`}>
                <PlaceHolder height={5} width={120} mb={5} />
                <PlaceHolder height={5} width={120} mb={10} />
              </React.Fragment>
            ))}
          {!isLoading && (totals ?? []).map((item, index) => (
            <Box key={`kpi-${index}`}>
              <Text textStyle="summaryValue">{item.value}</Text>
              <Text textStyle="summaryTitle">{item.title}</Text>
              {index < totals.length - 1 && <Divider my={2} />}
            </Box>
          ))}
        </Box>
      </Card>
      {!isLoading && (
        <Box mt={4} textAlign="center">
          <Link
            as={ReactRouterLink}
            to="/performance/network-characteristics"
            fontSize="xs"
            display="block"
            p={2}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            textDecoration="none"
            mb={2}
            _hover={{
              borderColor: 'blue.200',
            }}
          >
            ONRC Network Characteristics
          </Link>
          <Link
            as={ReactRouterLink}
            to="/onf/network-characteristics"
            fontSize="xs"
            display="block"
            p={2}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            textDecoration="none"
            _hover={{
              borderColor: 'blue.200',
            }}
          >
            ONF Network Characteristics
          </Link>
        </Box>
      )}
    </>
  );
}

export default NetworkCharacteristics;
