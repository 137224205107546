import {
  HStack, Select, Text,
} from '@chakra-ui/react';
import { pageSizeState } from '@tce/state/tce';
import { useRecoilState } from 'recoil';

export default function PageSize() {
  const [pageSize, setPageSize] = useRecoilState(pageSizeState);
  const pageSizes = [
    250, 500, 750, 1000, 1500,
  ];

  const onChangePageSize = (e) => {
    setPageSize(Number.parseInt(e.target.value, 10));
  };

  return (
    <HStack>
      <Select
        className="page-size"
        bgGradient="linear(to-b, gray.50, gray.100)"
        onChange={onChangePageSize}
        value={pageSize}
        w="160px"
      >
        {pageSizes.map((size) => (
          <option key={size}>{size}</option>
        ))}
      </Select>
      <Text w="full" fontWeight="bold">Per Page</Text>
    </HStack>
  );
}
