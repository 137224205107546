import { useState, useEffect } from 'react';

import { useDataQualityKPI } from '@src/modules/performance/context/map-api-hooks';
import TextWidget from './TextWidget';
import councildata from '../CouncilData';

const TITLE = 'Data Quality';
const SUBTEXT1 = 'Overall';
const SUBTEXT2 = 'Percentage at Expected Standard';

function DataQualityIndicator({ selection, setSelection, filter }) {
  const { data, isLoading } = useDataQualityKPI();
  const [value, setValue] = useState('-');
  const [colour, setColour] = useState('GREY');
  const [subText1, setSubText1] = useState(SUBTEXT1);
  const [subText2, setSubText2] = useState(SUBTEXT2);
  const [marker] = useState('percentage');
  const [lockYear, setLockYear] = useState(null);

  useEffect(() => {
    if (data && !isLoading) {
      const selectedRca = data.find((x) => x.GroupType === (filter || null));
      if (selectedRca) {
        const c = councildata.mapValueToColourRange(selectedRca.Value, councildata.colours.dataQuality);
        setColour(c);
        setValue(selectedRca.Value);
        // eslint-disable-next-line no-nested-ternary
        setSubText1(filter ? (['Low', 'Moderate', 'High'].some((x) => filter.includes(x)) ? `${filter} Importance` : filter) : 'Overall');
        setSubText2(SUBTEXT2);
        setLockYear(selectedRca.LockYear);
      } else {
        setColour('GREY');
        setValue('No data');
        setSubText1('');
        setSubText2('');
      }
    }
  }, [data, isLoading, filter]);

  return (
    <TextWidget
      title={TITLE}
      value={value}
      lockYear={lockYear}
      colour={colour}
      subtext1={subText1}
      subtext2={subText2}
      marker={marker}
      selected={selection === 'dataQuality'}
      handleClick={() => {
        setSelection('dataQuality');
      }}
    />
  );
}

export default DataQualityIndicator;
