const MapMarkerSVG = ({ dotColor = '#ff0000', size = 40 }) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="${size}" height="${size}">
      <!-- Define the shadow filter -->
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="1" dy="1" stdDeviation="2" flood-color="rgba(0, 0, 0, 0.4)" />
        </filter>
      </defs>
      
      <!-- Marker shape with white background and drop shadow -->
      <path fill="#ffffff" d="M16 0C10 0 5 5 5 11c0 5 8 15 11 19 3-4 11-14 11-19 0-6-5-11-11-11z" filter="url(#shadow)" />
      
      <!-- Inner circle dot with dynamic color -->
      <circle cx="16" cy="11" r="8" fill="${dotColor}" />
    </svg>
  `;
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
};

export default MapMarkerSVG;
