import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useUserInfo } from '@transport-insights/uikit';
import PageLoader from '@src/components/layouts/shared/PageLoader';
import ErrorPage from '@src/components/pages/ErrorPage';

export default function AuthenticateUser({ children, redirectToLoginPage = true }) {
  const { instance } = useMsal();
  const { isLoggedIn } = useUserInfo();
  const [check, setCheck] = useState({ loading: true, error: undefined });

  useEffect(() => {
    if (isLoggedIn) {
      setCheck({ loading: false });
      return;
    }
    // Page requires login, redirect user to login page
    if (redirectToLoginPage) {
      instance.loginRedirect()
        .catch((error) => setCheck({ loading: false, error }));
    } else {
      setCheck({ loading: false });
    }
  }, [instance, isLoggedIn, redirectToLoginPage]);

  return (
    <PageLoader isLoading={check.loading}>
      {
        check.error
          ? <ErrorPage error={check.error} />
          : children
      }
    </PageLoader>
  );
}
