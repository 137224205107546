import { useEffect, useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';

function SLListTextWidget({ queryInfo, title, field }) {
  const [text, setText] = useState([]);

  useEffect(() => {
    if (queryInfo?.data) {
      const values = queryInfo.data.map((row) => row[field]);
      const distinctValues = [...new Set(values)];

      setText(distinctValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box>
      <Text align="center" fontSize={12} fontWeight="bold" mb={8}>{title}</Text>
      <LockYearBadge lockYear={queryInfo?.data[0]?.LockYear} />
      <Flex flexDirection="column" mt={4}>
        {text.map((t, index) => (
          <Text key={`t${index}`}>
            {t}
          </Text>
        ))}
      </Flex>
    </Box>
  );
}

export default SLListTextWidget;
