import { QueryClientProvider, QueryClient } from 'react-query';

// Defaults for the useQuery() hooks
// See: https://react-query.tanstack.com/guides/important-defaults
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, once data is retrieved, we keep it as 'fresh' and
      // do not automatically refresh, unless opted-in to do so!
      staleTime: Infinity,
      // Avoid getting stuck in a retry loop
      // only retry once, and wait 2 seconds before retrying
      retry: 1,
      retryDelay: 2000,
    },
  },
});

export default function QueryClientBehaviour({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      { children }
    </QueryClientProvider>
  );
}
