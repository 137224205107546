import React, { useEffect, useState } from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Center,
  Flex,
} from '@chakra-ui/react';
import TrafficLight from '../indicators/TrafficLight';

const tableCaption = (labels) => (
  <Center>
    <Flex>
      <Center>
        {labels.map((l) => (
          <React.Fragment key={l.text}>
            <TrafficLight colour={l.colour} size={8} />
            <Text ml={2} mr={5} fontSize={12}>{l.text}</Text>
          </React.Fragment>
        ))}
      </Center>
    </Flex>
  </Center>
);

const defaultData = {
  headers: [],
  subheaders: [],
  rows: [],
  legend: [],
};

function TrafficLightTable({ queryInfo }) {
  const [data, setData] = useState({ ...defaultData });

  useEffect(() => {
    if (queryInfo?.data) {
      setData(queryInfo.data);
    } else {
      setData({ ...defaultData });
    }
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Table
      variant="simple"
      sx={{
        'td:nth-child(1),td:nth-child(4)': {
          borderRight: 'solid 2px lightgray',
        },
      }}
      size="sm"
    >
      <TableCaption>{tableCaption(data.legend)}</TableCaption>
      <Thead>
        <Tr>
          {data.headers.map((h) => (
            <Th colSpan={h.colspan} textTransform="none" fontSize={12} textAlign="center" key={h.text} border="none">
              {h.text}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          {data.subheaders.map((sh, index) => (
            <Td key={`header${sh}${index}`}>
              {index < 1
              && (
              <Text align="right">
                {sh}
              </Text>
              )}
              {index > 0 && (
                <Center>
                  {sh}
                </Center>
              )}
            </Td>
          ))}
        </Tr>
        {data.rows.map((row) => (
          <Tr key={`row-${row.title}`}>
            <Td border="none">
              <Text align="right">{row.title}</Text>
            </Td>
            {row.cells.map((cell, index) => (
              <Td key={`cell${row.title}-${index}`} border="none">
                <Center>
                  <TrafficLight size={12} colour={cell} />
                </Center>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default TrafficLightTable;
