import {
  Box, Heading, Link, Stat, StatHelpText, StatLabel, StatNumber, SimpleGrid, HStack,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import Card from '@src/modules/performance/components/Card';
import {
  isEmpty, isNumber, last, reduce,
} from 'lodash';
import { Link as ReactRouterLink } from 'react-router-dom';

import {
  useCyclewaysNetworkLength, useDeliveryKPI, useRoadMaintenanceKPI, useStats,
} from '@src/modules/performance/context/reports-api-hooks';
import LockYearBadge from '../LockYearBadge';

// NOTE:

// renders a list of network characteristics, which will vary by council
// some have only a few, some have lots
function StatsWidget({ showNetworkCharacteristicButtons, rest }) {
  const { data, isLoading: isLoadingStats, isError } = useStats();
  const { data: deliveryData, isLoading: isLoadingDelivery } = useDeliveryKPI();
  const { data: cyclewayData, isLoading: isLoadingCycleways } = useCyclewaysNetworkLength();
  const { data: roadMaintenanceData, isloading: isLoadingRoadMaintenance } = useRoadMaintenanceKPI();

  const isLoading = isLoadingStats || isLoadingDelivery || isLoadingCycleways || isLoadingRoadMaintenance;

  const formatNumber = (value, digits, prefix, postfix, noDataValue) => {
    if (value !== null && value !== '') {
      return `${prefix || ''}${value.toLocaleString('en-NZ', { minimumFractionDigits: digits, maximumFractionDigits: digits })}${postfix || ''}`;
    }
    if (noDataValue !== null) {
      return noDataValue;
    }
    return 'N/A';
  };

  return (
    <Box width="100%" mt={4} {...rest}>
      <HStack mb={4} width="100%" justify="space-between" align="center">
        <Heading as="h2" fontSize="xl">Network Statistics</Heading>
        {!isLoading && showNetworkCharacteristicButtons && (
          <HStack textAlign="center">
            <Link
              as={ReactRouterLink}
              to="/performance/network-characteristics"
              fontSize="xs"
              display="block"
              p={2}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              textDecoration="none"
              _hover={{
                borderColor: 'blue.200',
              }}
            >
              ONRC Network Characteristics
            </Link>
            <Link
              as={ReactRouterLink}
              to="/onf/network-characteristics"
              fontSize="xs"
              display="block"
              p={2}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              textDecoration="none"
              _hover={{
                borderColor: 'blue.200',
              }}
            >
              ONF Network Characteristics
            </Link>
          </HStack>
        )}
      </HStack>
      <Card>
        {(isLoading || isError) && (
        <SimpleGrid
          spacing={4}
          columns={{
            base: 5,
          }}
          width="100%"
        >
          {Array.from({ length: 10 }, (_, i) => (
            <Stat key={`kpi-${i}`}>
              <SkeletonText width="30%" noOfLines={1}><StatLabel /></SkeletonText>
              <Skeleton width="40%" height="24px" my={2}><StatNumber /></Skeleton>
              <SkeletonText width="10%" noOfLines={1}><StatHelpText /></SkeletonText>
            </Stat>
          ))}
        </SimpleGrid>
        )}
        {(!isLoading && !isError && data)
            && (

              <SimpleGrid
                spacing={4}
                columns={{
                  base: 2,
                  sm: 3,
                  md: 5,
                }}
                width="100%"
              >
                <Stat key="kpi-pop">
                  <StatLabel>Population</StatLabel>
                  <StatNumber>{formatNumber(data.Population.Value, 0, null, null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.Population.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-gdp">
                  <StatLabel>Maintenance ($/km)</StatLabel>
                  <StatNumber>{roadMaintenanceData?.costPerKm ? formatNumber(roadMaintenanceData?.costPerKm, 0, '$', null, 0) : 'No data'}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={roadMaintenanceData?.quadLockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-budget">
                  <StatLabel>Budget ($M)</StatLabel>
                  <StatNumber>{isNumber(deliveryData?.budget) ? formatNumber(deliveryData?.budget, 1, '$', null, 'No data') : 'No data'}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={deliveryData?.lockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-val">
                  <StatLabel>Valuation ($M)</StatLabel>
                  <StatNumber>{formatNumber(data.Valuation.Value, 0, '$', null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.Valuation.ReportYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-exp">
                  <StatLabel>Expenditure ($M)</StatLabel>
                  <StatNumber>{formatNumber(data.Expenditure.Value, 1, '$', null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.Expenditure.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-cap">
                  <StatLabel>Expenditure per capita</StatLabel>
                  <StatNumber>{formatNumber(data.ExpenditurePerCapita.Value, 0, '$', null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.ExpenditurePerCapita.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-tot-rd">
                  <StatLabel>Total Roads (km)</StatLabel>
                  <StatNumber>{formatNumber(data.TotalKm.Value, 0, null, null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.TotalKm.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-tot-cyc">
                  <StatLabel>Total Cycleways (km)</StatLabel>
                  <StatNumber>{!isEmpty(cyclewayData?.values) ? reduce(cyclewayData?.values, (acc, v) => acc + last(v), 0).toFixed(1) : 'No data'}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={cyclewayData?.lockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-brid">
                  <StatLabel>No. of bridges</StatLabel>
                  <StatNumber>{formatNumber(data.BridgesCount.Value, 0, null, null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.BridgesCount.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-far">
                  <StatLabel>FAR</StatLabel>
                  <StatNumber>{formatNumber(data.FAR.Value * 100.0, 0, null, '%', 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.FAR.LockYear}
                    />
                  </StatHelpText>
                </Stat>
              </SimpleGrid>
            )}
      </Card>
    </Box>
  );
}

export default StatsWidget;
