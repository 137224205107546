import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import {
  convertBubbleRange,
} from '@src/utils/math';
import { getContrastColor, COMPARISON_COLORS } from '@src/utils/color';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import ReportSubheading from '../shared/ReportSubheading';
import CollapsedContent from '../shared/CollapsedContent';
import ChartContainer from '../shared/ChartContainer';

export default function Ep1({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const filteredCategories = useFilteredCategories(filters);
  const rca = reportData.rcas[0];
  const filteredReportData = reportData.data.filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = filteredReportData.filter((x) => x.id === rca.id);
  const pieChart = Object.entries(groupBy(selectedRcaData, 'category'))
    .map(([k, v]) => ({
      name: k,
      total: v.map((item) => item.incidentCount).reduce((a, b) => a + b, 0),
    }));
  const stackedBarChart = Object.entries(groupBy(selectedRcaData, 'causeDescription'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({ x: item.category, y: item.vehicleCount })),
    }));
  const bubbleChartData = Object.entries(groupBy(selectedRcaData, 'category'))
    .map(([k, v]) => ({
      category: k,
      length: v[0].length_km,
      totalClosures: v.map((item) => item.incidentCount).reduce((a, b) => a + b, 0),
      totalVehicles: v.map((item) => item.vehicleCount).reduce((a, b) => a + b, 0),
    }));
  const comparisonData = Object.entries(groupBy(filteredReportData, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        category: item.category,
        vehicleCountPerMVKT: item.vehicleCountPerMVKT,
      })),
    }))
    .map((x) => ({
      name: x.name,
      data: Object.entries(groupBy(x.data, 'category'))
        .map(([k, v]) => ({
          x: k,
          y: v.map((item) => item.vehicleCountPerMVKT).reduce((a, b) => a + b, 0).toFixed(2),
        })),
    }));
  const dataTable = Object.entries(groupBy(reportData.data, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        rcaName: item.rcaName,
        incidentCount: item.incidentCount,
        vehicleCount: item.vehicleCount,
        detour: item.detour,
      })),
    }))
    .map((x) => ({
      name: x.name,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          detourCount: v.filter((y) => y.detour).map((item) => item.incidentCount).reduce((a, b) => a + b, 0),
          detourVehicleCount: v.filter((y) => y.detour).map((item) => item.vehicleCount).reduce((a, b) => a + b, 0),
          noDetourCount: v.filter((y) => !y.detour).map((item) => item.incidentCount).reduce((a, b) => a + b, 0),
          noDetourVehicleCount: v.filter((y) => !y.detour).map((item) => item.vehicleCount).reduce((a, b) => a + b, 0),
        })),
    }));
  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '1fr 2fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Number of Unplanned Road Closures
          </ReportSubheading>
          <Text mb={8}>This chart shows the annual percentage of unplanned road closures for the selected ONF categories.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: filteredCategories.map((x) => theme.colors.onfCategory[x.description].base),
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: true,
                    marker: {
                      show: false,
                    },
                  },
                  labels: pieChart.map((x) => x.name),
                }
              }
              series={pieChart.map((x) => x.total)}
              type="pie"
              width="100%"
            />
            <Text textAlign="center" mt={4}>
              Total:
              {' '}
              {pieChart.map((x) => x.total).reduce((a, b) => a + b, 0)}
              {' '}
              unplanned road closures
            </Text>
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Journeys affected by Unplanned Road Closures
          </ReportSubheading>
          <Text mb={4}>
            The bars show the annual count of estimated journeys affected by unplanned road closures in each category. Each bar includes a breakdown of the causes of the closures.
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: true,
                  },
                  colors: filteredCategories.map((x) => theme.colors.onfCategory[x.description].base),
                  xaxis: {
                    labels: {
                      style: {
                        colors: filteredCategories.map((x) => theme.colors.onfCategory[x.description].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'Journeys Affected',
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                    labels: {
                      useSeriesColors: false,
                    },
                  },
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    shared: true,
                    marker: {
                      show: false,
                    },
                    inverseOrder: true,
                    y: {
                      formatter: (v) => (v === 0 ? null : v),
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                }
              }
              series={stackedBarChart}
              type="bar"
              width="100%"
              height="400"
            />
            <Text textAlign="center">
              Total:
              {' '}
              {stackedBarChart.map(({ data }) => data.map(({ y }) => y).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0).toLocaleString('en-US')}
              {' '}
              estimated journeys
            </Text>
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>
          Unplanned Road Closures
        </ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the unplanned closures and how many estimated journeys were affected by those closures, compared to the network length for each ONF category.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
                  {
                    colors: bubbleChartData.map((x) => theme.colors.onfCategory[x.category].base),
                    grid: {
                      padding: {
                        left: 20,
                        bottom: 20,
                      },
                    },
                    xaxis: {
                      type: 'numeric',
                      title: {
                        text: 'Street Category Network Length (km)',
                        offsetY: 70,
                      },
                      min: 0,
                      max: Math.max(...bubbleChartData.map((x) => x.length)) * 1.1,
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)} km`;
                        },
                      },
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: (Math.max(...bubbleChartData.map((x) => x.totalClosures)) + 0.5) * 1.1,
                        title: {
                          text: 'Road Closure Count',
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    fill: {
                      opacity: 0.8,
                    },
                    tooltip: {
                      marker: {
                        show: false,
                      },
                      z: {
                        title: 'Vehicle Count',
                      },
                      y: {
                        title: {
                          formatter: () => 'Crash Count:',
                        },
                      },
                      x: {
                        show: true,
                        formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                      },
                      custom({ seriesIndex, dataPointIndex, w }) {
                        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        const { name } = w.globals.initialSeries[seriesIndex];

                        return `<div style="padding: 10px">
                        <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                        <ul>
                        <li><b>Closures</b>: ${data.y}</li>
                        <li><b>Vehicle Count</b>: ${data.totalVehicles}</li>
                        <li><b>Length</b>: ${data.x} km</li>
                        </ul></div>`;
                      },
                    },
                    plotOptions: {
                      bubble: {
                        zScaling: true,
                      },
                    },
                  }
                }
                series={bubbleChartData.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.totalClosures,
                      z: convertBubbleRange(item.totalVehicles, [Math.min(...bubbleChartData.map((x) => x.totalVehicles)), Math.max(...bubbleChartData.map((x) => x.totalVehicles))], [1, 8]),
                      totalVehicles: item.totalVehicles,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>
          Comparison with other networks
        </ReportSubheading>
        <Text mb={8}>
          This graph shows the comparison between your network and the other networks you have selected, or between your network and your region, your peer group,
          and the National network.
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    title: {
                      text: 'Vehicle Count per MVKT',
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter(val) {
                    return val || 0;
                  },
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={comparisonData}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">Category</Th>
                  <Th background="gray.50">Network</Th>
                  <Th background="gray.50">Closures with Detour</Th>
                  <Th background="gray.50">No. of Vehicles with Detour</Th>
                  <Th background="gray.50">Closures with No Detour</Th>
                  <Th background="gray.50">No. of Vehicles with No Detour</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataTable.map((item) => item.data.map(({
                  rcaName, detourCount, detourVehicleCount, noDetourCount, noDetourVehicleCount,
                }, i) => (
                  <Tr key={`cat_${i}`}>
                    {
                        i === 0
                        && (
                        <Td
                          rowSpan={item.data.length}
                          color={getContrastColor(theme.colors.onfCategory[item.name].base)}
                          background={theme.colors.onfCategory[item.name].base}
                          borderBottom="2px solid"
                          borderColor={i === 0 ? theme.colors.onfCategory[item.name].base : 'gray.100'}
                          fontWeight="bold"
                        >
                          {item.name}
                        </Td>
                        )
                      }
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{rcaName}</Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{detourCount}</Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{detourVehicleCount}</Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{noDetourCount}</Td>
                    <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{noDetourVehicleCount}</Td>
                  </Tr>
                )))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
