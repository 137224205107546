import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

export default function ReportModal({
  title, size, children, ...rest
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} variant="solid" size="md" width="100%" colorScheme="brand.blue" {...rest}>{title}</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={size || '4xl'} scrollBehavior="inside">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(4px)"
        />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
