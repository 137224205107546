import { currentDatabaseSelector, selectedLockYearState, selectedDatabaseIdState } from '@transport-insights/uikit';
import { RAG_COLORS } from '@src/utils/color';
import { isEmpty, round } from 'lodash';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import usePerformanceLevelsOfServiceApi from '../api/usePerformanceLevelsOfServiceApi';

export function getChartData(condition, summarize) {
  const unspecified = {
    label: 'Not Specified',
    color: RAG_COLORS.gray,
    value: condition.percentageUnspecified,
  };

  if (summarize) {
    return [
      {
        label: 'Good',
        color: RAG_COLORS.green,
        value: round(condition.percentageExcellent + condition.percentageGood + condition.percentageAverage, 2),
      },
      {
        label: 'Poor',
        color: RAG_COLORS.red,
        value: round(condition.percentagePoor + condition.percentageVeryPoor, 2),
      },
      unspecified,
    ];
  }

  return [
    {
      label: 'Excellent',
      color: RAG_COLORS.green,
      value: condition.percentageExcellent,
    },
    {
      label: 'Good',
      color: RAG_COLORS.lightGreen,
      value: condition.percentageGood,
    },
    {
      label: 'Average',
      color: RAG_COLORS.yellow,
      value: condition.percentageAverage,
    },
    {
      label: 'Poor',
      color: RAG_COLORS.orange,
      value: condition.percentagePoor,
    },
    {
      label: 'Very Poor',
      color: RAG_COLORS.red,
      value: condition.percentageVeryPoor,
    },
    unspecified,
  ];
}

function mapToChartData(data, summarize) {
  if (isEmpty(data)) {
    return [];
  }
  return data.map((d) => ({
    ...d,
    chartData: getChartData(d.condition, summarize),
  }));
}

export function useAssets({ summarize, enabled }) {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = usePerformanceLevelsOfServiceApi();

  return useQuery(
    ['levels-of-service-assets', rcaId, lockYear],
    async () => api.getLevelsOfServiceAssets(rcaId, lockYear),
    {
      enabled,
      select: (data) => mapToChartData(data, summarize),
      retry: false,
    },
  );
}

export function useDataConfidence({ enabled }) {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = usePerformanceLevelsOfServiceApi();

  return useQuery(
    ['levels-of-service-data-confidence', rcaId, lockYear],
    async () => api.getLevelsOfServiceDataConfidence(rcaId, lockYear),
    {
      enabled,
      select: (data) => data.score,
      retry: false,
    },
  );
}

export function useAssetHistorical({ table, subType, summarize }) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const rcaId = rca?.id ?? null;
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = usePerformanceLevelsOfServiceApi();

  return useQuery(
    ['levels-of-service-asset-historical', table, subType, rcaId, lockYear],
    async () => api.getLevelsOfServiceAssetHistorical(table, subType, rcaId, lockYear),
    {
      enabled: !!table && !!rcaId && !!lockYear,
      select: (data) => mapToChartData(data, summarize),
      retry: 1,
    },
  );
}

export function useAssetCategory({ table, subType, summarize }) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const rcaId = rca?.id ?? null;
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = usePerformanceLevelsOfServiceApi();

  return useQuery(
    ['levels-of-service-asset-category', table, subType, rcaId, lockYear],
    async () => api.getLevelsOfServiceAssetCategory(table, subType, rcaId, lockYear),
    {
      enabled: !!table && !!rcaId && !!lockYear,
      select: (data) => mapToChartData(data, summarize),
      retry: false,
    },
  );
}
