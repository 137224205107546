import {
  Flex,
} from '@chakra-ui/react';

export default function ChartContainer({ children, ...rest }) {
  return (
    <Flex
      p={4}
      background="white"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="md"
      boxShadow="sm"
      flexDirection="column"
      justifyContent="center"
      flexGrow="1"
      {...rest}
    >
      {children}
    </Flex>
  );
}
