import {
  Center, Grid, GridItem, Heading, useTheme, VStack,
} from '@chakra-ui/react';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import { categories } from '@src/modules/onf/api/categories';
import {
  groupBy, map, reduce, sumBy,
} from 'lodash';
import { useAssetCategory } from '../../context/levels-of-service-api-hooks';
import { AssetCard } from './AssetCard';

function OnfFamilyBreakdown({ label, charts }) {
  const theme = useTheme();
  const chartData = charts.map((c) => c.chartData);
  const overallChartData = reduce(chartData, (result, data) => {
    data.forEach((item) => {
      const existingItem = result.find((i) => i.label === item.label);
      if (existingItem) {
        existingItem.value += item.value;
      } else {
        result.push(item);
      }
    });
    return result.map((item) => ({
      label: item.label,
      color: item.color,
      value: (item.value / sumBy(result, 'value')) * 100,
    }));
  }, []);

  return (
    <VStack gap={2} pb={2} align="stretch">
      <Heading as="h3" fontSize="xl">
        {label}
      </Heading>
      <Grid templateColumns="repeat(6, 1fr)" templateRows="repeat(2, 1fr)" gap={2}>
        <GridItem display="flex" colSpan={2} rowSpan={2}>
          <AssetCard
            title="Total"
            chartData={overallChartData}
            isInteractive
          />
        </GridItem>
        {charts.map((c) => (
          <AssetCard
            key={c.label}
            title={c.label}
            titleColor={theme.colors.onfCategory[c.label].base}
            chartData={c.chartData}
            isInteractive
          />
        ))}
      </Grid>
    </VStack>
  );
}

function OnfBreakdown({ asset, summarize }) {
  const { data, isLoading } = useAssetCategory({
    table: asset.table,
    subType: asset.subType,
    summarize,
  });

  const groupedData = map(groupBy(data, (d) => {
    const category = categories.find((c) => c.description === d.label);
    return category.streetFamily;
  }), (v, k) => ({ label: k, charts: v }));

  return (
    <>
      <Heading as="h2" py={4} fontSize="2xl">
        Breakdown by ONF Category
      </Heading>
      {isLoading && (
      <Center h="200px">
        <LoadingSpinner />
      </Center>
      )}
      {!isLoading && (
        groupedData.map((g) => (
          <OnfFamilyBreakdown key={g.label} label={g.label} charts={g.charts} />
        ))
      )}
    </>
  );
}

export default OnfBreakdown;
