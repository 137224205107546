import { Badge } from '@chakra-ui/react';

const statusColourMap = {
  NEW: { colorScheme: 'gray' },
  QUEUED: { colorScheme: 'gray' },
  PREPARING: { colorScheme: 'gray' },
  COPYING: { colorScheme: 'gray' },
  READY: { colorScheme: 'blue' },
  PROCESSING: { colorScheme: 'orange' },
  COMPLETED: { colorScheme: 'green' },
  RESULTS: { colorScheme: 'green' },
  FAILED: { colorScheme: 'red' },
  'CALCULATING RATES': { colorScheme: 'orange' },
  'CALCULATING ESTIMATES': { colorScheme: 'orange' },
  Unknown: { colorScheme: 'whiteAlpha' },
};

const getColorScheme = (status) => {
  const key = statusColourMap?.[status] ? status : 'Unknown';
  return statusColourMap[key].colorScheme;
};

/**
 * Map RESULTS status to CALCULATED
 * @param job
 * @returns {string}
 */
const getStatus = (job) => (job?.status === 'RESULTS' ? 'CALCULATED' : job?.status);

export default function EstimateStatusLabel({ job }) {
  if (job?.status === 'CALCULATING ESTIMATES' && job?.percentCompleted > 0) {
    return (
      <Badge colorScheme={getColorScheme(job?.status)}>
        CALCULATING (
        {job.percentCompleted}
        %)
      </Badge>
    );
  }
  return (
    <Badge colorScheme={getColorScheme(job?.status)}>{getStatus(job)}</Badge>
  );
}
