import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

/**
 * @typedef {object} PortalNavContextResponse
 * @prop {NavContextDatabase[]} databases
 */

/**
 * @typedef {object} NavContextDatabase
 * @prop {string} id
 * @prop {string} name
 * @prop {string[]} lockYears
 */

/**
 * @param {import('./_backend').BackendSelector} backend
 */
export const useSettingsApi = () => {
  const { SETTINGS_API_URL } = useConfig();
  const api = useAuthenticatedApi(SETTINGS_API_URL);
  return {
    /**
     * @returns {Promise<PortalNavContextResponse>}
     */
    async getRcaSelectorState() {
      const res = await api.get('/rca-selector');
      return (res.data || {});
    },
    async setCurrentDatabase(databaseId) {
      await api.post(`/database?db=${databaseId}`);
    },
    async getNews() {
      const res = await api.get('news');
      return res?.data ?? [];
    },
    async setNewsViewed() {
      const res = await api.put('news/viewed', {});
      return res?.data ?? [];
    },
    async getConfig() {
      const res = await api.get('configs');
      return res?.data ?? {};
    },
  };
};
