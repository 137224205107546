import { TrafficLightColours as COLOURS, amenityAndConditionLegend } from '@src/modules/performance/shared/constants';
import { useAchievementsKPI } from '@src/modules/performance/context/map-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import IndicatorLegend from '../IndicatorLegend';
import { roundKpiValue } from '../../shared/helpers';

export default function AmenityWidget() {
  const { data: amenityData, isLoading, isError } = useAchievementsKPI();

  const title = 'Road Condition';
  const description = 'Change in ride quality on sealed roads';

  // If there is an error and no data we want to hide the widget
  if (isError && (!amenityData || amenityData?.value === 'No data')) return null;

  return (
    <Widget
      link="/performance/reports/amenity-and-condition"
      title={title}
      description={description}
      lockYear={amenityData?.lockYear}
      quarter={null}
      isLoading={isLoading && !amenityData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The trend in the amount of traffic travelling on smooth sealed roads, indicating the ride quality experienced by the road user.
          </Text>
          <IndicatorLegend items={amenityAndConditionLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[amenityData?.colour]}
        value={`${roundKpiValue(amenityData?.value, 5, 1)}%`}
        isLoading={isLoading && !amenityData}
      />
    </Widget>
  );
}
