import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useRoadMaintenanceKPI } from '../../context/reports-api-hooks';
import { TextIndicator } from '../Indicators';

export default function RoadMaintenanceWidget() {
  const { data: roadMaintenanceData, isLoading, isError } = useRoadMaintenanceKPI();

  const title = 'Road Maintenance';
  const description = 'Cost per km';

  // If there is an error and no data we want to hide the widget
  if (isError && !roadMaintenanceData) return null;

  return (
    <Widget
      link={null}
      title={title}
      description={description}
      lockYear={roadMaintenanceData?.quadLockYear}
      quarter={null}
      isLoading={isLoading && !roadMaintenanceData}
      moreInfoContent={(
        <Text fontSize="sm">
          The annual average spend per network kilometre on NLTP road maintenance activities in the 3-year NLTP period.
        </Text>
      )}
    >
      <TextIndicator
        value={roadMaintenanceData?.costPerKm ? `$${roadMaintenanceData.costPerKm.toLocaleString('en-NZ')}` : null}
        isLoading={isLoading && !roadMaintenanceData}
      />
    </Widget>
  );
}
