import allReports from '../shared/report-list.json';

export const reportOrder = [
  'stateHighwaysPotholeRepairs',
  'efficiencyEffectivenessEconomy',
  'servicePerformance',
  'delivery',
  'deliveryPerformance',
  'safety',
  'amenityAndCondition',
  'temporaryTrafficManagement',
  'activityManagement',
  'coinvestorAssurance',
  'publicTransport',
  'networkAvailability',
  'networkPhysicalCharacteristics',
  'roadNetworkUse',
  'territorialActivity',
  'roadMaintenance',
  'networkAssetManagement',
];

export const useAllowedReportsList = (rcaId) => {
  // If no RCA ID, return all reports
  if (!rcaId) {
    return reportOrder.filter((key) => key in allReports).reduce(
      (obj, key) => {
        obj[key] = allReports[key];
        return obj;
      },
      {},
    );
  }

  const allowedReports = [
    'safety',
    'networkPhysicalCharacteristics',
    'roadNetworkUse',
    'temporaryTrafficManagement',
    'roadMaintenance',
    'networkAssetManagement',
    'deliveryPerformance',
  ];

  // If not DOC roads or State Highways
  if (rcaId !== 81 && rcaId !== 88) {
    allowedReports.push('publicTransport');
    allowedReports.push('territorialActivity');
    allowedReports.push('servicePerformance');
    allowedReports.push('coinvestorAssurance');
    allowedReports.push('efficiencyEffectivenessEconomy');
    allowedReports.push('networkAvailability');
    allowedReports.push('delivery');
    allowedReports.push('amenityAndCondition');
    allowedReports.push('activityManagement');
  }

  // If DOC Roads
  if (rcaId === 88) {
    allowedReports.push('coinvestorAssurance');
    allowedReports.push('amenityAndCondition');
    allowedReports.push('activityManagement');
    allowedReports.push('delivery');
  }

  // If State Highways
  if (rcaId === 81) {
    allowedReports.push('stateHighwaysPotholeRepairs');
  }

  // Filter reports list based on what is allowed
  const filteredReports = Object.keys(allReports)
    .filter((key) => allowedReports.includes(key))
    .reduce((obj, key) => {
      obj[key] = allReports[key];
      return obj;
    }, {});

  // Order reports based on reportOrder variable
  const orderedReports = reportOrder
    .filter((key) => key in filteredReports)
    .reduce((obj, key) => {
      obj[key] = filteredReports[key];
      return obj;
    }, {});

  return orderedReports;
};
