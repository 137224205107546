import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

const processRoadSection = (row) => {
  // Set Error/Warning field
  row.errorWarning = '';
  if (row?.tMixInvalid1 === true) {
    row.errorWarning += 'Percentage is blank; ';
  }

  if (row?.tMixInvalid2 === true) {
    const total = row.pcCar + row.pcLcv + row.pcMcv + row.pcHcvI + row.pcHcvII + row.pcBus;
    const formattedTotal = Number.isInteger(total) ? total : total.toFixed(3);
    row.errorWarning += `Total Loading not 100% (${formattedTotal}%); `;
  }

  if (row?.tMixInvalid3 === true) {
    row.errorWarning += 'Percentage Heavy <> MCV + HCVI + HCVII + Bus; ';
  }

  if (row?.significantChanges === true) {
    row.errorWarning += `Significant change in the Count (${row.significantChangesPercentage.toFixed(0)}% / ${row.vpdDiff}vpd); `;
  }

  if (row.pcHeavy > 10) {
    row.errorWarning += 'The Percentage of Heavy Vehicles is more than 10%';
  }

  // Set flags used to determine is a cell is editable
  row.reviewTrafficMix = row?.tMixInvalid1 || row?.tMixInvalid2 || row?.tMixInvalid3;
  row.updateAadtEstimate = row?.aadtEstimate === null || row?.aadtEstimate === 0;
  return row;
};

export default () => {
  const { TCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(TCE_API_URL);

  return {
    /* -------------------------------------------
                      Traffic Groups
       -------------------------------------------- */
    // get a list of traffic groups
    async getTrafficGroups() {
      const res = await api.get('/trafficgroups');
      return res?.data || [];
    },

    // create or update a traffic group
    /**
     * data:
     * {
     *    id:       integer  (optional)
     *    code:     string,  (required)
     *    global:   boolean, (optional, if not specified will default to false)
     *    description:      string, (optional)
     *    manualrate:       double,  (required)
     *    calculatedrate:   double,  (required)
     *    rcaid:    integer, (required)
     *    lockyear: string   (required)
     * }
     */
    async createUpdateTrafficGroup(data) {
      if (!data) return null;
      // if the body contains an id, this will update that traffic group,
      // otherwise it will create a new one.
      const res = await api.put('/trafficgroup', data);
      // will return the new or the updated traffic group
      return res?.data || null;
    },

    // delete a single traffic group by id
    // returns the deleted traffic group
    async deleteTrafficGroup(id) {
      if (!id) return null;
      const res = await api.delete(`/trafficgroup/${id}`);
      return res?.data || null;
    },

    /* -------------------------------------------
                        RCA Imports
       -------------------------------------------- */
    // get a list of imports by rca
    async getRcaImports(rcaid) {
      if (!rcaid) return [];
      const res = await api.get(`/rcaimports/${rcaid}`);
      return res?.data || [];
    },

    // get a single import by id
    async getRcaImport(id) {
      if (!id) return null;
      const res = await api.get(`/rcaimport/${id}`);
      return res?.data || null;
    },

    /* -------------------------------------------
                        Road sections
       -------------------------------------------- */

    // get a list of road sections by job number
    /**
     *  all fields are optional
     *
     *  data: {
     *    // pagination
     *    page: int (default 1)
     *    rows: int (default 20)
     *    // alt pagination
     *    offset: int,
     *    rows: int,
     *    // filters
     *    roadName: string
     *    trafficGroupdId: Guid
     *    onrcCategoryId: int
     *    cwayHierarchy: string
     *    urbanRural: string
     *    onlyInconsistentTrafficMix: boolean
     *    onlySignificantCountChanges: boolean
     *    onlyMissingAADT: boolean,
     *    onlyMissingTrafficGroups: boolean,
     *    onlySectionsWithCounts: boolean
     *  }
     *
     *  will return something like this
     *  roadsections will be sorted by name and cwaystart
     *  {
     *    pagination: {
     *      page: int
     *      rows: int
     *      totalPages: int
     *      totalRows: int
     *      offset: int
     *    },
     *    roadSections: [
     *      {
     *        id,
     *        cwayNo,
     *        roadNo,
     *        name,
     *        cwayStart,
     *        cwayEnd,
     *        latestCount,
     *        latestCountDate,
     *        onrcCategory,
     *        trafficGroupId,
     *        rcaId,
     *        importId,
     *
     *        pcCar,
     *        pcLcv
     *        pcMcv
     *        pcHcvI
     *        pcHcvII
     *        pcBus
     *        pcHeavy
     *      }
     *    ]
     *  }
     *
     *  */
    async getRoadSections(jobId, startRow, numberOfRows, filter) {
      if (!jobId) return [];
      const data = {
        offset: startRow,
        rows: numberOfRows,
        ...filter,
      };
      const res = await api.post(`/roadsections/${jobId}`, data);

      const dataReturned = res?.data || [];
      dataReturned.roadSections.forEach((row) => processRoadSection(row));
      return dataReturned;
    },

    // get a single road section by rca, import and id
    async getRoadSection(jobId, sectionid) {
      if (!jobId || !sectionid) return null;
      const res = await api.get(`/roadsection/${jobId}/${sectionid}`);
      return res?.data || null;
    },

    // get the unique categories for this import and rca
    // results will be an array of sorted names (uppercase strings)
    async getRoadSectionCategories(jobId) {
      if (!jobId) return [];
      const res = await api.get(`/roadsections/categories/${jobId}`);
      return res?.data || [];
    },

    // get the unique hierarchies for this import and rca
    // results will be an array of objects (name & id), sorted by name
    async getRoadSectionHierarchies(jobId) {
      if (!jobId) return [];
      const res = await api.get(`/roadsections/hierarchies/${jobId}`);
      return res?.data || [];
    },

    async updateRoadSection(jobId, data) {
      if (!data || !jobId) return null;
      console.log('tce.js::updateRoadSection() => update section for job ', jobId, ' with id: ', data.id);
      const res = await api.put(`/roadsection/${jobId}`, data);
      // will return the updated roadsection
      return res?.data ? processRoadSection(res.data) : null;
    },

    /**
     *  all fields except trafficGroupIdToAssign are optional
     *
     *  data: {
     *    trafficGroupIdToAssign: int required,
     *
     *    roadSections: int[]
     *    // filters
     *    roadName: string
     *    trafficGroupdId: Guid
     *    onrcCategoryId: int
     *    cwayHierarchy: string
     *    urbanRural: string
     *  }
     */
    /**
     * Assign specified traffic group either to the list of road sections or
     * to road sections meeting the specified filter
     * @param jobId
     * @param data
     * @returns {Promise<null|*>}
     */
    async assignTrafficGroup(jobId, data) {
      if (!data) return null;
      const res = await api.post(`/trafficgroup/${jobId}`, data);
      return res?.data || null;
    },

    /* -------------------------------------------
                        Jobs
       -------------------------------------------- */
    async getJobs(rcaId) {
      if (!rcaId) return [];
      const res = await api.get(`/jobs/${rcaId}`);
      return res?.data || [];
    },

    async getJobStatuses(rcaId) {
      if (!rcaId) return [];
      const res = await api.get(`/jobs/poll/${rcaId}`);
      return res?.data || [];
    },

    async getJobStatus(jobId) {
      if (!jobId) return [];
      const res = await api.get(`/job/poll/${jobId}`);
      return res?.data || [];
    },

    // get a single job by id
    async getJob(id) {
      if (!id) return null;
      const res = await api.get(`/job/${id}`);
      return res?.data || null;
    },

    async createJob({
      rcaId, lockYear, importId, userId, email, username,
    }) {
      if (!rcaId || !lockYear || !importId) return null;
      console.log(rcaId, lockYear, importId, userId, email, username);
      const res = await api.post('/job', {
        rcaId,
        lockYear,
        importId,
        userId,
        email,
        username,
      });
      return res?.data || null;
    },
    /* -------------------------------------------
                        Results
       -------------------------------------------- */
    // can't use getroadsections since we want to get EVERYTHING
    async retrieveResults(id) {
      if (!id) return null;
      const res = await api.get(`/job/results/${id}`);
      return res?.data || null;
    },

    async calculateRates(id) {
      if (!id) return null;
      const res = await api.get(`/job/startcalculation/${id}`);
      return res?.data || null;
    },

    /* -------------------------------------------
                        Step validation
       -------------------------------------------- */
    async validateStepOne(jobId) {
      if (!jobId) return null;
      const filter = {
        page: 1,
        onlyRoadSectionsWithCounts: true,
        onlyInconsistentTrafficMix: true,
        rows: 99999,
        returnCountOnly: true,
      };
      const res = await api.post(`/roadsections/${jobId}`, filter);
      const pagination = res?.data?.pagination;
      return pagination.totalRows < 1;
    },

    async validateStepTwo(jobId) {
      if (!jobId) return false;
      const filter = {
        page: 1,
        onlyMissingAADT: true,
        rows: 99999,
        returnCountOnly: true,
      };
      const res = await api.post(`/roadsections/${jobId}`, filter);
      const pagination = res?.data?.pagination;
      return pagination.totalRows < 1;
    },

    async validateStepThree(jobId) {
      if (!jobId) return false;
      const filter = {
        page: 1,
        onlyMissingTrafficGroups: true,
        rows: 99999,
        returnCountOnly: true,
      };
      const res = await api.post(`/roadsections/${jobId}`, filter);
      const pagination = res?.data?.pagination;
      return pagination.totalRows < 1;
    },

    async hasAccess(rcaId) {
      if (!rcaId) return null;
      const res = await api.get(`/has-access/${rcaId}`);
      return res.data;
    },

    async downloadTceFile(fileName) {
      const res = await api.post('/file/download/', { path: fileName }, {
        responseType: 'blob',
      });
      return res?.data || null;
    },

  };
};
