/* eslint-disable no-tabs */
import { Decimal } from 'decimal.js';

const average = (array) => array.reduce((a, b) => a + b, 0) / array.length;

const sum = (array) => array.reduce((a, b) => a + b);

const multiplyRows = (a, b) => {
  const result = [];
  for (let i = 0; i < a.length; i++) {
    result.push(a[i] * b[i]);
  }
  return result;
};

// check if every item in an array matches a given value
const allMatch = (array, value) => {
  const different = array.filter((item) => item !== value);
  return different.length < 1;
};

/* sample data - one row of this
Score,	Year,	Average score (Pillars of Success),	Average score (TIO),	Smart buyer self-assessment
45,	2018,	2.3,	1.8,	58,
49,	2020,	2.3,	1.8,	58,
51,	2019,	2.3,	1.8,	58,
*/
const calculateActivityManagement = (averageScore_PillarsOfSuccess, averageScore_TIO, smartBuyerSelfAssessment, DQ_Score) => {
  let scores = [];

  // If any are not assessed (of the first three, then we don't include it).. if it is then we will append it to a list

  // planning quality
  if (averageScore_PillarsOfSuccess > 2.25) {
    scores = scores.concat([1, 1, 1]);
  } else if (averageScore_PillarsOfSuccess > 1.5) {
    scores = scores.concat([2.5, 2.5, 2.5]);
  } else if (averageScore_PillarsOfSuccess > 0) {
    scores = scores.concat([4, 4, 4]);
  }

  // co-investment planning quality
  if (averageScore_TIO > 2.25) {
    scores = scores.concat([1, 1, 1]);
  } else if (averageScore_TIO > 1.5) {
    scores = scores.concat([2.5, 2.5, 2.5]);
  } else if (averageScore_TIO > 0) {
    scores = scores.concat([4, 4, 4]);
  }

  // smart buyer self assessment
  if (smartBuyerSelfAssessment > 65) {
    scores = scores.concat([1]);
  } else if (smartBuyerSelfAssessment > 55) {
    scores = scores.concat([2]);
  } else if (smartBuyerSelfAssessment > 30) {
    scores = scores.concat([3]);
  } else if (smartBuyerSelfAssessment > 0) {
    scores = scores.concat([4]);
  }

  // data quality
  if (DQ_Score > 80) {
    scores = scores.concat([1, 1, 1]);
  } else if (DQ_Score > 60) {
    scores = scores.concat([2, 2, 2]);
  } else if (DQ_Score > 40) {
    scores = scores.concat([3, 3, 3]);
  } else if (DQ_Score > 0) {
    scores = scores.concat([4, 4, 4]);
  }

  // each addition to the list will be a 1-4 grade... or 1, 2.5, 4 for the 3 option things
  // take the average of the list and then give a colour based on the rounded value

  // WATCH OUT - the original calculation used R, which rounds down to nearest even number. (bankers rounding)
  // javascript does not. Javascript uses 'round half towards positive infinity'.
  // e.g.
  // If the score is exactly 2.5, R would say 2 -> 'yellow' (biased towards more positive results in this case)
  // whereas javascript would say 3 -> 'orange' (biased towards more negative results)

  // this will only give 'surprising' results if the score is exactly 0.5, 1.5, 2.5 or 3.5
  // 0.5 -> 0
  // 1.5 -> 2
  // 2.5 -> 2
  // 3.5 -> 4

  // use decimal.js here, with 'bankers rounding'
  Decimal.set({ rounding: Decimal.ROUND_HALF_EVEN });

  // should be 1 - 4
  return new Decimal(average(scores)).round().toNumber();
};

/* sample input data
FY,	NZ/Regin/TA,	STE 2
2016	Westland District	0.95
2017	Westland District	0.96
2018	Westland District	0.96
2019	Westland District	0.96
2020	Westland District	0.94
*/
// Note: calculated over last 5 years!
const calculateAchievementsSlope = (years, crashes) => {
  // default - no data
  let marker = 'none';
  let colour = 'GREY';
  let value = null;

  const colours = ['RED', 'BLACK', 'GREEN'];

  // if there is crashdata and not all the crashes are 0 - otherwise return 'no data'
  if (crashes.length > 0 && !allMatch(crashes, 0)) {
    const a = crashes.length * sum(multiplyRows(years, crashes));
    const b = sum(crashes) * sum(years);
    const c = crashes.length * sum(multiplyRows(years, years));
    const d = sum(years) ** 2;

    // calculate the slope
    let slope = 0;
    if (Math.abs(c - d) > 0) {
      slope = ((a - b) / (c - d));
    }

    // divide by first year value to get percentage
    if (Math.abs(crashes[0]) > 0) {
      slope /= crashes[0];
    }

    // direction of marker
    let negpos = 0;
    if (Math.abs(slope) > 0) {
      negpos = slope / Math.abs(slope);
    }

    // negpos is either -1 or 1
    if (Math.abs(slope) > 0.01) {
      // up or down
      colour = colours[negpos + 1];
      marker = colour === 'RED' ? 'down' : 'up';
    } else {
      // flat yellow marker
      marker = 'flat';
      colour = 'YELLOW';
    }

    value = slope * 100.0;
  }

  return {
    value,
    marker,
    colour,
  };
};

/* sample input data

RoadCouncil,	Total Fatal & Serious,	Year
Westland District	0	2015
Westland District	1	2016
Westland District	1	2019
Westland District	2	2017
Westland District	3	2018
Westland District	5	2020
*/
// Note: calculated over last 5 years!

const calculateSafetySlope = (years, crashes) => {
  // default - no data
  let marker = 'none';
  let colour = 'GREY';
  let value = null;
  const colours = ['GREEN', 'BLACK', 'RED'];

  // if there is crashdata and not all the crashes are 0 - otherwise return 'no data'
  if (crashes.length > 0 && !allMatch(crashes, 0)) {
    const a = crashes.length * sum(multiplyRows(years, crashes));
    const b = sum(crashes) * sum(years);
    const c = crashes.length * sum(multiplyRows(years, years));
    const d = sum(years) ** 2;

    // the displayed number - average crashes
    // If the average is greater than 5, round to the nearest whole number. Otherwise, round to one decimal place.
    value = average(crashes) > 5 ? Math.round(average(crashes)) : Math.round(average(crashes) * 10) / 10;

    // calculate the slope
    let slope = 0;
    if (Math.abs(c - d) > 0) {
      slope = ((a - b) / (c - d));
    }

    // divide by first year value to get percentage
    if (Math.abs(crashes[0]) > 0) {
      slope /= crashes[0];
    }

    // direction of marker
    let negpos = 0;
    if (Math.abs(slope) > 0) {
      negpos = slope / Math.abs(slope);
    }

    // negpos is either -1 or 1
    if (Math.abs(slope) > 0.01) {
      // up or down
      colour = colours[negpos + 1];
      marker = colour === 'RED' ? 'up' : 'down'; // note the inversion here! Up is bad, so make it red!
    } else {
      // flat yellow marker
      marker = 'flat';
      colour = 'YELLOW';
    }
  }
  return {
    value,
    marker,
    colour,
  };
};

/* sample input data */
/*
Database Name,	Overall Investment Score,	Overall Technical Score,	Overall Combined Score,	Combined Audit Score,
Westland District Council,	0.25,	0,	0, ,
the combined audit score is either text or null

*/
const calculateCoInvestorAssuranceInvestment = (combined_audit_score, overall_combined_score, overall_technical_score, technicial_audit_report_date, combined_audit_date) => {
  let score = overall_technical_score;
  let isTechnical = true;
  // if there is a text assesment in this 'combined_audit_score' field, use the combined score instead
  if (combined_audit_score && combined_audit_score !== '') {
    score = overall_combined_score;
    isTechnical = false;
  }
  // Audit date formats are strings and not consistent
  let auditDate = null;
  if (technicial_audit_report_date) {
    const splitDate = technicial_audit_report_date.split('/');
    const date = new Date(Date.UTC(splitDate[2], splitDate[1] - 1, splitDate[0]));
    auditDate = `(${date.toLocaleDateString('en-NZ', { year: 'numeric', month: 'short' })})`;
  } else if (combined_audit_date) {
    const splitDate = combined_audit_date.split('-');
    auditDate = `(${splitDate[0]} 20${splitDate[1]})`;
  }

  // and return the score - colour mapping is done elsewhere
  return {
    score,
    isTechnical,
    auditDate,
  };
};

// returns a budget and a percentage
// note this is now calculated for each 3 year cycle starting from 2022
const calculateDeliveryKPI = (data) => {
  let budget = data && data.length > 0 ? parseInt(data[0].Budget, 10) : 0.0;

  // startYear is every 3 years beginning from 2022
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - (currentYear % 3);
  const endYear = startYear + 2;

  const nodata = !(budget > 0.0);
  let currentPercentage = 0.0;

  if (budget > 0.0) {
    const filtered = data.filter((d) => parseInt(d.Year, 10) >= startYear && parseInt(d.Year, 10) <= endYear);
    const totalBudget = filtered.map((d) => d.FundAll).reduce((prev, next) => prev + next);
    currentPercentage = (totalBudget / budget) * 100;
    budget /= 1000000;
  }

  return {
    budget,
    currentPercentage,
    originalPercentage: data?.at(-1)?.fundingSpent ? data.at(-1).fundingSpent * 100 : null,
    budgetPeriod: `${startYear - 1}-${endYear - 2000}`,
    nodata,
  };
};

const calculateCoinvestInvestmentKPI = (data) => {
  const score = data && data.Overall_Investment_Score ? data.Overall_Investment_Score : '0';
  let visible = true;
  let isTechnical = true;
  // if there is a text assesment in this 'combined_audit_score' field, use the combined score instead
  if (data.Combined_Audit_Score && data.Combined_Audit_Score !== '') {
    visible = false;
    isTechnical = false;
  }

  // and return the score - colour mapping is done elsewhere
  return {
    score,
    visible,
    isTechnical,
  };
};

const calculateCoinvestTechnicalKPI = (data) => {
  let score = data && data.Overall_Technical_Score ? data.Overall_Technical_Score : '0';
  const visible = true;
  let isTechnical = true;
  // if there is a text assesment in this 'combined_audit_score' field, use the combined score instead
  if (data.Combined_Audit_Score && data.Combined_Audit_Score !== '') {
    score = data.Overall_Combined_Score;
    isTechnical = false;
  }

  return {
    score,
    visible,
    isTechnical,
  };
};

const calculateTerritorialActivityKPI = (data) => {
  let nodata = false;

  const pop_change = data.map((r) => r.population_change);
  const values = data.map((r) => r.value);

  const avg = Math.round(average(pop_change)); // the displayed number - average population increase

  if (Math.max(...values) === 0 || Math.max(...pop_change) === 0) {
    nodata = true;
  }

  const firstYear = Math.min(...(data.map((r) => parseInt(r.year, 10))));
  const firstYearPop = data?.find((r) => parseInt(r.year, 10) === firstYear)?.value || null;

  let slope = 0.0;

  // calculate rate of change
  if (firstYearPop !== 0 && avg !== 0) {
    slope = (avg / firstYearPop) * 100;
  }

  return {
    value: avg,
    percentage: slope,
    nodata,
  };
};

// note this is subtly different from the other slope calculations!
const calculateNetworkCharacteristicsKPI = (data) => {
  let nodata = false;
  let slope = 0.0;
  let perc = 0.0;

  const allYears = data.map((row) => parseInt(row.Year, 10)); // this has duplicates
  const uniqueYears = [...new Set(allYears)];

  const lengths = [];

  Decimal.set({ rounding: Decimal.ROUND_HALF_UP });
  uniqueYears.forEach((year) => {
    // yes, we round the numbers before we sum them =^-.-^=
    // we do this to match the results from the PowerBI file/pdfs.
    const rows = data.filter((row) => parseInt(row.Year, 10) === year).map((row) => Decimal.round(row.Length).toNumber());
    lengths.push(sum(rows));
  });

  // Note that the powerbi calculation doubles the entries in 'lengths' by created a summed entry for each 'sealed' and 'unsealed' entry, instead of just working on the
  // combined sealed and unsealed data for each year. The end result is the same.

  if (lengths.length > 0 && !allMatch(lengths, 0)) {
    const a = lengths.length * sum(multiplyRows(uniqueYears, lengths));
    const b = sum(lengths) * sum(uniqueYears);
    const c = lengths.length * sum(multiplyRows(uniqueYears, uniqueYears));
    const d = sum(uniqueYears) ** 2;

    // calculate the slope
    if (Math.abs(c - d) > 0) {
      slope = ((a - b) / (c - d));
    }
    // divide by first year value to get percentage
    if (slope !== 0 && Math.abs(lengths[0]) > 0) {
      perc = (slope / lengths[0]) * 100;
    }
  } else {
    nodata = true;
  }

  return {
    value: slope,
    percentage: perc,
    nodata,
  };
};

const calculateRoadNetworkUseKPI = (data) => {
  let nodata = false;
  let value = 0.0;
  let perc = 0.0;

  const allYears = data.map((row) => parseInt(row.Year, 10)); // this has duplicates
  const uniqueYears = [...new Set(allYears)];

  // value is the result from the last year
  const firstYear = Math.min(...uniqueYears);
  const lastYear = Math.max(...uniqueYears);
  const firstRow = data.find((row) => parseInt(row.Year, 10) === firstYear);
  const lastRow = data.find((row) => parseInt(row.Year, 10) === lastYear);
  if (lastRow) {
    value = lastRow.VKT / 1000000;
  }

  const VKTS = [];
  uniqueYears.forEach((year) => {
    const rows = data.filter((row) => parseInt(row.Year, 10) === year).map((row) => row.VKT);
    VKTS.push(sum(rows) / 1000000);
  });

  let slope = 0.0;

  // slope
  const a = VKTS.length * sum(multiplyRows(allYears, VKTS));
  const b = sum(VKTS) * sum(allYears);
  const c = VKTS.length * sum(multiplyRows(allYears, allYears));
  const d = sum(allYears) ** 2;

  // calculate the slope
  if (Math.abs(c - d) > 0) {
    slope = ((a - b) / (c - d));
  }

  // now calculate the perc change over last 5 years - divide by first year value
  if (slope !== 0.0) {
    perc = (slope / (firstRow.VKT / 1000000)) * 100;
  }

  if (VKTS.length > 0 && allMatch(VKTS, 0)) {
    nodata = true;
  }

  return {
    value,
    percentage: perc,
    nodata,
  };
};

const calculatePublicTransportKPI = (unfilteredData) => {
  // default - no data
  let marker = 'none';
  let colour = 'GREY';
  let value = null;

  // filter out any entries where the kms are 0
  const data = unfilteredData.filter((row) => row.PassengerKilometresKm !== null && row.PassengerKilometresKm > 0);

  // data all zeroes or only 1 value remains
  // can't calculate slope on only 1 value :)
  if (data.length < 2) {
    return {
      value,
      colour,
      marker,
    };
  }

  const allYears = data.map((row) => parseInt(row.Year, 10)); // this has duplicates
  const uniqueYears = [...new Set(allYears)];

  // value is the result from the last year
  const firstYear = Math.min(...uniqueYears);
  const lastYear = Math.max(...uniqueYears);
  const firstRow = data.find((row) => parseInt(row.Year, 10) === firstYear);
  const lastRow = data.find((row) => parseInt(row.Year, 10) === lastYear);
  if (lastRow) {
    value = lastRow.PassengerKilometresKm / 1000000;
  }

  const kms = [];
  uniqueYears.forEach((year) => {
    const rows = data.filter((row) => parseInt(row.Year, 10) === year).map((row) => row.PassengerKilometresKm);
    kms.push(sum(rows) / 1000000);
  });

  let slope = 0.0;

  // slope
  const a = kms.length * sum(multiplyRows(uniqueYears, kms));
  const b = sum(kms) * sum(uniqueYears);
  const c = kms.length * sum(multiplyRows(uniqueYears, uniqueYears));
  const d = sum(uniqueYears) ** 2;

  // calculate the slope
  if (Math.abs(c - d) > 0) {
    slope = ((a - b) / (c - d));
  }

  // now calculate the perc change over last 5 years - divide by first year value
  if (slope !== 0.0) {
    value = (slope / (firstRow.PassengerKilometresKm / 1000000)) * 100;
  }

  // direction of marker
  let negpos = 0;
  if (Math.abs(slope) > 0) {
    negpos = slope / Math.abs(slope);
  }

  const colours = ['RED', 'BLACK', 'GREEN'];

  // negpos is either -1 or 1
  if (Math.abs(value) > 0.01) {
    // up or down
    colour = colours[negpos + 1];
    marker = colour === 'RED' ? 'down' : 'up';
  } else {
    // flat yellow marker
    marker = 'flat';
    colour = 'YELLOW';
  }

  return {
    value,
    colour,
    marker,
  };
};

// Calculate the average year range for the last x years based off the latest lock year
const calculateAverageYearRange = (latestLockYear, numberOfYears) => {
  if (!latestLockYear) return null;
  const endYear = parseInt(latestLockYear.split('/')[0], 10);
  const startYear = endYear - numberOfYears + 1;
  const endFinancialYear = latestLockYear;
  const startFinancialYear = `${startYear}/${startYear - 1999}`;
  return `${startFinancialYear} to ${endFinancialYear}`;
};

export {
  calculateActivityManagement,
  calculateAchievementsSlope,
  calculateSafetySlope,
  calculateCoInvestorAssuranceInvestment,
  calculateDeliveryKPI,
  calculateCoinvestInvestmentKPI,
  calculateCoinvestTechnicalKPI,
  calculateTerritorialActivityKPI,
  calculateNetworkCharacteristicsKPI,
  calculateRoadNetworkUseKPI,
  calculatePublicTransportKPI,
  calculateAverageYearRange,
};
