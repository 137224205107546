import { useFilteredCategories } from '@src/modules/onf/hooks/useFilteredCategories';
import CollectiveRiskReport from './components/CollectiveRiskReport';
import PersonalRiskReport from './components/PersonalRiskReport';

export default function Hsp2({
  filters, reportData, selectedToggleOption, networkSelector,
}) {
  const filteredCategories = useFilteredCategories(filters);
  if (selectedToggleOption?.report_type?.[0] === 'Personal') {
    return (
      <PersonalRiskReport
        filteredCategories={filteredCategories}
        filters={filters}
        reportData={{ ...reportData, data: [...reportData.data.filter((x) => x.measureCode === selectedToggleOption?.measureCode?.[0])] }}
        networkSelector={networkSelector}
      />
    );
  }

  return (
    <CollectiveRiskReport
      filteredCategories={filteredCategories}
      filters={filters}
      reportData={{ ...reportData, data: [...reportData.data.filter((x) => x.measureCode === selectedToggleOption?.measureCode?.[0])] }}
      networkSelector={networkSelector}
    />
  );
}
