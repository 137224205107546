const luxon = require('luxon');

const formatDate = (date) => {
  if (!date) return null;
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat('en-NZ', options).format(new Date(date));
};

// Find and convert dates in a string to financial/lock years
// accepted inputs:
// somethingthathasnoyearinit
// somethingsomethingsomething{lockyearyyyy/yy}something
// somethingsomethingsomething{yyyy}something
// somethingsomethingsomething{MM-YYYY}something
// somethingsomethingsomething{MMM-yyyy}something
// Output:
// yyyy/yy
function convertToFinancialYear(dateString) {
  if (!dateString) return null;
  let foundDate;

  // Extract potential date substrings from the input
  const dateMatch = dateString.match(/(\d{4}\/\d{2})|(\d{2}-\d{4})|(\d{1,2}\/\d{2}\/\d{4})|([a-zA-Z]{3}-\d{4})|(\d{4})/);

  if (!dateMatch) return ''; // No recognizable date found

  const extractedDate = dateMatch[0];

  // Check for lock year YYYY/YY
  if (/\d{4}\/\d{2}/.test(extractedDate)) {
    foundDate = luxon.DateTime.fromFormat(extractedDate.substring(0, 4), 'yyyy');
    foundDate = foundDate.set({ month: 7 });
  } else if (/\d{2}-\d{4}/.test(extractedDate)) {
    // Check for MM-YYYY format
    foundDate = luxon.DateTime.fromFormat(extractedDate, 'MM-yyyy');
  } else if (/\d{1,2}\/\d{2}\/\d{4}/.test(extractedDate)) {
    // Check for d/MM/yyyy format
    foundDate = luxon.DateTime.fromFormat(extractedDate, 'd/MM/yyyy');
  } else if (/[a-zA-Z]{3}-\d{4}/.test(extractedDate)) {
    // Check for MMM-yyyy format like Dec-2023
    foundDate = luxon.DateTime.fromFormat(extractedDate, 'MMM-yyyy');
  } else if (/\d{4}/.test(extractedDate)) {
    // Check for just the year (yyyy)
    foundDate = luxon.DateTime.fromFormat(extractedDate, 'yyyy');
    foundDate = foundDate.set({ month: 7 });
  }

  // If no valid date was found, return an empty string
  if (!foundDate) return '';

  // Determine the financial year based on the month
  if (foundDate.month <= 6) {
    return `${foundDate.year - 1}/${foundDate.year.toString().slice(-2)}`;
  }
  return `${foundDate.year}/${(foundDate.year + 1).toString().slice(-2)}`;
}

export { convertToFinancialYear, formatDate };
