import {
  Box, HStack, Text, Tooltip,
} from '@chakra-ui/react';

export default function JobStatusBox({ job }) {
  let text = job ? 'TCE Loaded' : '';
  let date = job?.createdAt || null;
  if (job?.status === 'COMPLETED' || job?.status === 'RESULTS') {
    text = 'TCE Completed';
    date = job?.completedAt;
  }

  const textDate = date ? new Date(date).toLocaleDateString('en-GB') : '';
  const tooltipDate = date ? `${new Date(date).toDateString()} ${new Date(date).toLocaleTimeString('en-GB')}` : '';

  return (
    <Box mt={6}>
      <HStack justify="space-between">
        <Text>
          {text}
        </Text>
        <Tooltip label={tooltipDate} placement="top" hasArrow>
          <Text fontWeight="bold">{textDate}</Text>
        </Tooltip>
      </HStack>
    </Box>
  );
}
