import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem,
  AccordionPanel, Box, Button, Icon, Progress, Spacer, Spinner, Text, VStack,
} from '@chakra-ui/react';
import { CalculateRates, PollSingleJob } from '@src/modules/tce/src/context/jobs';
import { selectedJobIdState } from '@src/modules/tce/src/state/tce';
import { FiSettings } from 'react-icons/fi';
import { useRecoilValue } from 'recoil';

function Step4({ canRunCalculation }) {
  const { job } = PollSingleJob();
  const jobId = useRecoilValue(selectedJobIdState);
  const { mutate: calculateTrafficGroupRates } = CalculateRates({});
  const startTheJob = () => {
    calculateTrafficGroupRates(jobId);
  };

  if (!job) {
    return (
      <Box
        background="gray.50"
        p={12}
        borderRadius="md"
      >
        <VStack h="full" fontSize="20px">
          <Spinner />
        </VStack>
      </Box>
    );
  }

  if (!canRunCalculation) {
    return (
      <Box
        background="gray.50"
        p={12}
        borderRadius="md"
      >
        <Text fontSize="20px">
          Please complete the previous steps before calculating the Traffic Count Estimates.
        </Text>
      </Box>
    );
  }

  return (
    <Box
      background="gray.50"
      p={12}
      borderRadius="md"
    >
      {job.status === 'RESULTS' && (
        <VStack h="full" fontSize="20px">
          <Spacer />
          <Text>The Traffic count estimation for this import has been completed.</Text>
          <Spacer />
          <Text>Go to the next page to view and export your results.</Text>
          <Spacer />
        </VStack>
      )}
      {job.status === 'FAILED' && (
        <>
          <VStack h="full" fontSize="20px">
            <Spacer />
            <Text>There was an issue processing your estimate.</Text>
            <Spacer />
            <Text>Please contact Reg Insights support.</Text>
            <Spacer />
          </VStack>
          <VStack h="full" fontSize="14px" align="stretch">
            <Spacer />
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {job.errorMessage}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Spacer />
          </VStack>
        </>
      )}
      {job.status !== 'RESULTS' && job.status !== 'FAILED' && job.status !== 'READY' && (
        <>
          <VStack h="full" fontSize="20px">
            <Spacer />
            <Text>The Traffic count estimation for this import is being calculated.</Text>
            <Spacer />
            <Text color="gray.500" fontSize="16px" border="solid orange 1px" borderRadius={10} padding={3}>
              <Icon
                as={FiSettings}
                mr={2}
                className="makeItSpin"
                boxSize={4}
                verticalAlign="middle"
                marginTop="-3px"
              />
              {job.status}
              {' '}
              <span>
                {job.percentCompleted}
                %
              </span>
            </Text>
            <Spacer />
          </VStack>
          {job.percentCompleted > 0
            && (
            <VStack h="full" fontSize="20px" align="stretch">
              <Spacer />
              <Progress
                value={job.percentCompleted}
                colorScheme="orange"
                size="md"
                backgroundColor="gray.100"
                hasStripe
              />
            </VStack>
            )}
        </>
      )}
      {job.status === 'READY' && (
        <VStack h="full" fontSize="20px">
          <Spacer />
          <Text>The Traffic count estimation for this import is ready to be calculated.</Text>
          <Spacer />
          <Button onClick={startTheJob}>Start the calculation</Button>
          <Spacer />
        </VStack>
      )}
    </Box>
  );
}

export default Step4;
