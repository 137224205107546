import { useState, useEffect } from 'react';
import {
  Input, HStack, Text,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';

function DateRangePicker({ onDateChange }) {
  // Get today's date and the date 12 months ago using Luxon
  const today = DateTime.now().toISODate();
  const threeMonthsAgo = DateTime.now().minus({ months: 3 }).toISODate();

  const [startDate, setStartDate] = useState(threeMonthsAgo);
  const [endDate, setEndDate] = useState(today);

  // Function to add one day to the start date
  const getNextDay = (dateString) => DateTime.fromISO(dateString).plus({ days: 1 }).toISODate();

  // Effect to trigger callback when both dates are set
  useEffect(() => {
    if (startDate && endDate) {
      onDateChange({ startDate, endDate });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <HStack align="center">
      <Text fontSize="sm">From</Text>
      <Input
        type="date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
        placeholder="Start Date"
        max={today}
        size="sm"
      />
      <Text fontSize="sm">to</Text>
      <Input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        placeholder="End Date"
        min={startDate ? getNextDay(startDate) : undefined}
        max={today}
        size="sm"
      />
    </HStack>
  );
}

export default DateRangePicker;
