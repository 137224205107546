import {
  Circle, Icon, Spinner, Text, Tooltip, VStack,
} from '@chakra-ui/react';
import { FiAlertTriangle, FiCheck } from 'react-icons/fi';

export function Step({
  label, status, onClick, index, isActive, errorText,
}) {
  let colorScheme = 'gray.200';
  let innerComponent = (
    <Text
      fontSize="2xl"
      color="white"
    >
      {index + 1}
    </Text>
  );

  if (isActive) {
    colorScheme = 'brand.blue.600';
  } else if (status === 'loading') {
    innerComponent = (
      <Spinner color="white" />
    );
  } else if (status === 'error') {
    colorScheme = 'red.600';
    innerComponent = (
      // <Tooltip label={errorText} placement="bottom-end">
      <Icon boxSize={6} as={FiAlertTriangle} color="white" />
      // </Tooltip>
    );
  } else if (status === 'complete') {
    colorScheme = 'green.600';
    innerComponent = (
      <Icon boxSize={6} as={FiCheck} color="white" />
    );
  }

  return (
    <VStack width="80px">
      <Tooltip
        bg="red.600"
        isDisabled={status !== 'error'}
        // isDisabled={!isError}
        label={errorText}
        placement="bottom"
      >
        <Circle
          size="60px"
          bg={colorScheme}
          mx={12}
          onClick={onClick}
          style={{
            cursor: 'pointer',
            // cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
        >
          {innerComponent}
        </Circle>
      </Tooltip>
      <Text
        textAlign="center"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </VStack>
  );
}
