import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Box,
  Badge,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  IconButton,
  Button,
  TableContainer,
  Checkbox,
  Stack,
} from '@chakra-ui/react';
import CsvDownloader from 'react-csv-downloader';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';
import {
  GoogleMap, useJsApiLoader, Marker, InfoWindow,
} from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { useRecoilValue } from 'recoil';
import { useGetTtmSiteInspectionList } from '@src/modules/performance/context/temporary-traffic-management-api-hooks';
import EmptyState from '@src/components/shared/EmptyState';
import DateRangePicker from '@src/modules/performance/components/DateRangePicker';
import { TrafficLightColours, TTM_SITE_STATUSES } from '@src/modules/performance/shared/constants';
import IndicatorLegend from '@src/modules/performance/components/IndicatorLegend';
import {
  FiCalendar, FiDownloadCloud, FiUser, FiX,
} from 'react-icons/fi';
import MapMarkerSVG from './MapMarkerSVG';

function convertToLatLngLiteral(latLngString) {
  if (!latLngString) return null;
  const [lat, lng] = latLngString.split(',').map(Number);
  return { lat, lng };
}

const STATUS_COLORS = {
  1: TrafficLightColours.GREY,
  2: TrafficLightColours.GREEN,
  3: TrafficLightColours.ORANGE,
  4: TrafficLightColours.RED,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

function insertLineBreaks(str, wordsPerLine) {
  const words = str.split(' ');
  let result = '';

  for (let i = 0; i < words.length; i += wordsPerLine) {
    result += `${words.slice(i, i + wordsPerLine).join(' ')}\n`;
  }

  return result;
}

function TtmSiteInspectionView({ isOpen, onClose }) {
  const config = useConfig();
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const { isLoaded: googleApiIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GOOGLE_API_KEY,
  });
  const [rcaLocation, setRcaLocation] = useState(null);
  const [error, setError] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  const addressLookup = currentDatabase?.name === 'State Highways'
    ? 'New Zealand'
    : `${currentDatabase?.name?.replace('Council', '').replace('Transport', '').replace('City', '').trim()}, New Zealand`;
  const zoomLevel = currentDatabase?.name.includes('City') ? 11 : 9;

  const [status, setStatus] = useState([...TTM_SITE_STATUSES.map((siteStatus) => siteStatus.id.toString())]);
  const [dateRange, setDateRange] = useState(null);
  const { data, isLoading, error: dataError } = useGetTtmSiteInspectionList({ ...dateRange });
  const filteredData = data?.filter((site) => status.length === 0 || status.includes(site.SiteStatus));
  const statusCounts = data?.reduce((acc, site) => {
    const statusLabel = TTM_SITE_STATUSES.find((x) => x.id.toString() === site.SiteStatus)?.label;
    if (!statusLabel) return acc;
    acc[statusLabel] = (acc[statusLabel] || 0) + 1;
    return acc;
  }, {});

  const csvData = filteredData?.map((site) => ({
    RCA: currentDatabase?.name,
    Status: `"${TTM_SITE_STATUSES.find((x) => x.id.toString() === site.SiteStatus)?.label}"`,
    Address: `"${site.Address}"`,
    Description: `"${site.Description}"`,
    GPS: `"${site.Location}"`,
    DateInspected: `"${new Date(site.InspectionDate).toLocaleDateString('en-NZ')}"`,
    InspectedBy: `"${site.UserName}"`,
  }));

  // Fetch RCA location
  useEffect(() => {
    const handleFetchLocation = async () => {
      if (googleApiIsLoaded && addressLookup) {
        try {
          const results = await geocodeByAddress(addressLookup);
          const location = results[0]?.geometry?.location;
          if (location) {
            setRcaLocation({ lat: location.lat(), lng: location.lng() });
          } else {
            setError('Location not found');
          }
        } catch (err) {
          console.error('Error retrieving location:', err);
          setError('Failed to geocode address');
        }
      }
    };
    handleFetchLocation();
  }, [googleApiIsLoaded, addressLookup]);

  const mapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const handleMarkerClick = (markerId) => {
    setActiveMarker(markerId);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent height="100vh">
        <ModalHeader>
          Site Inspections for
          {' '}
          {currentDatabase?.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" flex="1" pb={6}>
          <Stack direction={{ base: 'column', lg: 'row' }} mb={6} width="100%" justify="space-between">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              align={{ base: 'flex-start', md: 'center' }}
              spacing={6}
              background="gray.50"
              p={4}
              borderRadius="base"
              width={{ base: '100%', lg: 'fit-content' }}
              height="fit-content"
            >
              <DateRangePicker onDateChange={handleDateChange} />
              <Stack direction={{ base: 'column', md: 'row' }}>
                {TTM_SITE_STATUSES.map((siteStatus) => (
                  <Checkbox
                    key={siteStatus.id}
                    size="sm"
                    isChecked={status.includes(siteStatus.id.toString())}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setStatus((prev) => [...prev, siteStatus.id.toString()]);
                      } else {
                        setStatus((prev) => prev.filter((id) => id !== siteStatus.id.toString()));
                      }
                    }}
                  >
                    {siteStatus.label}
                  </Checkbox>
                ))}
              </Stack>
            </Stack>
            <Stack direction="row" spacing={6} align="flex-start">
              <VStack>
                <Text fontSize="2xl" fontWeight="bold">{data?.length}</Text>
                <Text fontSize="xs">Total Inspections</Text>
              </VStack>
              {statusCounts && Object.keys(statusCounts).map((statusLabel) => (
                <VStack key={statusLabel}>
                  <Text fontSize="2xl" fontWeight="bold">{statusCounts[statusLabel]}</Text>
                  <Text
                    fontSize="xs"
                    whiteSpace="pre-line"
                  >
                    {insertLineBreaks(statusLabel, 2)}
                  </Text>
                </VStack>
              ))}
            </Stack>

          </Stack>
          {isLoading && (
            <Flex justify="center" align="center" flex="1">
              <Spinner />
              <Text ml={4} fontSize="sm">Loading Site Inspections</Text>
            </Flex>
          )}
          {googleApiIsLoaded && rcaLocation && !isLoading && !error && !dataError && (
            <Tabs
              variant="soft-rounded"
              colorScheme="brand.orange"
              display="flex"
              flex="1"
              flexDir="column"
            >
              <TabList
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <HStack spacing={2} flex="1">
                  <Tab>
                    Map View
                  </Tab>
                  <Tab>
                    List View
                  </Tab>
                </HStack>
                <CsvDownloader
                  filename={`${currentDatabase?.name} - Site Inspections (${dateRange?.startDate} to ${dateRange?.endDate})`}
                  extension=".csv"
                  separator=","
                  datas={csvData}
                >
                  <Button
                    onClick={() => {}}
                    leftIcon={<Icon as={FiDownloadCloud} boxSize={4} />}
                    size="sm"
                    variant="ghost"
                    isDisabled={filteredData?.length === 0}
                  >
                    Download CSV
                  </Button>
                </CsvDownloader>
              </TabList>
              <TabPanels display="flex" flex="1">
                <TabPanel display="flex" flex="1" p={0} pt={4}>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={rcaLocation}
                    zoom={zoomLevel}
                    options={mapOptions}
                  >
                    <Box
                      position="absolute"
                      right={4}
                      top={4}
                      background="white"
                      boxShadow="base"
                      borderRadius="base"
                      p={4}
                    >
                      <IndicatorLegend items={TTM_SITE_STATUSES.map((siteStatus) => ({ label: siteStatus.label, color: STATUS_COLORS[siteStatus.id] }))} />
                    </Box>
                    {filteredData?.map((site) => {
                      const siteLocation = convertToLatLngLiteral(site.Location);
                      return (
                        siteLocation && (
                          <Marker
                            key={site.Id}
                            position={siteLocation}
                            title={site.Address}
                            onClick={() => handleMarkerClick(site.Id)}
                            icon={{
                              url: MapMarkerSVG({ dotColor: STATUS_COLORS[site.SiteStatus], size: 40 }),
                              scaledSize: new window.google.maps.Size(40, 40),
                            }}
                          >
                            {activeMarker === site.Id && (
                              <InfoWindow
                                position={siteLocation}
                                onCloseClick={handleInfoWindowClose}
                              >
                                <Box>
                                  <IconButton
                                    icon={<Icon as={FiX} color="gray.500" boxSize={4} />}
                                    onClick={handleInfoWindowClose}
                                    position="absolute"
                                    top="8px"
                                    right="8px"
                                    size="sm"
                                    backgroundColor="white"
                                    zIndex="1"
                                    colorScheme="gray"
                                    tabIndex={-1}
                                  />
                                  <VStack spacing={2} align="flex-start">
                                    <Badge backgroundColor={STATUS_COLORS[site.SiteStatus]} color="white">
                                      {TTM_SITE_STATUSES.find((x) => x.id.toString() === site.SiteStatus)?.label}
                                    </Badge>
                                    <Text fontSize="md" fontWeight="bold" whiteSpace="pre-line">
                                      {site.Address}
                                    </Text>
                                    {site.Description && (
                                    <Text fontSize="sm" whiteSpace="pre-line">{site.Description}</Text>
                                    )}
                                    <HStack align="center">
                                      <Icon as={FiCalendar} boxSize={4} />
                                      <Text fontSize="sm">{new Date(site.InspectionDate).toLocaleDateString('en-NZ')}</Text>
                                    </HStack>
                                    <HStack align="center">
                                      <Icon as={FiUser} boxSize={4} />
                                      <Text fontSize="sm">{site.UserName}</Text>
                                    </HStack>
                                  </VStack>
                                </Box>
                              </InfoWindow>
                            )}
                          </Marker>
                        )
                      );
                    })}
                  </GoogleMap>
                </TabPanel>
                <TabPanel width="100%" p={0} pt={4}>
                  <TableContainer
                    p={4}
                    width="100%"
                    border="1px solid"
                    borderColor="gray.100"
                    borderRadius="md"
                  >
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Status</Th>
                          <Th>Address</Th>
                          <Th>Description</Th>
                          <Th>GPS Location</Th>
                          <Th>Date Inspected</Th>
                          <Th>Inspected By</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredData?.map((site) => (
                          <Tr key={site.Id}>
                            <Td>
                              <Badge backgroundColor={STATUS_COLORS[site.SiteStatus]} color="white">
                                {TTM_SITE_STATUSES.find((x) => x.id.toString() === site.SiteStatus)?.label}
                              </Badge>
                            </Td>
                            <Td>{site.Address}</Td>
                            <Td>{site.Description}</Td>
                            <Td>{site.Location}</Td>
                            <Td>{new Date(site.InspectionDate).toLocaleDateString('en-NZ')}</Td>
                            <Td>{site.UserName}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
          {(error || dataError) && (
            <EmptyState
              title="Error Loading Inspections"
              message={(
                <Text color="gray.400">There was an error loading the site inspections. Please check later.</Text>
              )}
            />
          )}
          {filteredData && filteredData.length === 0 && (
            <EmptyState
              title="No Data"
              message={(
                <Text color="gray.400">There were no site inspections found.</Text>
              )}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TtmSiteInspectionView;
