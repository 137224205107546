import {
  Button,
  ModalBody, ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner, Text, VStack,
} from '@chakra-ui/react';
import { numTotalRowsFilteredState, numTotalRowsSelectedState } from '@tce/state/tce';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

function FilterConfirmationModalContent({
  onAssignToFilter,
  onAssignToSelected,
  trafficGroup,
}) {
  const totalRowsSelected = useRecoilValue(numTotalRowsSelectedState);
  const totalRowsFiltered = useRecoilValue(numTotalRowsFilteredState);
  const [showSpinner, setShowSpinner] = useState(false);
  const onAssign = (type) => {
    setShowSpinner(true);
    if (type === 'filtered') {
      onAssignToFilter();
    } else {
      onAssignToSelected();
    }
  };

  return (
    <ModalContent>
      <ModalHeader>{showSpinner ? 'Assign Traffic Group' : 'Assign Traffic Group'}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {!showSpinner && (
        <VStack>
          <Text>
            Out of a total of
            {' '}
            {totalRowsFiltered ?? 0}
            {' '}
            Carriageways that match your specified filter, you have selected
            {' '}
            {totalRowsSelected ?? 0}
            {' '}
            Carriageway Sections.
          </Text>
          <Text>
            Would you like to assign
            {' '}
            {trafficGroup.code}
            {' '}
            to all
            {' '}
            {totalRowsFiltered ?? 0}
            {' '}
            Carriageway Sections?
          </Text>

        </VStack>
        )}
        {showSpinner && (
          <Spinner />
        )}
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button
          variant="link"
          colorScheme="gray"
          onClick={() => onAssign('selected')}
        >
          No, only update selected
        </Button>
        <Button onClick={() => onAssign('filtered')}>Yes, update all</Button>
      </ModalFooter>
    </ModalContent>
  );
}

export default FilterConfirmationModalContent;
