import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
} from '@chakra-ui/react';

import { getContrastColor } from '@src/utils/color';
import CollapsedContent from '../../../shared/CollapsedContent';

export default function DataTable({ data }) {
  const theme = useTheme();

  return (
    <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
      <Box border="1px solid" borderColor="gray.100" my={4}>
        <TableContainer>
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">Category</Th>
                <Th background="gray.50">Network</Th>
                <Th background="gray.50">Personal Risk per 100 million VKT</Th>
                <Th background="gray.50">Collective Risk per 1000 km</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item) => item.data.map(({ rcaName, personalRisk100M, collectiveRisk }, i) => (
                <Tr key={`cat_${i}`}>
                  {
                        i === 0
                        && (
                        <Td
                          rowSpan={item.data.length}
                          color={getContrastColor(theme.colors.onfCategory[item.name].base)}
                          background={theme.colors.onfCategory[item.name].base}
                          borderBottom="2px solid"
                          borderColor={i === 0 ? theme.colors.onfCategory[item.name].base : 'gray.100'}
                          fontWeight="bold"
                        >
                          {item.name}
                        </Td>
                        )
                      }
                  <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{rcaName}</Td>
                  <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{personalRisk100M}</Td>
                  <Td borderBottom="2px solid" borderColor={i === item.data.length - 1 ? theme.colors.onfCategory[item.name].base : 'gray.100'}>{collectiveRisk}</Td>
                </Tr>
              )))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </CollapsedContent>
  );
}
