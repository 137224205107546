export const categories = [
  {
    description: 'Transit Corridors',
    streetFamily: 'Urban',
    displaySequence: 1,
  },
  {
    description: 'Urban Connectors',
    streetFamily: 'Urban',
    displaySequence: 2,
  },
  {
    description: 'City Hubs',
    streetFamily: 'Urban',
    displaySequence: 3,
  },
  {
    description: 'Activity Streets',
    streetFamily: 'Urban',
    displaySequence: 4,
  },
  {
    description: 'Main Streets',
    streetFamily: 'Urban',
    displaySequence: 5,
  },
  {
    description: 'Local Streets',
    streetFamily: 'Urban',
    displaySequence: 6,
  },
  {
    description: 'Civic Spaces',
    streetFamily: 'Urban',
    displaySequence: 7,
  },
  {
    description: 'Interregional Connectors',
    streetFamily: 'Rural',
    displaySequence: 8,
  },
  {
    description: 'Stopping Places',
    streetFamily: 'Rural',
    displaySequence: 9,
  },
  {
    description: 'Rural Connectors',
    streetFamily: 'Rural',
    displaySequence: 10,
  },
  {
    description: 'Peri-urban Roads',
    streetFamily: 'Rural',
    displaySequence: 11,
  },
  {
    description: 'Rural Roads',
    streetFamily: 'Rural',
    displaySequence: 12,
  },
];
