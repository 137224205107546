const getRangeOfPoints = (start, end, quantity) => {
  const result = [];
  const qty = quantity - 1;

  for (let i = 0; i < qty; i++) {
    const position = i / qty;
    const point = start + (end - start) * position;

    result.push(parseFloat(point).toFixed(1));
  }

  result.push(end);

  return result;
};

const getPercent = (val, total) => parseFloat(((val / total) * 100).toFixed(1));

const roundToDecimal = (val, roundTo) => parseFloat((val).toFixed(roundTo));

const getTrendDirection = (trendB, trendM, trendCount) => {
  if (trendB === (trendB + (trendM * trendCount))) return 'flat';
  if (trendB < trendB + (trendM * trendCount)) return 'up';
  return 'down';
};

const convertBubbleRange = (value, r1, r2 = [1, 8]) => {
  // Lets work out whether to use original range or not
  const diff = r1[1] - r1[0];
  const range = diff < 15 ? [r1[0], r1[1]] : r2;
  if (diff === 0) return r2[1];
  return ((value - r1[0]) * (range[1] - range[0])) / (r1[1] - r1[0]) + range[0];
};

const currencyFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
});

export {
  getRangeOfPoints, getPercent, roundToDecimal, convertBubbleRange, getTrendDirection, currencyFormatter,
};
