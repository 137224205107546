const textStyles = {
  // summary widget
  summaryValue: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  summaryTitle: {
    fontSize: '11px',
    textAlign: 'left',
  },
  // card widgets
  cardHeader: {
    fontSize: '15px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  cardText: {
    fontSize: '48px',
  },
  cardSubtext: {
    fontSize: '12px',
    textAlign: 'center',
  },
  cardLink: {
    fontSize: '12px',
    color: 'brand.blue.600',
  },
  reportSectionHeader: {
    fontWeight: 'bold',
    color: 'brand.blue.600',
  },
};

export default textStyles;
