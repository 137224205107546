import { Grid } from '@chakra-ui/react';
import { AssetSelectorCard } from './AssetSelectorCard';

function AssetSelector({
  assets, selectedAsset, setSelectedAsset,
}) {
  return (
    <Grid
      gap={2}
      templateColumns="repeat(6, 1fr)"
    >
      {Object.entries(assets).map(([title, assetList]) => (
        <AssetSelectorCard
          key={`${title}-${assetList.map((a) => a.subType).join('-')}`}
          isSelected={title === selectedAsset?.label}
          subType={selectedAsset?.subType}
          title={title}
          onSetSelection={setSelectedAsset}
          assetList={assetList}
        />
      ))}
    </Grid>
  );
}

export default AssetSelector;
