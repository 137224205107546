import { InfoBox } from '@react-google-maps/api';
import { Box, Text } from '@chakra-ui/react';

function PopupBox({
  title, value, fontSize, position, visible, subtext,
}) {
  return (
    <InfoBox
      visible={visible}
      position={position}
      options={{
        closeBoxURL: '', // no close button
      }}
    >
      <Box
        backgroundColor="white"
        padding={5}
        opacity={0.75}
        borderRadius={10}
        minWidth={200}
        textAlign="center"
      >
        <Text fontSize={12}>{title}</Text>
        <Text
          fontSize={fontSize}
          fontWeight="bold"
        >
          {value}
        </Text>
        {subtext && (<Text fontSize={10}>{subtext}</Text>)}
      </Box>
    </InfoBox>
  );
}

export default PopupBox;
