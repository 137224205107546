import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ReportsList from '@src/modules/regional-reporting/pages/ReportsList';
import SafetyReport from '@src/modules/regional-reporting/pages/reports/Safety';
import SmoothTravelReport from '../pages/reports/SmoothTravel';
import AccessToJobsReport from '../pages/reports/AccessToJobs';
import GreenhouseGasReport from '../pages/reports/GreenhouseGas';
import FreightReport from '../pages/reports/Freight';
import ActiveTravelReport from '../pages/reports/ActiveTravel';
import ForecastMaintenanceSpend from '../pages/reports/ForecastMaintenanceSpend';
import Resilience from '../pages/reports/Resilience';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/transport-outcomes/safety`} component={SafetyReport} />
      <Route path={`${path}/transport-outcomes/smooth-travel`} component={SmoothTravelReport} />
      <Route path={`${path}/transport-outcomes/access-to-jobs`} component={AccessToJobsReport} />
      <Route path={`${path}/transport-outcomes/greenhouse-gas-emissions`} component={GreenhouseGasReport} />
      <Route path={`${path}/transport-outcomes/freight`} component={FreightReport} />
      <Route path={`${path}/transport-outcomes/active-travel`} component={ActiveTravelReport} />
      <Route path={`${path}/transport-outcomes/forecast-maintenance-spend`} component={ForecastMaintenanceSpend} />
      <Route path={`${path}/transport-outcomes/resilience`} component={Resilience} />
      <Route path={`${path}/transport-outcomes/`} component={ReportsList} />
    </Switch>
  );
}
