import { Pie } from 'react-chartjs-2';
import { Text, Box, Center } from '@chakra-ui/react';

function PopupPieChart({ settings, title }) {
  const width = 200;
  const height = 200;

  const data = {
    labels: settings.labels,
    datasets: [
      {
        data: settings.data,
        backgroundColor: settings.colours,
        borderWidth: 0, // this will hide white border line
      },
    ],
  };
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        formatter: (value) => value + settings.postfix,
      },
      legend: {
        position: 'bottom',
      },
    },
    hoverOffset: 0, // make the slice popout a little when you hover over it
    offset: 5, // separate the slices by a bit
  };

  return (
    <Box mb={20}>
      <Center>
        <Text textStyle="cardHeader">
          {title}
        </Text>
      </Center>
      <Center>
        <Pie data={data} options={options} width={width} height={height} />
      </Center>
    </Box>
  );
}

export default PopupPieChart;
