import {
  Icon, Flex, Text, Box, Center,
} from '@chakra-ui/react';
import {
  TrafficLightColours,
  ChartColours,
} from '../charts/ChartColours';

function TinyCircleIcon(props) {
  return (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
}

// vertical and chartColours are booleans
function Legend({
  values, colours, vertical, chartColours, title, ...props
}) {
  return (
    <Box {...props}>
      {title && (<Text fontSize={12} fontWeight="bold" mb={5}>{title}</Text>)}
      <Center>
        <Flex flexDirection={vertical ? 'column' : 'row'}>
          {values.map((text, index) => (
            <Flex key={`legend${index}`}>
              <TinyCircleIcon mt={0} mr={3} color={chartColours ? ChartColours[colours[index]] : TrafficLightColours[colours[index]]} size={32} />
              <Text mr={10} fontSize={12}>{text}</Text>
            </Flex>
          ))}
        </Flex>
      </Center>
    </Box>
  );
}

export default Legend;
